import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState} from "react";
import 'material-icons/iconfont/material-icons.css';
import {Link, useHistory} from "react-router-dom";
import './ChangePassword.css';
import LogoElemMK from "../assets/images/ESM_MK.png";
import LogoElemEN from "../assets/images/ESM_EN.png";
import UserAddService from "../repository/userRepository/UserAddRepository";
import ApplicationService from "../ApplicationService";


const changePassword = () => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [newPassword, setNewPassword] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [repeatNewPassword, setRepeatNewPassword] = useState("");

    const bothFieldsAreFilled = newPassword && repeatNewPassword;

    const passwordsMatch = newPassword === repeatNewPassword;

    const queryParams = new URLSearchParams(window.location.search)

    const TOKEN = queryParams.get("token")

    const changePassword = () => {
        if (newPassword === repeatNewPassword && newPassword != '' && repeatNewPassword != '') {
            const form = new FormData();
            form.append('newPassword', newPassword);
            form.append('repeatNewPassword', repeatNewPassword);
            UserAddService.changePassword(newPassword, repeatNewPassword, TOKEN)
                .then((data) => {
                    history.push("/finalized_registration?status=true")
                }).catch(() => {
                history.push("/finalized_registration?status=false")
            })

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div className="back">
            <Form className="form_newpassword" onSubmit={changePassword}>
                <div className="d-flex justify-content-center">
                    <img src={ApplicationService.getActiveLanguage()=="mk"? LogoElemMK : LogoElemEN} className={"logo"}/>
                </div>
                <hr/>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter new password" aria-required={"true"}
                                  onChange={(event) => setNewPassword(event.target.value)}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control type="password" placeholder="Repeat new password" aria-required={"true"}
                                  onChange={(event) => setRepeatNewPassword(event.target.value)}/>
                </Form.Group>
                <div className={'d-inline-flex text-center justify-content-center'}>
                    {passwordsMatch && bothFieldsAreFilled ?
                        (
                            <Link to={" "}> <Button variant="primary" type="submit" onClick={(event) => {
                                changePassword()
                            }}>
                                Submit
                            </Button>
                            </Link>

                        ) : null
                    }
                    {bothFieldsAreFilled ?
                        (passwordsMatch ?
                                (
                                    <div className={'d-inline-flex mx-3 my-2 text-center justify-content-center'}>
                                        <i className="material-icons mx-1">check_circle</i>
                                        Passwords match
                                    </div>
                                ) :
                                (
                                    <div className={'d-sm-flex'}>
                                        <i className="material-icons ">report</i>
                                        Passwords do not match
                                    </div>
                                )
                        ) : null
                    }
                </div>
            </Form>
        </div>
    );
}

export default changePassword;
