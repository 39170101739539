import React, {useEffect, useState} from "react";
import {strings} from "../../localization/Localization";
import {Calendar, dateFnsLocalizer} from "react-big-calendar";
import Modal from "react-bootstrap/Modal";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import {mk} from "date-fns/locale";
import {enUS} from "date-fns/locale";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import AbsencesService from "../../repository/absencesRepository/absencesRepository";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserListInModal from "../../components/UserListInModal";
import StyleForCalendarEvents from "../../components/StyleForCalendarEvents";
import "./StatusCalendar.css"
import LeaveRequestRepository from "../../repository/leaveRequestRepository/LeaveRequestRepository";
import 'material-icons/iconfont/material-icons.css';
import DownloadFileModal from "./DownloadFileModal";
import StatusColorLegendWrapper from "./StatusColorLegendWrapper/StatusColorLegendWrapper";
import ApplicationService from "../../ApplicationService";

const locales = {
    'mk': mk,
    'en-US': enUS

};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const StatusCalendar = () => {

    const customviews = ["week", "month"];

    const [newEvent, setNewEvent] = useState({title: "", start: "", end: ""});

    const [showDownloadModal,setShowDownloadModal]=useState(false);

    const [allEvents, setAllEvents] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [absenceUserForSelectedDay, setAbsenceUserForSelectedDay] = useState([]);

    const [absenceCountForMonth, setAbsenceCountForMonth] = useState([]);

    const [key, setKey] = useState('');

    useEffect(() => {
        var date = new Date();
        getAbsenceCountForMonth(date)
    }, [])

    useEffect(() => {
        let newEvents = [];
        let date = absenceCountForMonth[absenceCountForMonth.length - 1];
        if (date) {
            for (var i = 0; i < absenceCountForMonth.length - 1; i++) {
                if (absenceCountForMonth[i].absenceCounter) {
                    newEvents.push({
                        title: absenceCountForMonth[i].absenceCounter + " - " + strings.absence,
                        start: new Date(date.getFullYear(), date.getMonth(), i + 1,),
                        end: new Date(date.getFullYear(), date.getMonth(), i + 2,),
                        type: "NATIONAL"
                    })
                }
                if (absenceCountForMonth[i].ethnicityCounter + absenceCountForMonth[i].nationalCounter + absenceCountForMonth[i].religionCounter > 0) {
                    newEvents.push({
                        title: absenceCountForMonth[i].allAbsenceCounter - absenceCountForMonth[i].absenceCounter + " - " + strings.holiday,
                        start: new Date(date.getFullYear(), date.getMonth(), i + 1,),
                        end: new Date(date.getFullYear(), date.getMonth(), i + 2,),
                        type: "ETHNICITY"
                    })
                }
            }
        }
        setNewEvent(newEvents)
        setAllEvents(newEvents)
    }, [absenceCountForMonth]);

    useEffect(() => {
        getAbsenceUserOnSelectedDay()
    }, [key]);


    function handleAddEvent() {
        setAllEvents([...allEvents, newEvent]);
    };

    const handleClose = () => {
        setKey("")
        setShowModal(false);
    };

    const handleShow = () => {
        setKey("all")
        setShowModal(true);
    };

    const handleShowDownloadModal = () => {
        setShowDownloadModal(true);
    };

    const handleCloseDownloadModal = () => {
        setShowDownloadModal(false);
    };

    const getActiveLanguage = () => {
        const activeLanguage = localStorage.getItem('activeLanguage')
        if (activeLanguage === 'mk') {
            return "mk"
        } else {
            return "en-US"
        }
    };

    const getCountOfAbsenceForMonth = (date) => {
        AbsencesService.getAbsenceCountForMonth(date)
            .then((data) => {
                var temp = data.data
                temp.push(date)
                setAbsenceCountForMonth(temp)
            })
    };

    const getAbsenceUserOnSelectedDay = () => {
        if (key === 'all') {
            getALLAbsenceUserOnSelectedDay()
        } else if (key === "vacation") {
            getAbsenceUserOnSelectedDayByVacation()
        } else if (key === "national") {
            getAbsenceUserOnSelectedDayByHolidayType("NATIONAL")
        } else if (key === "ethnicity") {
            getAbsenceUserOnSelectedDayByHolidayType("ETHNIC")
        } else if (key == "religion") {
            getAbsenceUserOnSelectedDayByHolidayType("RELIGIOUS")
        }
    };

    const getALLAbsenceUserOnSelectedDay = () => {
        let temp = convertDateToString(selectedDate.start)
        LeaveRequestRepository.getAllAbsence(temp)
            .then((data) => {
                let temp = selectedDate.start
                setAbsenceUserForSelectedDay(data.data)
            })
    };

    const getAbsenceUserOnSelectedDayByVacation = () => {
        let temp =convertDateToString(selectedDate.start)
        LeaveRequestRepository.getAbsenceByVacation(temp)
            .then((data) => {
                setAbsenceUserForSelectedDay(data.data)
            })
    };

    const getAbsenceUserOnSelectedDayByHolidayType = (type) => {
        let temp = convertDateToString(selectedDate.start)
        AbsencesService.getAbsenceByHolidayType(temp, type)
            .then((data) => {
                setAbsenceUserForSelectedDay(data.data)
            })
    };

    const getStringOfSelectedData = () => {
        if (showModal) {
            let start = selectedDate.start;
            let monthNumber = start.getMonth();
            let day = start.getDay();
            let date = start.getDate();
            let year = start.getFullYear();
            const monthString = [strings.january, strings.february, strings.march, strings.april, strings.may, strings.june, strings.july, strings.august, strings.september, strings.october, strings.november, strings.december];
            const weekday = [strings.sunday, strings.monday, strings.tuesday, strings.wednesday, strings.thursday, strings.friday, strings.saturday];
            return weekday[day] + ", " + date + " " + monthString[monthNumber] + " " + year;
        }
    };

    const getAbsenceCountForMonth = (date) => {
        AbsencesService.getAbsenceCountForMonth(date)
            .then((data) => {
                let temp = data.data
                temp.push(date)
                setAbsenceCountForMonth(temp)
            })
    };

    const convertDateToString = (date) => {
        let stringFormat=null;
        if ((parseInt(date.getDate())) < 10 && (parseInt(date.getMonth()) < 9)) {
            stringFormat = "" + date.getFullYear() + "-0" + (date.getMonth() + 1) + "-0" + date.getDate();
        } else if (parseInt(date.getDate()) < 10) {
            stringFormat = "" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-0" + date.getDate();
        } else if (parseInt(date.getMonth()) < 9) {
            stringFormat = "" + date.getFullYear() + "-0" + (date.getMonth() + 1) + "-" + date.getDate();
        } else {
            stringFormat = "" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        }
        return stringFormat;
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: {
                ...StyleForCalendarEvents.getStyleForType(event.type),
                width: "80%",
                marginLeft: "10%",
                marginTop: "2px"
            }
        }
    };

    return (
        <>
            <DownloadFileModal showDownloadModal={showDownloadModal} handleCloseDownloadModal={handleCloseDownloadModal}/>
            <div className={"container mt-2 pb-4 h-100 "}>
                <div className="d-flex- w-100  align-items-center justify-content-between">
                    <div className={"d-flex justify-content-between"} >
                        <h1 className="h-100 align-middle ">{strings.absenceCalendar}</h1>
                        <div className={`btn border-radius text-white save my-2 p-2 ${ApplicationService.getActiveLanguage()=="mk"? "px-3 me-1":"px-2 me-1"}`} onClick={handleShowDownloadModal}>
                            <div className={"d-flex justify-content-center text-start"}>
                                <div>
                                    <i className="material-icons text-white align-items-center d-flex">sim_card_download</i>
                                </div>
                                <div className={"mx-1"}>
                                    {strings.downloadFile}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Calendar
                    messages={{
                        previous: ('<'),
                        next: ('>'),
                        today: ('•'),
                        week: strings.week
                        , month: strings.month
                    }}
                    views={customviews}
                    localizer={localizer}
                    culture={getActiveLanguage()}
                    selectable
                    onSelectSlot={(slotInfo) => {
                        setSelectedDate(slotInfo)
                        handleShow();
                    }}
                    events={allEvents}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={(data) => {
                        setSelectedDate(data)
                        handleShow();
                    }}
                    onNavigate={(date, view, action) => {
                        if (action == "DATE") {
                            setSelectedDate({start: date})
                            handleShow();
                        } else {
                            getCountOfAbsenceForMonth(date)
                        }
                    }}

                    eventPropGetter={(eventStyleGetter)}
                    style={{height: "93%"}}/>

                <Modal
                    show={showModal}
                    onHide={handleClose}
                    keyboard={false}
                    centered
                    size="lg">
                    <Modal.Body>

                        <Modal.Title>{getStringOfSelectedData()}</Modal.Title>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3 mt-3 status-absence-modal-tabs tabs-buttons">
                            <Tab eventKey="all" title={strings.all}>
                                <UserListInModal users={absenceUserForSelectedDay}/>
                            </Tab>
                            <Tab eventKey="vacation" title={strings.vacationn}>
                                <UserListInModal users={absenceUserForSelectedDay}/>
                            </Tab>
                            <Tab eventKey="national" title={strings.nationalHoliday}>
                                <UserListInModal users={absenceUserForSelectedDay}/>
                            </Tab>
                            <Tab eventKey="ethnicity" title={strings.ethnicityHoliday}>
                                <UserListInModal users={absenceUserForSelectedDay}/>
                            </Tab>
                            <Tab eventKey="religion" title={strings.religionHoliday}>
                                <UserListInModal users={absenceUserForSelectedDay}/>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*<div className={"cancel btn text-white"} onClick={handleClose}>*/}
                        <div className={"cancel btn text-white"} onClick={handleClose}>
                            {strings.close}
                        </div>
                    </Modal.Footer>
                </Modal>
                <div className={"d-flex w-100 mt-3 mb-5"}>
                    <StatusColorLegendWrapper/>
                </div>
            </div>
        </>
    );
}
export default StatusCalendar;