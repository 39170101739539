import axios from "../../axios/axios";

const LeaveReasonService = {
    getAllLeaveReasonsByLeaveTypeId:(id, userId) =>{
        return axios.get(`/leavereason/getAllActive/${id}`,
          {
            params: {
              userId: userId
            }
          });
    },
    getAllowDayAndRemainingDayForReasonsForLoggedUser:(id, date, userId)=>{
        return axios.get(`/userabsence/getAllByUserAndReason/${id}`,
          {
              params: {
                  date: date,
                  userId: userId
              }
          });
    },
    getAllowDayAndRemainingDayForReasonsByUserId:(reasonsId,userId, date)=>{
        return axios.get(`/userabsence/getAllByUserAndReason/${reasonsId}/${userId}` ,
            {
              params: {
                  date: date
              }
          });
    }
}
export default LeaveReasonService;