import axios from "../../axios/axios";

const SettingsRepository= {
    getEditableHierarchy:()=>{
        return axios.get('/hierarchysettings/get')
    },
    changeEditableHierarchy:()=>{
        return axios.put('/hierarchysettings/update')
    }
}
export default SettingsRepository