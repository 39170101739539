import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import Select from 'react-select';
import CalendarHistoryRepository from '../../repository/calendarHistoryRepository/CalendarHistoryRepository';
import { strings } from '../../localization/Localization';
import swal from 'sweetalert';

const CalendarHistoryModal = (props) => {

  const [fromCalendarYears, setFromCalendarYears] = useState(null);

  const [selectedFromYearCalendarYear, setSelectedFromYearCalendarYear] = useState({
    value: null,
    label: null
  });

  const [selectedToYearCalendarYear, setSelectedToYearCalendarYear] = useState({
    value: null,
    label: null
  });

  const [followingYears, setFollowingYears] = useState([]);

  useEffect(() => {
    if (props.showModal) {
      getFromCalendarYears();
      generateFollowingYearsList();
    }
  }, [props.showModal]);

  const generateFollowingYearsList = () => {
    let years = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    for (let i = currentYear; i <= currentYear + 5; i++) {
      years.push({
        label: i,
        value: i
      });
    }
    setFollowingYears(years);
  };

  const copyYear = (fromYear, toYear) => {
    let filterSameYear = fromCalendarYears.filter((e) => e.year === toYear);
    if (filterSameYear.length > 0) {
      swal({
        title: strings.areYouSure,
        text: strings.alreadyHaveHoliday,
        icon: 'warning',
        buttons: [strings.cancel, strings.ok],
      })
        .then((willSuccess) => {
          if (willSuccess) {
            copyHoliday(fromYear, toYear);
          } else {
          }
        });
    } else {
      copyHoliday(fromYear, toYear);
    }
  };

  const copyHoliday = (fromYear, toYear) => {
    props.setShowSpinner(false);
    CalendarHistoryRepository.copyCalendar(fromYear, toYear).then((data) => {
      successfulAlert();
      props.getCalendarYears();
      props.handleClose();
      props.setShowSpinner(true);
    }).catch(() => props.setShowSpinner(true));

  };

  const getFromCalendarYears = () => {
    props.setShowSpinner(false);
    CalendarHistoryRepository.fetchCalendarYears().then((data) => {
      setFromCalendarYears(data.data);
      setSelectedFromYearCalendarYear({
        value: data.data[0].year,
        label: data.data[0].year
      } ?? {
        value: new Date().getFullYear(),
        label: new Date().getFullYear()
      });
      props.setShowSpinner(true);
    }).catch(() => props.setShowSpinner(true));
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: 'success',
    });
  };

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: 'black',
      backgroundColor: state.isFocused ? '#b2d2f8' : 'white',
      // fontSize: state.selectProps.myFontSize,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '25px',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#b2d2f8' : '#b2d2f8'
      },
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      textAlign: 'center',
      fontSize: '24px'
    })
  };

  return (
    <Modal show={props.showModal} onHide={props.handleClose} size='lg' centered>
      <ModalHeader>
        <ModalTitle><b>{strings.copyCalendar}</b></ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <h5 className='col'>{strings.fromYear}</h5>
          <h5 className='col'>{strings.toYear}</h5>
        </div>
        <div className='row'>
          <Select name='fromYear'
                  className='col-6 pt-2 select'
                  styles={styles}
                  noOptionsMessage={() => strings.noOptions}
                  options={
                    fromCalendarYears?.map((item) => (
                      {
                        value: item.year,
                        label: item.year
                      }
                    ))}
                  value={selectedFromYearCalendarYear}
                  onChange={(val) => setSelectedFromYearCalendarYear(val)}
                  isSearchable={true}
                  maxMenuHeight={250}
          />
          <Select name='toYear'
                  className='col-6 pt-2 select'
                  styles={styles}
                  noOptionsMessage={() => strings.noOptions}
                  options={
                    followingYears}
                  value={selectedToYearCalendarYear}
                  onChange={(val) => setSelectedToYearCalendarYear(val)}
                  isSearchable={true}
                  maxMenuHeight={250}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={'save btn text-white'}
             onClick={() => copyYear(selectedFromYearCalendarYear.value, selectedToYearCalendarYear.value)}>{strings.copyCalendar}</div>
      </ModalFooter>
    </Modal>
  );
};

export default CalendarHistoryModal;
