import React from "react"
import "./PositionCard.css"

const PositionCard = (props) => {
  return(
      <div className={"w-100 card-position p-2 hover-shadow mb-3 h-100 row"}>
          <div className={"col-2 align-items-center d-flex justify-content-center" }>
              {/*<img src={positionImg} width={"100px"} height={"100px"}/>*/}
              <i className={"material-icons  p-2  position-icon"}>import_contacts</i>
          </div>
          <div className={"col-10"}>
              <h5><b>{props.position.name}</b></h5>
              <h6>{props.position.code}</h6>
          </div>
      </div>
  )
}
export default PositionCard;