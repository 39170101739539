import React, {useEffect, useRef, useState} from 'react'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useHistory} from "react-router-dom";
import UserAddRepository from "../../../repository/userRepository/UserAddRepository";
import swal from "sweetalert";
import UserRepository from "../../../repository/userRepository/UserRepository";
import {strings} from "../../../localization/Localization";
import "./UserUpsertModal.css"
import {GrLocation} from "react-icons/gr";
import {TbPhone} from "react-icons/tb";
import NotMultipleSelectDropDown from "../../../components/NotMultipleSelectDropDown";
import ReligionUnitService from "../../../repository/religionUnit/ReligionUnitRepository";
import EthnicityService from "../../../repository/ethnicityRepository/EthnicityRepository";
import {BsKey} from "react-icons/bs";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from "react-select";
import {FormGroup, FormLabel} from "react-bootstrap";
import UserService from "../../../repository/userRepository/UserRepository";

const UserUpsertModal = (props) => {

    const history = useHistory();

    const [formData, updateFormData] = React.useState({
        email: "",
        firstName: "",
        lastName: "",
        parentName: "",
        role: [],
        phoneNumber: "",
        address: "",
        ethnicityId: "",
        religionId: "",
        hrmsIdNumber: "",
    });

    const [selectedRoles, setSelectedRoles] = React.useState([]);

    const [religionList, setReligionList] = useState([]);

    const [ethnicityList, setEthnicityList] = useState([]);

    const [religionListForDropDown, setReligionListForDropDown] = useState([]);

    const [ethnicityListForDropDown, setEthnicityListForDropDown] = useState([]);

    const [selectedEthnicity, setSelectedEthnicity] = useState({});

    const [selectedReligion, setSelectedReligion] = useState({});

    const [defaultSelectedEthnicityForCompare, setDefaultSelectedEthnicityForCompare] = useState({});

    const [defaultSelectedReligionForCompare, setDefaultSelectedReligionForCompare] = useState({});


    const [showMailInfoTitle, setShowMailInfoTitle] = useState(false);

    const [isRequired, setIsRequired] = useState(false);

    const [isActive,setIsActive]=useState(true)

    const target = useRef(null);

    const getSelectedRoleFromDropDown = (e) => {
        setSelectedRoles(e);
    };

    const getSelectedEthnicityFromDropDown = (e) => {
        setSelectedEthnicity(e);
    };

    const getSelectedReligionFromDropDown = (e) => {
        setSelectedReligion(e);
    };

    useEffect(() => {
        setSelectedRoles(props.defaultListForDropdown)
    }, [props.defaultListForDropdown]);

    useEffect(() => {
        if (props.showModal) {
            resetFormData();
            ReligionUnitService.getAllReligion()
                .then((data) => {
                    setReligionList(data.data)
                    setReligionListForDropDown(convertListForDropDown(data.data))
                });
            EthnicityService.getAllEthnicity()
                .then((data) => {
                    setEthnicityList(data.data)
                    setEthnicityListForDropDown(convertListForDropDown(data.data))
                });
        } else {
            resetFormData();
            setSelectedEthnicity({});
            setSelectedReligion({});
        }
        setIsRequired(false);
    }, [props.showModal])

    useEffect(() => {
        if (props.selectedUserForEdit.id) {
            setSelectedEthnicity({
                value: props.selectedUserForEdit.person?.ethnicity?.id,
                label: props.selectedUserForEdit.person?.ethnicity?.label_mk,
            });
            setSelectedReligion({
                value: props.selectedUserForEdit.person?.religion?.id,
                label: props.selectedUserForEdit.person?.religion?.label_mk,
            });
            setDefaultSelectedEthnicityForCompare(props.selectedUserForEdit.person?.ethnicity?.id);
            setDefaultSelectedReligionForCompare(props.selectedUserForEdit.person?.religion?.id);
            setIsActive(props.selectedUserForEdit.active);
        }
    }, [props.selectedUserForEdit])

    const resetFormData = () => {
        updateFormData({
            email: "",
            firstName: "",
            lastName: "",
            parentName: "",
            role: [],
            phoneNumber: "",
            address: "",
            ethnicityId: "",
            religionId: "",
            hrmsIdNumber: ""
        });
    }

    const convertListForDropDown = (list) => {
        var convertList = []
        for (var i = 0; i < list.length; i++) {
            if (list[i].labelMk) {
                convertList[i] = {
                    value: list[i].id,
                    label: list[i].labelMk
                }
            } else {
                convertList[i] = {
                    value: list[i].id,
                    label: list[i].label_mk
                }
            }
        }
        return convertList;
    }

    const addUser = (email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber) => {
        props.handleClose();
        props.handleSpinnerShow();
        UserAddRepository.addUser(email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber)
            .then((data) => {
                props.handleSpinnerClose();
                successfulAlert();
                props.loadUserAndPage();
            }).catch(() => {
            props.handleSpinnerClose();
            errorAlert();
        })
    };

    const editUser = (id, email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber) => {
        props.handleClose();
        props.handleSpinnerShow();
        UserRepository.editUser(id, email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber)
            .then((data) => {
                props.handleSpinnerClose();
                userEditSuccessfulAlert();
                props.loadUserAndPage();
            }).catch((e) => {
            props.handleSpinnerClose();
            errorAlert();
        })
    };

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    };

    const enabledUserHandleChange = () => {
            UserService.changeUserActive(props.selectedUserForEdit.id).then((data)=>{
                setIsActive(!isActive);
            })
    }

    const successfulAlert = () => {
        swal(strings.userAddSuccessful, {
            icon: "success",
        });
    };

    const userEditSuccessfulAlert = () => {
        swal(strings.userEditSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const warrningAlert = () => {
        swal({
            title: strings.required,
            text: strings.fieldsRequired,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };


    const onFormSubmit = (e) => {
        e.preventDefault();
        document.getElementsByClassName("modal-content").disabled = true;
        const email = formData.email !== "" ? formData.email : props.selectedUserForEdit.email;
        const firstName = formData.firstName !== "" ? formData.firstName : props.selectedUserForEdit.firstName;
        const lastName = formData.lastName !== "" ? formData.lastName : props.selectedUserForEdit.lastName;
        const parentName = formData.parentName !== "" ? formData.parentName : props.selectedUserForEdit.person.parentName;
        let roleIds = selectedRoles.map((e) => e.value)
        const defaultRoleIdsForCompare = props.defaultListForDropdown.map((e) => e.value)
        const phoneNumber = formData.phoneNumber !== "" ? formData.phoneNumber : props.selectedUserForEdit?.person?.phoneNumber;
        const address = formData.address !== "" ? formData.address : props.selectedUserForEdit.address;
        const hrmsIdNumber = formData.hrmsIdNumber !== "" ? formData.hrmsIdNumber : props.selectedUserForEdit.hrmsIdNumber;
        let ethnicityId = selectedEthnicity.value;
        let religionId = selectedReligion.value;
        if (roleIds.length > 0) {
            if (ethnicityId === defaultSelectedEthnicityForCompare) {
                ethnicityId = null;
            }
            if (religionId === defaultSelectedReligionForCompare) {
                religionId = null;
            }
            if (compareArray(roleIds, defaultRoleIdsForCompare)) {
                roleIds = null;
            }
            if (props.selectedUserForEdit.id) {
                editUser(props.selectedUserForEdit.id, email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber)
            } else {
                addUser(email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber);
            }
        } else {
            setIsRequired(true);
            warrningAlert();
        }
    }

    const compareArray = (list1, list2) => {
        if (list1.length != list2.length) return false;
        list1.sort();
        list2.sort();
        for (var i = 0; i < list1.length; i++) {
            if (list1[i] != list2[i]) return false;
        }
        return true;
    }

    return (
        <Modal show={props.showModal} onHide={props.handleClose} size={"xl"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{(props.selectedUserForEdit.id) ? (
                    strings.editUser
                ) : (
                    strings.addNewUser
                )
                }</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className={"d-flex"}>
                                    <div className={"label-icon align-items-center d-flex rotate-key-icon"}><BsKey/>
                                    </div>
                                    {strings.hrmsIdNumber}
                                    <span className={isRequired ? " text-danger" : ""}>&nbsp; *</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    autoFocus
                                    defaultValue={props.selectedUserForEdit?.person?.hrmsIdNumber}
                                    onChange={handleChange}
                                    disabled={props.isEnabledSync}
                                    name="hrmsIdNumber"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className={"d-flex"}>
                                    <div className={"label-icon align-items-center d-flex rotate-key-icon"}><BsKey/>
                                    </div>
                                    {strings.employmentNumber}
                                    <span className={isRequired ? " text-danger" : ""}>&nbsp; *</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    autoFocus
                                    defaultValue={props.selectedUserForEdit?.hrmsIdNumber}
                                    onChange={handleChange}
                                    disabled={props.isEnabledSync}
                                    name="employmentNumber"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className={"d-flex"}>
                                    <div className={"label-icon align-items-center d-flex rotate-key-icon"}><BsKey/>
                                    </div>
                                    {strings.personNumber}
                                    <span className={isRequired ? " text-danger" : ""}>&nbsp; *</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    autoFocus
                                    defaultValue={props.selectedUserForEdit?.person?.hrmsPersonId}
                                    onChange={handleChange}
                                    disabled={props.isEnabledSync}
                                    name="personNumber"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.name} <span
                                    className={isRequired ? " text-danger" : ""}>*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    autoFocus
                                    name='firstName'
                                    disabled={props.isEnabledSync}
                                    defaultValue={props.selectedUserForEdit.firstName}
                                    onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.parentName} <span
                                    className={isRequired ? " text-danger" : ""}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    defaultValue={props.selectedUserForEdit?.person?.parentName}//proveri dali e .person.parentName
                                    onChange={handleChange}
                                    disabled={props.isEnabledSync}
                                    autoFocus
                                    name="parentName"/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.lastName} <span
                                    className={isRequired ? " text-danger" : ""}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    autoFocus
                                    defaultValue={props.selectedUserForEdit.lastName}
                                    disabled={props.isEnabledSync}
                                    onChange={handleChange}
                                    name="lastName"
                                />
                            </Form.Group>
                        </Col>


                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.ethnicityName}</Form.Label>
                                <NotMultipleSelectDropDown
                                    listForDropDown={ethnicityListForDropDown}
                                    selectedDefaultValue={selectedEthnicity}
                                    handleSelection={getSelectedEthnicityFromDropDown}
                                    disable={props.isEnabledSync}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.religion}</Form.Label>
                                <NotMultipleSelectDropDown
                                    listForDropDown={religionListForDropDown}
                                    selectedDefaultValue={selectedReligion}
                                    handleSelection={getSelectedReligionFromDropDown}
                                    disable={props.isEnabledSync}
                                />
                            </Form.Group>
                        </Col>


                    </Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className={"d-flex"}>
                            {strings.email} <span className={isRequired ? " text-danger" : ""}>&nbsp;*</span>
                            <div className={"d-flex ms-1 align-items-center cursor-pointer"} ref={target}
                                 onClick={() => setShowMailInfoTitle(!showMailInfoTitle)}><i
                                className={"material-icons"}>error_outline</i>
                                <Overlay target={target.current} show={showMailInfoTitle} placement="right-start">
                                    {(props) => (
                                        <Tooltip id="overlay-example" {...props}>
                                            <div>
                                                {strings.theMailFurtherUsedForLoggingIntoTheSystem}
                                            </div>
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div>
                        </Form.Label>
                        <div className={"d-flex"}>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                autoFocus
                                required
                                title={strings.theMailFurtherUsedForLoggingIntoTheSystem}
                                disabled={props.selectedUserForEdit.email ? true : false}
                                defaultValue={props.selectedUserForEdit.email}
                                onChange={handleChange}
                                name="email"
                            />

                        </div>
                    </Form.Group>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                <Form.Label>
                                    <label className={"label-icon"}><TbPhone/></label>
                                    {strings.phoneNumber} </Form.Label>
                                <Form.Control
                                    type="text"
                                    autoFocus
                                    name='phoneNumber'
                                    disabled={props.isEnabledSync}
                                    defaultValue={props.selectedUserForEdit?.person?.phoneNumber}
                                    onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>
                                    <label className={"label-icon"}><GrLocation/></label>
                                    {strings.address}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.selectedUserForEdit.address}
                                    onChange={handleChange}
                                    disabled={props.isEnabledSync}
                                    autoFocus
                                    name="address"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                            <Form.Label>
                                {strings.roles}
                                <span className={isRequired ? " text-danger" : ""}>&nbsp;*</span>
                            </Form.Label>
                        </Form.Label>

                        {/*<MultiSelectDropdown handleSelection={getSelectedRoleFromDropDown}*/}
                        {/*                     listForDropDown={props.listForDropDown}*/}
                        {/*                     defaultListForDropdown={selectedRoles}*/}
                        {/*/>*/}
                        <Select
                            onChange={getSelectedRoleFromDropDown}
                            value={selectedRoles}
                            isMulti
                            noOptionsMessage={() => strings.noOptions}
                            isDisabled={props.isEnabledSync}
                            name="role"
                            options={props.listForDropDown}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </Form.Group>

                    <Row style={{borderTop:"1px solid #ced4da"} } className={"mt-5"}>
                        <Col xs={12} md={8}  className={"mt-4"}>
                            <Form.Group className="mb-1 ms-1   d-flex "
                                        controlId="exampleForm.ControlInput1">

                                <FormGroup className={"d-flex my-auto mt-1"}>
                                    <FormLabel className={"me-3"}>
                                        {strings.blockUnblockUser}
                                    </FormLabel>
                                    <div className={"mt-1"}>
                                        <label className="switchEnabled">
                                            <input type="checkbox" checked={isActive} onChange={enabledUserHandleChange}/>
                                            <span className="sliderEnabled round"></span>
                                        </label>
                                    </div>
                                </FormGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    {!props.isEnabledSync &&
                        <button type={"submit"} className={"save btn text-white"}>
                            {strings.save}
                        </button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )

}
export default UserUpsertModal;

