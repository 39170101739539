import React, {useEffect, useRef, useState} from "react"
import "./Holiday.css"
import {strings} from "../../../localization/Localization";
import HolidayService from "../../../repository/holidayRepository/holidayRepository";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import swal from "sweetalert";
import NotMultipleSelectDropDown from "../../../components/NotMultipleSelectDropDown";
import EthnicityService from "../../../repository/ethnicityRepository/EthnicityRepository";
import ReligionUnitService from "../../../repository/religionUnit/ReligionUnitRepository";
import UserListForHoliday from "./UserListForHoliday/UserListForHoliday";
import ApplicationService from "../../../ApplicationService";
import {Form} from "react-bootstrap";
import SpinnerComponent from "../../../components/Spinner";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "../../../components/ReactCalendarStyle.css"
import MacedonianCalendarLocalization from "../../../components/MacedonianCalendarLocalization";

const holiday = (props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [formData, updateFormData] = React.useState({
        name: "",
        description: "",
        day: "",
        month: "",
        years: "",
        recurringValue: ""
    })

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [date, setDate] = useState();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [modal, setModal] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const params = useParams();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedHoliday, setSelectedHoliday] = useState({})

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [defaultHolidayTypeOnSelectedHoliday, setDefaultHolidayTypeOnSelectedHoliday] = useState({})

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [holidayType, setHolidayType] = useState([])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listForDropDown, setListForDropDown] = useState([])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listForDropDownForSubType, setListForDropDownForSubType] = useState([])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedHolidayType, setSelectedHolidayType] = useState({});

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [allSubTypeofHolidayType, setAllSubTypeofHolidayType] = useState({});

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedEthnicityType, setSelectedEthnicityType] = useState({});

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedReligionType, setSelectedReligionType] = useState({});

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [permissionForUpsert, setPermissionForUpsert] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isRequirement, setIsRequirement] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [spinnerShow,setSpinnerShow]=useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [dateForEditInput, setDateForEditInput] = useState();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        HolidayService.getHolidaysType()
            .then((data) => {
                setHolidayType(data.data)
            })
        if (ApplicationService.hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"])) {
            setPermissionForUpsert(true);
        }

    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        convertAllHolidayTypeForDropDown()
    }, [holidayType])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getSubType();
    }, [selectedHolidayType, defaultHolidayTypeOnSelectedHoliday])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        convertAllHolidaySubTypeForDropDown()
    }, [allSubTypeofHolidayType])

    const convertAllHolidayTypeForDropDown = () => {
        var convertRole = []
        for (var i = 0; i < holidayType.length; i++) {
            convertRole[i] = {
                value: holidayType[i],
                label: holidayType[i].label_mk
            }
        }
        setListForDropDown(convertRole)
    }

    const convertAllHolidaySubTypeForDropDown = () => {
        var convertRole = []
        for (var i = 0; i < allSubTypeofHolidayType.length; i++) {
            convertRole[i] = {
                value: allSubTypeofHolidayType[i],
                label: allSubTypeofHolidayType[i].name // treba da se smene vo labelMK i label_mk
            }
        }
        setListForDropDownForSubType(convertRole)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const divRef = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setIsLoading(true)
        if (params.id) {
            HolidayService.getHolidayById(params.id)
                .then((data) => {
                    setSelectedHoliday(data.data)
                    setSelectedHolidayType({
                        value: data.data.holidayType,
                        label: data.data.holidayType.label_mk
                    })
                    if (data.data.religion) {
                        setSelectedReligionType({
                                value: data.data.religion,
                                label: data.data.religion.label_mk
                            }
                        )
                    }
                    if (data.data.ethnicity) {
                        setSelectedEthnicityType({
                            value: data.data.ethnicity.id,
                            label: data.data.ethnicity.label_mk
                        })
                    }
                    const month = data.data.month + 1 < 10 ? ("0" + (data.data.month + 1)) : (data.data.month + 1);
                    const day = data.data.day < 10 ? ("0" + data.data.day) : data.data.day;
                    setDateForEditInput(data.data.year + "-" + month + "-" + day);
                    setDate(new Date(data.data.year, data.data.month, data.data.day, 0, 0, 0, 0))
                    setIsLoading(false)
                })
        } else {

            if (location.params) {
                var paramsDate = location.params
                var month = parseInt(paramsDate.getMonth())
                var day = parseInt(paramsDate.getDate())
                var year = parseInt(paramsDate.getFullYear())
                var tempDate = new Date(year, month, day)
                setDate(new Date(year, month, day, 0, 0, 0, 0))
            } else {
                setDate(new Date())
            }
            setIsLoading(false)
            setSelectedHoliday({})
        }
    }, [])

    const successfulAlert = (text) => {
        swal(text, {
            icon: "success",
        });
    };

    const onChange = newDate => {
        setModal(true);
        setDate(newDate);
    };

    const onChangeHolidayDate = newDate => {
        setDateForEditInput(newDate.target.value);
        setDate(new Date(newDate.target.value))
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        document.getElementsByClassName("modal-content").disabled = true;
        const name = formData.name !== "" ? formData.name : "";
        const description = formData.description !== "" ? formData.description : "";
        var recurringValue = null;
        if (selectedHoliday.id) {
            recurringValue = formData.recurringValue !== "" ? formData.recurringValue : selectedHoliday.recurring;
        } else {
            recurringValue = formData.recurringValue !== "" ? formData.recurringValue : true;

        }
        const temp = dateForEditInput ? dateForEditInput : date.toString();
        const dateTemp = dateForEditInput ? new Date(dateForEditInput) : date;
        const day = dateTemp.getDate();
        const month = dateTemp.getMonth();
        const year = dateTemp.getFullYear();

        if (selectedHolidayType?.value?.id && (selectedHolidayType.label === "Национален" || (selectedHolidayType.label === "Религиозен и национален") || (selectedHolidayType.label === "Етнички" && selectedEthnicityType.value) || (selectedHolidayType.label === "Религиозен" && selectedReligionType.value))) {
            if (selectedHoliday.id) {
                setSpinnerShow(false);
                HolidayService.editHolidayeditHoliday(selectedHoliday.id, name, description, day, month, year, selectedHolidayType?.value?.id, selectedEthnicityType?.value?.id, selectedReligionType?.value?.id, recurringValue)
                    .then(() => {
                        setIsRequirement(false);
                        setSpinnerShow(true);
                        successfulAlert(strings.holidaysSuccessfullyEdited);
                    }).catch(()=>{
                        setSpinnerShow(true);
                })
            } else {
                setSpinnerShow(false);
                HolidayService.addHoliday(name, description, day, month, year, selectedHolidayType?.value?.id, selectedEthnicityType?.value?.id, selectedReligionType?.value?.id, recurringValue)
                    .then((data) => {
                        setIsRequirement(false);
                        successfulAlert(strings.holidaysSuccessfullyCreated);
                        history.push("holiday/" + data.data.id);
                    }).catch(()=>{
                        setSpinnerShow(true)
                });
            }
        } else {
            setIsRequirement(true);
        }
    }

    const getStringOfSelectedData = () => {
        if (date) {
            let monthNumber = date.getMonth();
            let day = date.getDate();
            let dayNumber = date.getDay();
            let year = date.getFullYear();
            const monthString = [strings.january, strings.february, strings.march, strings.april, strings.may, strings.june, strings.july, strings.august, strings.september, strings.october, strings.november, strings.december];
            const weekday = [strings.sunday, strings.monday, strings.tuesday, strings.wednesday, strings.thursday, strings.friday, strings.saturday];
            return (weekday[dayNumber] + ", " + day + " " + monthString[monthNumber] + " " + year);
        }
    }

    const deleteHoliday = () => {
        swal({
            title: strings.areYouSure,
            text: strings.doYouReallyWantToDelete + "\n" + selectedHoliday.name + ". \n " + strings.thisHolidayCannotBeRestored,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
        })
            .then((willSuccess) => {
                if (willSuccess) {
                    setSpinnerShow(false);
                    HolidayService.deleteHoliday(selectedHoliday.id)
                        .then(() => {
                            setSpinnerShow(true);
                            swal(strings.holidayIsDeleted, {
                                icon: "success",
                            });
                            history.push("/holiday")
                        }).catch(()=>{
                            setSpinnerShow(true);
                    })
                } else {
                }
            });
    }

    const getSelectedValueTypeFromDropDown = (e) => {
        setSelectedReligionType({});
        setSelectedEthnicityType({});
        setSelectedHolidayType(e)
    }

    const getSelectedValueSubTypeOfTypeFromDropDown = (e) => {
        let type = selectedHolidayType.value.name
        if (type === "ETHNIC") {
            setSelectedEthnicityType(e)
            setSelectedReligionType({})
        } else if (type === "RELIGIOUS") {
            setSelectedReligionType(e)
            setSelectedEthnicityType({})
        }
    }

    const getSubType = () => {
        if (selectedHolidayType.value) {
            let type = selectedHolidayType.label
            if (type === "ETHNIC" || type === "Етнички") {
                EthnicityService.getAllEthnicity()
                    .then((data) => {
                        setAllSubTypeofHolidayType(data.data)
                    })
            } else if (type === "RELIGIOUS" || type === "Религиозен") {
                ReligionUnitService.getAllReligion()
                    .then((data) => {
                        setAllSubTypeofHolidayType(data.data)
                    })
            }
        }
    };

    const formatShortWeekday = (locale, date) => {
        return MacedonianCalendarLocalization.dayLabels[date.getDay()];
    };

    const formatMonth = (locale, date) => {
        return MacedonianCalendarLocalization.monthLabels[date.getMonth()];
    };


    const formatMonthYear = (locale, date) => {
        return `${MacedonianCalendarLocalization.monthLabels[date.getMonth()]} ${date.getFullYear()}`;
    };


    const getSubTipDropdownOfSelectedTip = () => {
        if (listForDropDownForSubType.length > 0 || defaultHolidayTypeOnSelectedHoliday.value) {
            let type = selectedHolidayType.value ? selectedHolidayType.value.name : ""
            if (type === "ETHNIC" || type === "Етнички") {
                return (
                    <div className={'form-group mb-3'}>
                        <label className={'mb-2'}>{strings.ethnicityType}<span
                            className={isRequirement ? "text-danger" : ""}>*</span></label>
                        <NotMultipleSelectDropDown
                            handleSelection={getSelectedValueSubTypeOfTypeFromDropDown}
                            listForDropDown={listForDropDownForSubType}
                            selectedDefaultValue={selectedEthnicityType}
                            disable={!permissionForUpsert}
                        />
                    </div>
                )
            } else if (type === "RELIGIOUS" || type === "Религиозен") {
                return (
                    <div className={'form-group mb-3'}>
                        <label className={'mb-2'}>{strings.religionType} <span
                            className={isRequirement ? "text-danger" : ""}>*</span></label>
                        <NotMultipleSelectDropDown
                            handleSelection={getSelectedValueSubTypeOfTypeFromDropDown}
                            listForDropDown={listForDropDownForSubType}
                            selectedDefaultValue={selectedReligionType}
                            disable={!permissionForUpsert}/>
                    </div>
                )
            }
        }
    }

    const ShowAddDateModal = () => {
        if (modal) {
            return (
                <div className="add-holiday align-middle w-100  p-3">
                    <Form onSubmit={onFormSubmit}>
                        <div className="p-2 d-inline-flex w-75">
                            <h5>{strings.dateselected}:
                                <br/>
                                {getStringOfSelectedData()}
                            </h5>
                        </div>
                        {selectedHoliday.id ? (permissionForUpsert &&
                            <div className={"d-inline-flex w-25 justify-content-end align-items-end text-end cursor-pointer"}>
                                {/* <a onClick={deleteHoliday} */}
                                {/*    className={"holiday__deleteButton__animation  mx-2"} */}
                                {/*    title={strings.delete}> */}
                                {/*     <i className={"material-icons delete-icon-circle"}>delete</i> */}
                                {/*     /!*<i className={"material-icons delete-icon-circle"}>delete</i>*!/ */}
                                {/* </a> */}
                            </div>) : null}
                        <div className="form-group mb-3">
                            {selectedHoliday.id &&
                                <div className="form-group mb-3">
                                    <div className={'form-group mb-3'}>
                                        <label className={'mb-2'}>{strings.date} <span
                                            className={isRequirement ? "text-danger" : ""}>*</span></label>
                                        <input type="date" onChange={onChangeHolidayDate}
                                               value={dateForEditInput}
                                               name={'name'} className="form-control" id="DateEvent" disabled/>
                                    </div>
                                </div>
                            }
                            <div className={'form-group mb-3'}>
                                <label className={'mb-2'}>{strings.name} <span
                                    className={isRequirement ? "text-danger" : ""}>*</span></label>
                                <input type="text" onChange={handleChange} defaultValue={selectedHoliday.name}
                                       required={true}
                                       name={'name'} className="form-control" id="DateEvent"
                                       placeholder={strings.enterEvent}
                                       disabled={!permissionForUpsert} disabled/>
                            </div>
                            <div className={'form-group mb-3'}>
                                <label className={'mb-2'}>{strings.type} <span
                                    className={isRequirement ? "text-danger" : ""} >*</span></label>
                                <NotMultipleSelectDropDown
                                    handleSelection={getSelectedValueTypeFromDropDown}
                                    listForDropDown={listForDropDown}
                                    // disable={!permissionForUpsert}
                                    selectedDefaultValue={selectedHolidayType} disable
                                />
                            </div>
                            {
                                getSubTipDropdownOfSelectedTip()
                            }
                            <div className={'form-group mb-3'}>
                                <label className={'mb-2'}>{strings.holidayRecurring}  <span
                                    className={isRequirement ? "text-danger" : ""}>*</span></label> <br/>
                                <label>
                                    <label className={"mx-2"}>{strings.yes} <input type={"radio"} required
                                                                                   onChange={handleChange}
                                                                                   defaultChecked={selectedHoliday.id ? selectedHoliday.recurring : null}
                                                                                   name={"recurringValue"}
                                                                                   value={true}
                                                                                   disabled={!permissionForUpsert} disabled/>
                                    </label>
                                    <label>{strings.no} <input type={"radio"} onChange={(event => {
                                        handleChange(event)
                                    })} required defaultChecked={selectedHoliday.id ? !selectedHoliday.recurring : null}
                                                               name={"recurringValue"} value={false}
                                                               disabled={!permissionForUpsert} disabled/>
                                    </label>
                                </label>
                            </div>
                            <div className={'form-group'}>
                                <label className={'mb-2'} >{strings.description}</label>
                                <textarea className="form-control" defaultValue={selectedHoliday.description}
                                          name={'description'} id="DateEvent" onChange={handleChange}
                                          placeholder={strings.enterDescription} disabled={!permissionForUpsert} disabled/>
                            </div>
                        </div>
                        {(selectedHoliday.id) ? (permissionForUpsert &&
                            <></>
                            // <button type={"submit"}
                            //         className={"btn text-white add-button btn-sm float-end"}>{strings.editHoliday}
                            // </button>

                        ) : (permissionForUpsert &&
                            <button type={"submit"}
                                    className={"btn text-white add-button btn-sm float-end mt-1 mb-1"}>{strings.addHoliday}
                            </button>
                        )
                        }
                    </Form>
                </div>
            )
        }
    }

    return (
        <>

            <div className={"d-flex my-2 align-items-center"}>
                <SpinnerComponent spinnerShow={spinnerShow}/>
                <div className={"align-items-center d-flex back-link-to-holiday__wrapper"}>
                    <Link to={"/holidays?year=" + date?.getFullYear() + "&&month=" + date?.getMonth()}
                          className={"btn btn-secondary btn-sm  ms-3 back-link-to-holiday__button border-radius "}>
                        <div className={"d-flex align-items-center"}>
                            <div className={"align-items-center d-flex"}>
                                <i
                                    className={"material-icons   back-link-to-holiday__icon"}>arrow_left
                                </i>
                            </div>
                            <div className={"me-1"}>
                                {strings.back}
                            </div>
                        </div>
                    </Link>
                </div>


                <div className={'text-center mt-1'}>
                    <h1>{strings.holiday}</h1>
                </div>
            </div>
            <div className={"mx-auto row"}>

                <div className={"col-md-5 mb-5 d-flex flex-column align-items-center text-center"}>

                    {!isLoading && date &&
                        <>
                            {ApplicationService.getActiveLanguage() === "mk" ?
                                (
                                    <Calendar
                                        value={date}
                                        formatShortWeekday={formatShortWeekday}
                                        formatMonth={formatMonth}
                                        onChange={onChange}
                                        formatMonthYear={formatMonthYear}
                                        minDate={selectedHoliday.id ? date : undefined}
                                        maxDate={selectedHoliday.id ? date : undefined}
                                    />) : (
                                    <Calendar
                                        value={date}
                                        onChange={onChange}
                                        minDate={selectedHoliday.id ? date : undefined}
                                        maxDate={selectedHoliday.id ? date : undefined}
                                    />
                                )}
                        </>
                    }
                </div>
                <div className="col-md-7 pb-5">
                    {ShowAddDateModal()}
                </div>
                {
                    params.id && <UserListForHoliday scroll={params.id ? true : false} setSpinnerShow={setSpinnerShow}/>
                }
            </div>
        </>
    );
}
export default holiday;