import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Aux from './hoc/AuxWrapper';
import {ToastContainer} from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Aux>
        <ToastContainer/>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Aux>
);
reportWebVitals();
