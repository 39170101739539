import React from "react"
import "./ColorLegend.css"

const ColorLegend = (props) => {


    return (
        <div className={"color_legend__card  h-100 w-100"}>
            <div className={"h-100 mt-3 ms-1 row"}>
                {props.data.map((e)=>{
                    return(
                        <div className={`col-12 mb-1 col-sm-6 col-lg-3 d-flex  align-items-center`}>
                            <div className={`color_legend__color__card me-2 align-items-center ${e.colorClass}`}></div>
                            <div>{e.title}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
export default ColorLegend;