import React from "react";
import swal from "sweetalert";
import {strings} from "../../../localization/Localization";

const EthnicityTerm = (props) => {

    const deleteConfirmAlert = () => {
        swal({
            title: strings.areYouSure,
            text: strings.deleteConfirmationNoQuestionMark + " " + props.term.name + "?\n" + strings.deletedForever,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
        }).then((willSuccess) => {
            if (willSuccess) {
                props.deleteEthnicity(props.term.id)
                swal(strings.ethnicityDeleted, {
                    icon: "success",
                });
            } else {
            }
        });
    };

    return (
        <tr key={props.term.id}>
            <td>{props.term.name}</td>
            <td>{props.term.label_mk}</td>
            <td>{props.term.label_sq}</td>
            <td>{props.term.label_en}</td>
            <td className={'h-100 pe-2'}>
                {props.permissionForUpsert &&
                    <div className={"d-inline-flex justify-content-end w-100 align-items-end"}>
                        <div className={'icon-div mx-3'}>
                            <div
                                onClick={() => props.onClickSetSelectedEthnicityForEdit(props.editTerm)}
                                title={'Edit'}
                                className={'text-dark icon'}>
                                <i className={"edit-icon-circle material-icons "}>
                                    create
                                </i>
                            </div>
                        </div>
                        {!props.isEnabledSync &&
                            <div className={'icon-div'}>
                                <div title={'Delete'} type={'Button'}
                                     onClick={deleteConfirmAlert}
                                >
                                    <i className={"material-icons delete-icon-circle"}>delete</i>

                                </div>
                            </div>
                        }
                    </div>
                }
            </td>
        </tr>
    )
}
export default EthnicityTerm;