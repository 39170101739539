import React from "react"
import ColorLegend from "../../../components/ColorLegend/ColorLegend";
import {strings} from "../../../localization/Localization";

const StatusColorLegendWrapper = () => {

    const data = [

        {
            colorClass: "absence_by_day_off_color",
            title: strings.absencesByDayOff,
        },{
            colorClass: "absence_by_holiday_color",
            title: strings.absencesByHoliday,
        }
    ]

    return (
        <>
            <ColorLegend data={data}/>
        </>
    );
}
export default StatusColorLegendWrapper;