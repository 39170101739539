import { Col, Form, Row } from 'react-bootstrap';
import { strings } from '../../../../localization/Localization';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';

const LeaveRequestHistoryModal = ({
  leaveRequest,
  parentLeaveRequest
}) => {

  const [showDeputyInput, setShowDeputyInput] = useState(false);

  useEffect(() => {
    if(parentLeaveRequest.deputy != null){
      setShowDeputyInput(true)
    } else {
      setShowDeputyInput(false);
    }
  }, [parentLeaveRequest])

  return (
    <>
      {leaveRequest?.parentId !== null &&
        <div className={'col-lg-6 col-md-12 d-flex align-items-center justify-content-center py-3'} style={{
          backgroundColor: leaveRequest.leaveRequestStatus?.name != 'DECLINED' ? '#e4e4e4' : 'white',
          borderRadius: '10px'
        }}>
          <div className={'w-100'}>
            <>
              {parentLeaveRequest.status.name === 'TERMINATED' ?
                <h1 className={'text-center'} style={{ marginTop: -80, marginBottom: 50 }}>{strings.termination_request}</h1>
                :
                <h1 className={'text-center'} style={{ marginTop: -80, marginBottom: 50 }}>{strings.previous_correction_request}</h1>
              }
                <Form.Group className='row mb-3 ' controlId='date'>
                <Form.Group className='col-6'>
                  <Form.Label>{strings.dateFrom}</Form.Label> <br/>
                  <DatePicker
                    className='react-datepicker w-100'
                    selected={new Date(parentLeaveRequest?.dateFrom)}
                    disabled={true}
                    required
                    dateFormat='dd.MM.yyyy'/>
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>{strings.dateTo}</Form.Label> <br/>
                  <DatePicker
                    className='react-datepicker w-100'
                    selected={new Date(parentLeaveRequest?.dateTo)}
                    required
                    disabled={true}
                    dateFormat='dd.MM.yyyy'/>
                </Form.Group>
              </Form.Group>
              <Form.Group className='row' controlId='days'>
                <Form.Group className='col-6 mb-2' controlId='workDays'>
                  <Form.Label>{strings.workingDays}</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder={strings.day}
                    autoFocus
                    required
                    disabled={true}
                    defaultValue={parentLeaveRequest.workDays}
                    name='workDays'
                    value={parentLeaveRequest.workDays}
                    className='mb-3'/>
                </Form.Group>
                <Form.Group className='col-6 mb-2' controlId='totalDays'>
                  <Form.Label>{strings.totalDays}</Form.Label>
                  <Form.Control
                    type='number'
                    autoFocus
                    disabled={true}
                    defaultValue={leaveRequest?.totalDays}
                    readOnly={true}
                    name='totalDays'
                    value={parentLeaveRequest.totalDays}
                    className='mb-3'/>
                </Form.Group>
              </Form.Group>
              <Form.Group className='mb-3 justifyContent' controlId='description'>
                <Form.Label>{strings.description}</Form.Label>
                <textarea
                  className={'w-100 free-area-text form-control text-start'}
                  placeholder={strings.enterDescription}
                  defaultValue={parentLeaveRequest.description}
                  disabled={true}
                  name='description'>
                    </textarea>
              </Form.Group>
              <Row>
                {showDeputyInput &&
                  <Form.Group className='col-12 mb-3'>
                    <Form.Label>{strings.deputy}</Form.Label>
                    <Select required
                            name='deputies'
                            noOptionsMessage={() => strings.noOptions}
                            isDisabled={true}
                            value={ parentLeaveRequest && {
                              value: parentLeaveRequest.leaveType.id,
                              label: parentLeaveRequest.deputy.firstName + ' ' + parentLeaveRequest.deputy.lastName
                            }}/>
                  </Form.Group>
                }
              </Row>
              <Form.Label>{strings.leaveType}</Form.Label>
              <Select required
                      name='leaveType'
                      noOptionsMessage={() => strings.noOptions}
                      isDisabled={true}
                      value={ parentLeaveRequest && {
                          value: parentLeaveRequest.leaveType.id,
                          label: parentLeaveRequest.leaveType.name
                        }
                      }
              />
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group className='mt-3 justifyContent' controlId='reasons'>
                    <Form.Label>{strings.reasonsForLeaveRequest}</Form.Label>
                    <Select required
                            name='leaveReasons'
                            isDisabled={true}
                            value={ parentLeaveRequest && {
                                value: parentLeaveRequest.leaveReasons.id,
                                label: parentLeaveRequest.leaveReasons.name
                              }
                            }
                            defaultValue={parentLeaveRequest.leaveReason}/>
                  </Form.Group>
                </Col>
              </Row>
            </>
          </div>
        </div>
      }
    </>
  );
};
export default LeaveRequestHistoryModal;