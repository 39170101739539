import React, { useState } from "react";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Spinner } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { strings } from "../../localization/Localization";
import ExportService from "../../repository/exportRepository/exportRepository";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SpinnerComponent from '../../components/Spinner';

const DownloadFileModal = (props) => {
    const [formData, updateFormData] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    });

    const [showSpinner, setShowSpinner] = useState(true)

    let history = useHistory();

    const [fileName, setFileName] = useState("");

    const FileSaver = require("file-saver");

    const successfulAlert = () => {
        swal(strings.fileDownloaded, {
            icon: "success"
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
            } else {
            }
        });
    };

    const handleChange = (date) => {
        updateFormData({
            ...formData,
            month: date.getMonth() + 1,
            year: date.getFullYear()
        });
    };

    const downloadFile = (month, year) => {
        setShowSpinner(false);
        ExportService.getAbsenceByDateFile(month, year)
          .then((data) => {
              setFileName(data.data);
              return fetch(process.env.REACT_APP_HOST_ENV + `/export/download/${data.data}`, {
                  origin: "same-origin",
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("auth_token")
                  }
              })
                .then((res) => res.blob())
                .then((file) => FileSaver.saveAs(file, data.data + ".xlsx"));
          })
          .then(() => {
              successfulAlert();
          })
          .catch(() => {
              warningAlert();
          })
          .finally(() => {
              setShowSpinner(true);
          });
    };


    const onFormSubmit = () => {
        const month = formData.month;
        const year = formData.year;
        downloadFile(month, year);
    };

    return (
      <>
          <SpinnerComponent spinnerShow={showSpinner}/>
          <Modal show={props.showDownloadModal} onHide={props.handleCloseDownloadModal} centered>
              <ModalHeader>
                  <ModalTitle>
                      <b>{strings.enterDate}</b>
                  </ModalTitle>
              </ModalHeader>
              <Form onSubmit={onFormSubmit}>
                  <ModalBody>
                      <Row>
                          <Col xs={12} md={12}>
                              <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                  <Form.Label>{strings.month}</Form.Label>
                                  <DatePicker
                                    selected={new Date(formData.year, formData.month - 1)}
                                    onChange={handleChange}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className="form-control"
                                  />
                              </Form.Group>
                          </Col>

                      </Row>
                  </ModalBody>
                  <ModalFooter>
                      <div className={"cancel btn text-white"} onClick={props.handleCloseDownloadModal}>
                          {strings.close}
                      </div>
                      <div className={"save btn text-white"} onClick={onFormSubmit}>
                          {strings.download}
                      </div>
                  </ModalFooter>
              </Form>
          </Modal>
      </>
    );
};

export default DownloadFileModal;
