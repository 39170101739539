import axios from "../../axios/axios";

const ExportService = {
    getAbsenceByDateFile: (month, year) => {
        return axios.post("/export", {
            month,
            year
        })
    },
    getEmployeeFile:(filterEmployee)=> {
        return axios.post("/exportUser",filterEmployee)
    }
}
export default ExportService;