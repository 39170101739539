import React, {useEffect, useState} from "react";
import {strings} from "../../../localization/Localization";
import PositionHierarchyTree from "../PositionHierarchyTree/PositionHierarchyTree";
import SettingsRepository from "../../../repository/settingsRepository/SettingsRepository";


const PositionHierarchyList = () => {

    const [isEnabledHierarchy, setIsEnabledHierarchy] = useState(true);

    useEffect(()=>{
        getIsEnabledHierarchy();
    },[]);

    const getIsEnabledHierarchy = async () => {
        let isEnabled = await SettingsRepository.getEditableHierarchy()
        isEnabled = isEnabled.data.value === "1"
        setIsEnabledHierarchy(isEnabled);
    };

    return (
        <>
            <div className="container w-100">

                <div className="my-3 mx-3">
                    <h1>{strings.positionHierarchy}</h1>
                </div>

                <div>
                    <PositionHierarchyTree isEnabledHierarchy={isEnabledHierarchy}/>
                </div>
            </div>
        </>
    );
}
export default PositionHierarchyList;