import React from "react";
import './AccessDenied.css'
import errorPhoto from '../../assets/images/403 error page.png'
import {useHistory} from "react-router-dom";
import {strings} from "../../localization/Localization";

const AccessDenied = () => {

    let history = useHistory();

    return (
        <div className="access_denied_back_container">
            <img src={errorPhoto} className={'access_denied_background_img'}/>
            <div className={'container text-center justify-content-center'}>
                <h1 className={'mb-4 display-3'}><strong className={'access_denied_header'}> {strings.weAreSorry}</strong></h1>
                <p className={'mb-3 access_denied_text_info'}>
                    {strings.accessRestrictedText} <br/>
                    {strings.referToSystemAdministrator}
                </p>
                    <button onClick={() => history.goBack()} className={'btn btn-primary rounded-3 access_denied_back-button'}>{strings.back}</button>
            </div>
        </div>
    )

}
export default AccessDenied;