import EthnicityTerm from "../Ethnicity_term/EthnicityTerm";
import {useState} from "react";
import ReactPaginate from "react-paginate";
import React, {useEffect} from "react";
import EthnicityModal from "../Ethnicity_modal/EthnicityModal";
import EthnicityRepository from "../../../repository/ethnicityRepository/EthnicityRepository";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import {strings} from "../../../localization/Localization";
import AddButton from "../../../components/addButton/AddButton";
import Select from "react-select";
import ApplicationService from "../../../ApplicationService";

const EthnicityList = () => {

    const [page, setPage] = useState(0);

    const [size, setSize] = useState(10);

    const [offset, setOffset] = useState(0)

    const [nextPageOffset, setNextPageOffset] = useState(0)

    const [ethnicityList, setEthnicityList] = useState([])

    const [pageCount, setPageCount] = useState()

    const [showModal, setShowModal] = useState(false)

    const [selectedEthnicityForEdit, setSelectedEthnicityForEdit] = useState({})

    const [isEdit, setIsEdit] = useState(false);

    const [permissionForUpsert, setPermissionForUpsert] = useState(ApplicationService.hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]));

    const [isEnabledSync, setIsEnabledSync] = useState(true);

    useEffect(() => {
        ApplicationService.hasSyncEnabled().then((data) => {
            setIsEnabledSync(data);
        })
    }, []);

    useEffect(() => {
        loadEthnicityAndPage();
    }, [size])

    const handleClose = () => {
        setShowModal(false);
        setIsEdit(false);
        setSelectedEthnicityForEdit({});
        loadEthnicityAndPage();
    };

    const handleShow = () => {
        setShowModal(true);
    };

    const onClickSetSelectedEthnicityForEdit = (e) => {
        setSelectedEthnicityForEdit(e);
        setIsEdit(true)
        handleShow();
    }

    useEffect(() => {
        loadEthnicityAndPage();
    }, [page]);

    const loadEthnicityAndPage = () => {
        EthnicityRepository.fetchEthnicities(page, size).then((res) => {
            if(res.data.content.length ===0 && page!=0){
                setPage(page-1);
            }else{
                setEthnicityList(res.data.content);
                setPageCount(res.data.totalPages)
                setOffset(res.data.pageable.offset);
                setNextPageOffset(res.data.pageable.offset + size)
            }
        });
    };

    const deleteEtnicity = (id) => {
        EthnicityRepository.deleteEthnicity(id)
            .then((data) => {
                loadEthnicityAndPage();
            })
    };

    const getEthnicityPage = () => {
        return ethnicityList.map((term) => {
            return (
                <EthnicityTerm
                    key={term.id}
                    onClickSetSelectedEthnicityForEdit={onClickSetSelectedEthnicityForEdit}
                    term={term}
                    editTerm={term}
                    permissionForUpsert={permissionForUpsert}
                    deleteEthnicity={deleteEtnicity}
                    isEnabledSync={isEnabledSync}
                />
            );
        })
    };

    const ethnicityTermPage = getEthnicityPage(offset, nextPageOffset);

    const handlePageClick = (data) => {
        let selected = data.selected;
        setPage(selected);
    };

    const onClickHandle = () => {
        handleShow();
    }

    const selectSize = (e) => {
        setPage(0);
        setSize(e.value);
    };

    return (
        <>
            <div className={"w-100 d-flex justify-content-between"}>
                <div>
                    <h1>{strings.ethnicityName}</h1>
                </div>
                {permissionForUpsert && !isEnabledSync &&
                    <div className={"align-items-center d-flex "}>
                        <AddButton onClickHandle={onClickHandle}></AddButton>
                    </div>
                }
            </div>
            <div className={"container"}>
                <div>
                    <div className={"row table-responsive"}>
                        <table id="keywords" className={"mb-1"}>
                            <thead>
                            <tr>
                                <th scope={"col"}>{strings.ethnicityName}</th>
                                <th scope={"col"}>{strings.labelMk}</th>
                                <th scope={"col"}>{strings.labelSq}</th>
                                <th scope={"col"}>{strings.labelEn}</th>
                                <th scope={"col"} className={'text-end '}>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {ethnicityList.length > 0 &&
                                ethnicityTermPage
                            }
                            </tbody>
                        </table>
                        {
                            ethnicityList.length === 0 &&
                            <div className={"justify-content-center text-center w-100"}>
                                <div>
                                    <h5 className={"text-secondary w-100 "}>
                                        <i>{strings.emptyList}</i></h5>
                                </div>
                            </div>
                        }
                    </div>
                    {ethnicityList.length !== 0 &&
                        <div className={"d-flex justify-content-between my-3"}>
                            <div className={"mx-auto h-100 align-items-center  d-flex"}>
                                <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                               nextLabel={<AiOutlineArrowRight/>}
                                               breakLabel={"..."}
                                               pageCount={pageCount}
                                               marginPagesDisplayed={2}
                                               pageRangeDisplayed={5}
                                               onPageChange={handlePageClick}
                                               forcePage={page}
                                               breakClassName={"page-item"}
                                               breakLinkClassName={"page-link"}
                                               containerClassName={"pagination h-100 m-0 justify-content-center"}
                                               pageClassName={"page-item"}
                                               pageLinkClassName={"page-link"}
                                               previousClassName={"page-item"}
                                               previousLinkClassName={"page-link"}
                                               nextClassName={"page-item"}
                                               nextLinkClassName={"page-link"}
                                               activeClassName={"active"}/>
                            </div>
                            <div className={"d-flex align-items-end"}>

                                <Select
                                    placeholder={strings.size}
                                    onChange={selectSize}
                                    noOptionsMessage={() => strings.noOptions}
                                    options={[
                                        {value: 5, label: "5"},
                                        {value: 10, label: "10"},
                                        {value: 15, label: "15"},
                                        {value: 20, label: "20"}
                                    ]}
                                    defaultValue={{value: 10, label: "10"}}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 40,
                                            border: "none"
                                        })
                                    }}
                                />
                            </div>

                        </div>
                    }
                </div>
            </div>
            <EthnicityModal showModal={showModal}
                            handleClose={handleClose}
                            selectedEthnicityForEdit={selectedEthnicityForEdit}
                            isEdit={isEdit}
                            isEnabledSync={isEnabledSync}
            />
        </>
    )
}

export default EthnicityList;