import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { strings } from '../localization/Localization';

const MultiSelectDropdown = (props) => {

  const [options, setOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setOptions(props.listForDropDown);
    if (props.defaultListForDropdown != null) {
      if (props.defaultListForDropdown.length > 0) {
        let newOptions = props.listForDropDown.filter(obj => selectedOptions.map(s => s.value).includes(obj.value));
        setSelectedOptions(newOptions);
        props.handleSelection(newOptions);
      } else {
        setSelectedOptions([]);
      }
    }
  }, [props.listForDropDown]);

  useEffect(() => {
    if (props.resetData) {
      setSelectedOptions([]);
    }
  }, [props.resetData]);

  const getValue = (selectedValues) => {
    props.setResetData(false);
    setSelectedOptions(selectedValues);
    props.handleSelection(selectedValues);
  };

  return (
    <div>
      <Select
        onChange={getValue}
        value={selectedOptions}
        noOptionsMessage={() => strings.noOptions}
        isMulti
        name='role'
        placeholder={props.isDisabled ? strings.isLoading : strings.select}
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        isDisabled={props.isDisabled ? true : false}/>
    </div>
  );

};
export default MultiSelectDropdown;