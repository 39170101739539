import React, {useEffect, useState} from "react";
import './MainLayout.css'
import 'react-pro-sidebar/dist/css/styles.css';
import Navigationbar from "../Navigationbar";
import Sidebar from "../Sidebar";
import '../Table.css'
import MobileNavigationBar from "../MobileNavigaionBar/MobileNavigationBar";
import MobileSideBar from "../MobileSideBar/MobileSideBar";
import UserRepository from "../../repository/userRepository/UserRepository";

const MainLayout = ({children}) => {

    const windowSize = window.innerWidth;

    const [collapsed, setCollapsed] = useState(false);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [userData, setUserData] = useState({});

    useEffect(() => {
        if(windowSize<=567)
        UserRepository.getUserDetails().then(res => {
            setUserData(res.data);
        })
    }, [])


    const handleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    function OnMenuClick() {
        setCollapsed(!collapsed);
    };

    return (
        <div className={'mainDiv h-100'}>
            {
                windowSize > 567 ?
                    (<Navigationbar/>)
                    : (
                        <MobileNavigationBar handleMobileMenu={handleMobileMenu}/>
                    )
            }

            <div className={'flex d-inline-flex w-100 centerWrapper'}>
                {windowSize > 567 ?
                    (<Sidebar/>) : (mobileMenuOpen && <MobileSideBar userData={userData} handleMobileMenu={handleMobileMenu}/>)
                }
                <div className={"w-100 scrollWrapper"}>
                    <div className={`w-100 px-sm-3 px-lg-3  h-100 container  pt-2 pb-3 ${mobileMenuOpen ? "inShadow" : ""}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainLayout;
