import React from "react"
import {strings} from "../../localization/Localization";
import PositionCard from "./PositionCard";

const PositionListCard = (props) => {
    return (
        <>
            {props.positionList.length > 0 ? (
                <div className={"py-3 ps-4  border-radius border-4 mb-3  "}>
                    {props.positionList.map((position)=>{
                        return(
                            <PositionCard key={position.id} position={position}/>
                        )

                    })}

                </div>
            ) : (

                <h5 className={'text-center text-secondary'}>
                    <i>  {strings.noPositionForSelectedOrganisation} </i>
                </h5>

            )}
        </>
    );
}
export default PositionListCard;