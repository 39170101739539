import React from 'react';
import '../../../components/Table.css';

const EmployeeTerm = (props) => {
  return (
    <tr className='trhover' onClick={() => props.onClickSetSelectedEmployeeForEdit(props.term)}>
      <td className={'ps-3 pe-1'} title={props.term?.person?.hrmsIdNumber}>{props.term?.person?.hrmsIdNumber}</td>
      <td className={'ps-3 pe-1'} title={props.term.firstName}>{props.term.firstName}</td>
      <td className={'mx-3'} title={props.term.lastName}>{props.term.lastName}</td>
      <td className={'mx-3'} title={props.term.person?.parentName}>{props.term.person?.parentName}</td>
      <td className={'px-3'} title={props.term.email}>{props.term.email}</td>
      <td className={'px-2'} title={props.term.roles.join(', ')}>{props.term.roles.join(', ')}</td>
      <td className={'px-2'} title={props.term?.locationName}>{props.term?.locationName}</td>
      <td className={'px-2'} title={props.term.organisation?.name}>{props.term.organisation?.name}</td>
      <td className={'pe-2'} title={props.term.position?.name}>{props.term.position?.name}</td>
    </tr>
  );
};
export default EmployeeTerm;