import React, {useEffect, useState} from "react"
import ReligionUnitService from "../../../repository/religionUnit/ReligionUnitRepository";
import ReligionModal from "../Religion_modal/ReligionModal";
import {strings} from "../../../localization/Localization";
import swal from "sweetalert";
import AddButton from "../../../components/addButton/AddButton";
import ApplicationService from "../../../ApplicationService";

const religionList = (props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [religionUnit, setreligionUnit] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showModal, setShow] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedReligionForEdit, setSelectedReligionForEdit] = useState({});

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [permissionForUpsert, setPermissionForUpsert] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isEnabledSync, setIsEnabledSync] = useState(true);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        ApplicationService.hasSyncEnabled().then((data) => {
            setIsEnabledSync(data);
        })
        loadReligionUnit();
        if (ApplicationService.hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"])) {
            setPermissionForUpsert(true);
        }
    }, []);

    const loadReligionUnit = () => {
        ReligionUnitService.fetchReligionUnit(0, 100)
            .then((data) => {
                setreligionUnit(data.data.content);
            });
    }

    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteReligionUnit(id);
            }
        });
    }

    const deleteReligionUnit = (id) => {
        ReligionUnitService.deleteReligionUnit(id)
            .then((data) => {
                swal(strings.religionDeleted, {
                    icon: "success",
                });
                loadReligionUnit();
            })
    }

    const handleClose = () => {
        setShow(false);
        loadReligionUnit();
    }
    const handleShow = () => {
        setShow(true);
    }
    const onClickSetSelectedReligionForEdit = (e) => {
        setSelectedReligionForEdit(e);
        handleShow();
    }
    const setDefaultSelectedReligion = () => {
        setSelectedReligionForEdit({})
    }

    const onClickHandle = () => {
        handleShow();
        setDefaultSelectedReligion();
    }

    return (
        <>
            <div className={"w-100 d-flex justify-content-between"}>
                <div>
                    <h1>{strings.religionList}</h1>
                </div>
                {permissionForUpsert && !isEnabledSync &&
                    <div className={"align-items-center d-flex "}>
                        <AddButton onClickHandle={onClickHandle}></AddButton>
                    </div>
                }
            </div>

            <div className={"container"}>
                <div>
                    <div className={"row table-responsive"}>
                        <table id="keywords" className={"mb-1"}>
                            <thead>
                            <tr>
                                <th scope={"col"}>ID</th>
                                <th scope={"col"}>{strings.name}</th>
                                <th scope={"col"}>{strings.labelMk}</th>
                                <th scope={"col"}>{strings.labelSq}</th>
                                <th scope={"col"}>{strings.labelEn}</th>
                                <th scope={"col"} className={'text-end'}>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {religionUnit.length > 0 && religionUnit.map(unit => {
                                return (
                                    <tr key={unit.id}>
                                        <th scope="row">{unit.id}</th>
                                        <th scope="row">{unit.name}</th>
                                        <td>{unit.labelMk}</td>
                                        <td>{unit.labelSq}</td>
                                        <td>{unit.labelEn}</td>
                                        <td className={'h-100 pe-2'}>
                                            <div className={"d-inline-flex justify-content-end w-100 align-items-end"}>
                                                {permissionForUpsert &&
                                                    <>
                                                        <div className={'icon-div mx-3'}>
                                                            <div
                                                                onClick={() => onClickSetSelectedReligionForEdit(unit)}
                                                                title={'Edit'}
                                                                className={'text-dark icon'}>
                                                                <i className={"edit-icon-circle material-icons "}>
                                                                    create
                                                                </i>
                                                            </div>
                                                        </div>
                                                        {!isEnabledSync &&
                                                            <div className={'icon-div'}>
                                                                <a title={'Delete'}>
                                                                    <div
                                                                        onClick={() => ConfirmationDelete(unit.id)}
                                                                        title={'Delete'}
                                                                        className={'text-dark icon'}>
                                                                        <i className={"material-icons delete-icon-circle"}>delete</i>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                            }
                            </tbody>
                        </table>
                        {
                            religionUnit.length === 0 &&
                            <div className={"justify-content-center text-center w-100"}>
                                <div>
                                    <h5 className={"text-secondary w-100 "}>
                                        <i>{strings.emptyList}</i></h5>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ReligionModal showModal={showModal}
                           handleClose={handleClose}
                           selectedReligionForEdit={selectedReligionForEdit}
                           isEnabledSync={isEnabledSync}/>
        </>
    );
}
export default religionList;
