import React from "react";
import decode from "jwt-decode";
import {Redirect, Route} from "react-router-dom";

const ProtectedRoute = ({component: Component, authorizedRoles: authorizedRoles, ...rest}) => {

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? (
                    isAuthorized(authorizedRoles) ? (
                    <Component {...props} />
                    ):(
                        <Redirect
                            to={{
                                pathname: "/accessDenied",
                            }}
                        />
                    )

                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
                )
            }
        />
    );

}

const isAuthorized = (authorizedRoles) => {

    if (authorizedRoles.length == 0) return true;

        const token = localStorage.getItem('auth_token');
        let userRoles = decode(token).authority
    // for (var r in authorizedRoles) {
    //     let el = authorizedRoles[r]
    //     if (userRoles.includes(el)) {
    //         return true
    //     }
    // }
    // return false
    const isAuthorized = authorizedRoles.filter((role) => userRoles.includes(role));
    return isAuthorized.length>0;
}

const isAuthenticated = () => {
    try {

        const token = localStorage.getItem('auth_token');
        decode(token)
        return true;

    } catch (error) {
        return false;
    }
}
export default ProtectedRoute;