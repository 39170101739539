import React from "react"
import {strings} from "../../../localization/Localization";
import "../AbsenceStatus.css"

const AbsenceStatusList = (props) => {

    const windowSize = window.innerWidth;

  return(
      <div className="table-responsive">
          <table className="table user-table table-responsive table-borderless text-start">
              <thead className={"user-table-header text-white"}>
              <tr className={"user-table-header-row"}>
                  <th scope="col" >{strings.year}</th>
                  {((windowSize <= 567 && props.showReasons)|| windowSize>576) &&
                      <th scope="col">{strings.reasonsForLeaveRequest}</th>
                  }
                  <th scope="col" >{strings.allowedDays}</th>
                  <th scope="col" >{strings.usedDays}</th>
                  <th scope="col" >{strings.remainingDays}</th>
              </tr>
              </thead>
              <tbody>

              {props.data.length > 0 ?
                  (props.data.map((data) => {
                          return (
                              <tr className={"user-table-body-row"}>
                                  <td>{data.forYear}</td>
                                  {((windowSize <= 567 && props.showReasons)|| windowSize>576) &&
                                  <td>{data.leaveReason.labelMk}</td>
                                  }
                                  <td>{data.allowedDays}</td>
                                  <td>{data.usedDays}</td>
                                  <td>{data.remainingDays}</td>
                              </tr>
                          )
                      })

                  ) :
                  (
                      <tr className={"justify-content-center text-center "}>
                          <td colSpan={5}>
                              <h6 className={"text-secondary"}><i>{strings.emptyList}</i></h6>
                          </td>
                      </tr>
                  )
              }
              {/*{usersForSelectedHoliday.length > 0 &&*/}
              {/*    <tr className={"text-start justify-content-center bg-light mt-3"}>*/}
              {/*        <td colSpan={4}>*/}
              {/*            <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}*/}
              {/*                           nextLabel={<AiOutlineArrowRight/>}*/}
              {/*                           breakLabel={"..."}*/}
              {/*                           pageCount={pageCountListOfHolidayUser}*/}
              {/*                           marginPagesDisplayed={2}*/}
              {/*                           pageRangeDisplayed={2}*/}
              {/*                           onPageChange={handlePageClickListOfHolidayUser}*/}
              {/*                           breakClassName={"page-item"}*/}
              {/*                           breakLinkClassName={"page-link"}*/}
              {/*                           containerClassName={"pagination justify-content-center"}*/}
              {/*                           pageClassName={"page-item"}*/}
              {/*                           pageLinkClassName={"page-link"}*/}
              {/*                           previousClassName={"page-item"}*/}
              {/*                           previousLinkClassName={"page-link"}*/}
              {/*                           nextClassName={"page-item"}*/}
              {/*                           nextLinkClassName={"page-link"}*/}
              {/*                           activeClassName={"active"}/>*/}
              {/*        </td>*/}
              {/*    </tr>*/}
              {/*}*/}
              </tbody>
          </table>
      </div>
  );
}
export default AbsenceStatusList;