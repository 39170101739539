import {Redirect} from "react-router-dom";

const Logout = (props) => {
    let language = localStorage.getItem('activeLanguage');
    localStorage.clear();
    localStorage.setItem("activeLanguage", language);
    return(
        <Redirect to="/login" />
    );
}

export default Logout;