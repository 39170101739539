import React from "react";
import LogoElemMK from "../assets/images/ESM_MK.png"
import LogoElemEN from "../assets/images/ESM_EN.png"
import DropDown from "./Dropdown";
import {useEffect, useState} from "react";
import UserRepository from "../repository/userRepository/UserRepository";
import {strings} from "../localization/Localization";
import Notifications from "./Notifications/Notifications";
import {Link} from "react-router-dom";
import ApplicationService from "../ApplicationService";

const Navigationbar = () => {

    const [userData, setUserData] = useState({});

    useEffect(() => {
        UserRepository.getUserDetails().then(res => {
            setUserData(res.data)
        })
    }, [])

    const setLanguageToEnglish = () => {
        strings.setLanguage('en');
        localStorage.setItem("activeLanguage", "en")
        window.location.reload();
    }

    const setLanguageToMacedonian = () => {
        strings.setLanguage('mk');
        localStorage.setItem("activeLanguage", "mk")
        window.location.reload();
    }

    return (
        <div className="navigation container-fluid p-1">
            <div className="row g-0 align-items-center">
                <div className="col-6 justify-content-end text-start ps-1"><Link to="/home"><img
                    className="img-fluid" width={"300px"} height={"150px"}
                    src={ApplicationService.getActiveLanguage() == "mk" ? LogoElemMK : LogoElemEN}/></Link></div>
                <div className="col-6 justify-content-end align-items-center  text-end d-flex">
                    <div className="justify-content-end align-items-center information text-start d-flex text-wrap">
                        <div
                            className={"h-100 w-100 text-center align-items-center pt-2 justify-content-end d-flex mx-1"}>
                            <Link to={"/myprofile"}>
                                <i className={"material-icons account-icon "}>account_circle</i>
                            </Link>
                        </div>
                        <div className={"mx-1"}>
                            <div
                                className={'text-dark'}>{userData.firstName ? userData.firstName : ""} {userData.lastName ? userData.lastName : ""} </div>
                            <div className={'text-dark'}>{userData.email ? userData.email : ""}</div>
                        </div>
                    </div>
                    <div className={"d-flex align-items-start left-space"}>
                        <div onClick={setLanguageToMacedonian} className="btn btn-sm  m-sm-1 text-white language-btn"
                        >MK
                        </div>
                        <div onClick={setLanguageToEnglish} className="btn btn-sm  m-sm-1 text-white language-btn"
                        >EN
                        </div>
                    </div>
                    <div className={"d-flex align-items-end text-start "}>
                        <div className="d-flex align-items-end justify-content-center text-end h-100 mt-auto">
                            <Notifications/>
                        </div>
                        <div className=" d-flex align-items-end justify-content-center text-end h-100">
                            <DropDown/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Navigationbar;
