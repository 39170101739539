import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReligionUnitRepository from "../../../repository/religionUnit/ReligionUnitRepository";
import {strings} from "../../../localization/Localization";

const ReligionModal = (props) => {

    const history = useHistory();

    const [formData, updateFormData] = React.useState({
        name: "",
        label_mk: "",
        label_sq: "",
        label_en: "",
    });

    useEffect(() => {
        resetFormData();
    }, [props.showModal])

    const addReligion = (name, label_mk, label_sq, label_en) => {
        ReligionUnitRepository.addReligion(name, label_mk, label_sq, label_en)
            .then((data) => {
                props.handleClose()
                successfulAlert();
                history.push("/religion")
            }).catch(() => {
            warningAlert()
        })
    };

    const editReligion = (id, name, label_mk, label_sq, label_en) => {
        ReligionUnitRepository.editReligion(id, name, label_mk, label_sq, label_en)
            .then((data) => {
                props.handleClose();
                successfulAlert();
                history.push("/religion");
            }).catch((e) => {
            warningAlert()
        })
    };

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    };

    const resetFormData = () => {
        updateFormData({
            name: "",
            label_mk: "",
            label_sq: "",
            label_en: "",
        });
    };

    const successfulAlert = () => {
        swal(strings.successfulReligion, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            } else {
                swal(strings.fileIsSafe);
            }
        });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        document.getElementsByClassName("modal-content").disabled = true;
        const id = formData.id !== "" ? formData.id : props.selectedReligionForEdit.id;
        const name = formData.name !== "" ? formData.name : props.selectedReligionForEdit.name;
        const label_mk = formData.label_mk !== "" ? formData.label_mk : props.selectedReligionForEdit.labelMk;
        const label_sq = formData.label_sq !== "" ? formData.label_sq : props.selectedReligionForEdit.labelSq;
        const label_en = formData.label_en !== "" ? formData.label_en : props.selectedReligionForEdit.labelEn;
        if (props.selectedReligionForEdit.id) {
            editReligion(props.selectedReligionForEdit.id, name, label_mk, label_sq, label_en)
        } else {
            addReligion(name, label_mk, label_sq, label_en);
        }
    }

    return (
        <Modal show={props.showModal} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(props.selectedReligionForEdit.id) ? (
                        strings.editReligion
                    ) : (
                        strings.addNewReligion
                    )
                    }
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{strings.name}</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            defaultValue={props.selectedReligionForEdit.name}
                            onChange={handleChange}
                            disabled={props.isEnabledSync}
                            name="name"
                        />
                        <Row className="p-2">
                            <Col xs={12} md={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>{strings.labelMk}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        defaultValue={props.selectedReligionForEdit.labelMk}
                                        onChange={handleChange}
                                        disabled={props.isEnabledSync}
                                        autoFocus
                                        name="label_mk"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                    <Form.Label>{strings.labelSq}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={props.selectedReligionForEdit.labelSq}
                                        onChange={handleChange}
                                        disabled={props.isEnabledSync}
                                        name="label_sq"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>{strings.labelEn}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={props.selectedReligionForEdit.labelEn}
                                        onChange={handleChange}
                                        disabled={props.isEnabledSync}
                                        name="label_en"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    {!props.isEnabledSync &&
                        <button type={"submit"} className={"save btn text-white"}>
                            {strings.save}
                        </button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default ReligionModal;