import React, { useEffect, useState } from 'react';
import { Form, Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import { strings } from '../../../localization/Localization';
import UserFilter from '../../../components/UserFilter/UserFilter';
import PositionHierarchyService from '../../../repository/hierarchyRepository/positionHierarchyRepository';
import swal from 'sweetalert';
import './PositionHierarchyUserModal.css';
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import ListForAddAndDelete from './LIstForAddAndDelete/ListForAddAndDelete';

const OrganizationalHierarchyUserModal = (props) => {

  const [showFilterForPositionList, setShowFilterForPositionList] = useState(false);

  const [showFilterForOtherUserList, setShowFilterForOtherUserList] = useState(false);


  const [otherUserList, setOtherUserList] = useState([]);

  const [pageCount, setPageCount] = useState();

  const [page, setPage] = useState(0);

  const [pageCountOtherUsers, setPageCountOtherUsers] = useState();

  const [pageOtherUsers, setPageOtherUsers] = useState(0);

  const [size, setSize] = useState(5);

  const [userPositionList, setUserPositionList] = useState([]);

  const [userForAdd, setUserForAdd] = useState([]);

  const [userForDelete, setUserForDelete] = useState([]);

  const [filterDataForPositionUserList, setFilterDataForPositionUserList] = React.useState({
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location:[]
  });

  const [filterDateForOtherUserList, updateFilterDateForOtherUserList] = React.useState({
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location:[]
  });

  const [resetIsLoadingUserForPosition, setResetIsLoadingUserForPosition] = useState(false);

  const [searchIsLoadingUserForPosition, setSearchIsLoadingUserForPosition] = useState(false);

  const [resetIsLoadingUserWithoutPosition, setResetIsLoadingUserWithoutPosition] = useState(false);

  const [searchIsLoadingUserWithoutPosition, setSearchIsLoadingUserWithoutPosition] = useState(false);

  useEffect(() => {
    if (props.showModal) {
      loadPositionsAndPageOtherUsers(0, filterDataForPositionUserList);
      loadPositionsAndPage(0, filterDateForOtherUserList);
    }
  }, []);

  useEffect(() => {
    loadPositionsAndPage(page, filterDataForPositionUserList);
  }, [page]);

  useEffect(() => {
    loadPositionsAndPageOtherUsers(page, filterDateForOtherUserList);
  }, [pageOtherUsers]);

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: 'success',
    });
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected);
  };

  const handlePageClickOtherUsers = (data) => {
    let selected = data.selected;
    setPageOtherUsers(selected);
  };

  const loadPositionsAndPage = (page, data = {
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location: []
  }, resetDate = false, searchData = false) => {
    if (resetDate) {
      setResetIsLoadingUserForPosition(true);
    } else if (searchData) {
      setSearchIsLoadingUserForPosition(true);
    }
    PositionHierarchyService.getAllUsersForPositionPaginated(props.selectedNode.id, page, size, data)
      .then((data) => {
        setUserPositionList(data.data.content);
        setPageCount(data.data.totalPages);
        if (resetDate) {
          setResetIsLoadingUserForPosition(false);
        } else if (searchData) {
          setSearchIsLoadingUserForPosition(false);
        }
      }).catch(() => {
      setResetIsLoadingUserForPosition(false);
      setSearchIsLoadingUserForPosition(false);
    });
  };

  const loadPositionsAndPageOtherUsers = (page, data = {
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location: []
  }, resetDate = false, searchData = false) => {
    if (resetDate) {
      setResetIsLoadingUserWithoutPosition(true);
    } else if (searchData) {
      setSearchIsLoadingUserWithoutPosition(true);
    }
    PositionHierarchyService.getAllOtherUserByPositionPaginated(props.selectedNode.id, pageOtherUsers, size, data).then((data) => {
      setOtherUserList(data.data.content);
      setPageCountOtherUsers(data.data.totalPages);
      if (resetDate) {
        setResetIsLoadingUserWithoutPosition(false);
      } else if (searchData) {
        setSearchIsLoadingUserWithoutPosition(false);
      }
    }).catch(() => {
      setResetIsLoadingUserWithoutPosition(false);
      setSearchIsLoadingUserWithoutPosition(false);
    });
  };

  const warningAlert = (id) => {
    swal({
      title: 'Oops...',
      text: 'Дали сте сигурни дека сакате да го отстраните корисникот?',
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],

    }).then((willDelete) => {
      if (willDelete) {
        deleteUserFromPosition(id);
      } else {
      }
    });
  };

  const loadOtherUser = (data = {
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location: []

  }) => {
    PositionHierarchyService.getAllOtherUserForPosition(props.selectedNode.id, data)
      .then((data) => {
        setOtherUserList(data.data);
      });
  };

  const handleShowFilterForPositionList = () => {
    setShowFilterForPositionList(!showFilterForPositionList);
  };

  const handleShowFilterForOtherUserList = () => {
    setShowFilterForOtherUserList(!showFilterForOtherUserList);
  };

  const removeUserFormList = (list, e) => {
    return list.filter(u => u.id != e);
  };

  const filterUserInPositionList = (firstName, lastName, parentName, email, [], religion, ethnicity, organisation, position, resetDate = false, searchData = false, hrmsIdNumber, location) => {
    setFilterDataForPositionUserList({
      firstName: firstName,
      lastName: lastName,
      parentName: parentName,
      email: email,
      hrmsIdNumber: hrmsIdNumber,
      roles: [],
      religion: religion,
      ethnicity: ethnicity,
      organisation,
      position,
      locaton: location
    });
    setPage(0);
    loadPositionsAndPage(0, {
      firstName,
      lastName,
      parentName,
      roles: [],
      hrmsIdNumber: hrmsIdNumber,
      email,
      religion,
      ethnicity,
      organisation,
      position,
      location
    }, resetDate, searchData);
  };

  const filterUserInOtherList = (firstName, lastName, parentName, email, [], religion, ethnicity, organisation, position, resetDate = false, searchData = false, hrmsIdNumber, location) => {
    updateFilterDateForOtherUserList({
      firstName: firstName,
      lastName: lastName,
      parentName: parentName,
      email: email,
      hrmsIdNumber: hrmsIdNumber,
      roles: [],
      religion: religion,
      ethnicity: ethnicity,
      organisation,
      position,
      location
    });
    setPageOtherUsers(0);
    loadPositionsAndPageOtherUsers(0, {
      firstName,
      lastName,
      parentName,
      hrmsIdNumber,
      roles: [],
      email,
      religion,
      ethnicity,
      organisation,
      position,
      location
    }, resetDate, searchData);
  };

  const resetFilterForOtherUserList = () => {
    updateFilterDateForOtherUserList({
        firstName: null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roles: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: [],
        location: []
      }
    );
  };

  const resetFilterForPositionUserList = () => {
    setFilterDataForPositionUserList({
        firstName: null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roles: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: [],
        location: []
      }
    );
  };

  const addUserInPosition = () => {
    let data = userForAdd.map(function (el,) {
      return {
        'id': el.id
      };
    });
    PositionHierarchyService.addUserInPosition(props.selectedNode.id, data)
      .then(() => {
        successfulAlert();
        setUserForAdd([]);
        setPageOtherUsers(0);
        loadPositionsAndPage(0, filterDataForPositionUserList);
        loadPositionsAndPageOtherUsers(0, filterDateForOtherUserList);
      });
  };

  const deleteUserFromPosition = (id) => {
    let data = userForDelete.map(function (el,) {
      return {
        'id': el.id
      };
    });
    PositionHierarchyService.deleteUserFromPosition(props.selectedNode.id, data)
      .then(() => {
        successfulAlert();
        setUserForDelete([]);
        setPage(0);
        loadPositionsAndPage(0, filterDataForPositionUserList);
        loadPositionsAndPageOtherUsers(0, filterDateForOtherUserList);
      });

  };

  const handleCheckInPositionUser = (event) => {
    if (event.target.checked) {
      setUserForDelete([...userForDelete, userPositionList.filter((u) => u.id == event.target.value).at(0)]);
    } else {
      setUserForDelete(removeUserFormList(removeUserFormList(userForDelete, event.target.value)));
    }
  };

  const handleCheckInOtherUserList = (event) => {
    if (event.target.checked) {
      setUserForAdd([...userForAdd, otherUserList.filter((u) => u.id == event.target.value).at(0)]);
    } else {
      setUserForAdd(removeUserFormList(removeUserFormList(userForAdd, event.target.value)));
    }
  };

  const isChecked = (list, userId) => {
    return list.filter((u) => u.id == userId).length > 0;
  };

  return (
    <>
      {props.showModal &&
        <Modal show={props.showModal} onHide={props.handleClose}
               dialogClassName='size-dialog-extra-large'
               aria-labelledby='example-custom-modal-styling-title'>
          <ModalHeader closeButton>
            <ModalTitle><b>{strings.editMemberInHierarchy}</b></ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className={'row h-100 fixed-height-table'}>
              <div className={'col-8 p-3  border-end border-muted border-2 fixed-height-table'}>
                <Form.Group className='h-100'>
                  <div>
                    <div className={'d-flex justify-content-between'}>
                      <h3>{strings.membersOfThePosition}</h3>
                      <div
                        className='btn  btn-secondary d-flex justify-content-center text-end  align-items-center users_filter_button'
                        onClick={handleShowFilterForPositionList}><i
                        className={'material-icons me-1'}>filter_list</i> {strings.filter}
                      </div>
                    </div>
                    <div className={'m-2'}>
                      {showFilterForPositionList &&
                        <UserFilter loadUserFiltred={filterUserInPositionList}
                                    resetFilterData={resetFilterForPositionUserList}
                                    resetIsLoading={resetIsLoadingUserForPosition}
                                    searchIsLoading={searchIsLoadingUserForPosition}/>}
                    </div>
                    <div className='table-responsive'>
                      <table className='table table-responsive table-borderless text-start'>
                        <thead className={'user-table-header text-dark'}>
                        <tr>
                          <th scope='col' width='5%'>
                          </th>
                          <th scope='col' width='25%'>{strings.hrmsIdNumber}</th>
                          <th scope='col' width='20%'>{strings.name}</th>
                          <th scope='col' width='20%'>{strings.lastName}</th>
                          <th scope='col' width='30%'>{strings.email}</th>
                        </tr>
                        </thead>
                        <tbody>

                        {userPositionList.length > 0 ?
                          (userPositionList.map((user) => {
                              return (
                                <tr key={user.id} scope={'row'}>
                                  <td scope='col'>
                                    <input className='form-check-input selected'
                                           value={user.id}
                                           checked={isChecked(userForDelete, user.id)}
                                           id={user.id}
                                           onChange={e => handleCheckInPositionUser(e)}
                                           type='checkbox'/>
                                    {/*            <span className={"delete-button btn "}*/}
                                    {/*                  onClick={() => {*/}
                                    {/*                      warningAlert(user.id)*/}
                                    {/*                  }} title={strings.remove}>*/}
                                    {/*    <i className={"material-icons "}>delete_forever</i>*/}
                                    {/*</span>*/}

                                  </td>
                                  <td>{user.hrmsIdNumber}</td>
                                  <td>{user.firstName}</td>
                                  <td>{user.lastName}</td>
                                  <td>{user.email}</td>
                                </tr>
                              );
                            })

                          ) :
                          (
                            <tr className={'justify-content-center text-center'}>
                              <td colSpan={4}>
                                <h6 className={'text-secondary'}>
                                  <i>{strings.noMemberInPosition}</i></h6>
                              </td>
                            </tr>
                          )
                        }
                        </tbody>
                      </table>

                      <div className={'d-flex justify-content-between my-3'}>
                        <div className={'mx-auto h-100 align-items-center  d-flex'}>
                          <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                         nextLabel={<AiOutlineArrowRight/>}
                                         forcePage={page}
                                         breakLabel={'...'}
                                         pageCount={pageCount}
                                         marginPagesDisplayed={2}
                                         pageRangeDisplayed={5}
                                         onPageChange={handlePageClick}
                                         breakClassName={'page-item'}
                                         breakLinkClassName={'page-link'}
                                         containerClassName={'pagination h-100 m-0 justify-content-center'}
                                         pageClassName={'page-item'}
                                         pageLinkClassName={'page-link'}
                                         previousClassName={'page-item'}
                                         previousLinkClassName={'page-link'}
                                         nextClassName={'page-item'}
                                         nextLinkClassName={'page-link'}
                                         activeClassName={'active'}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className={'my-3 container'}/>
                  <div className={'h-50 '}>
                    <div className={'d-flex justify-content-between'}>
                      <h3>{strings.otherUser}</h3>
                      <div
                        className='btn  btn-secondary d-flex justify-content-center text-end  align-items-center users_filter_button'
                        onClick={handleShowFilterForOtherUserList}><i
                        className={'material-icons me-1'}>filter_list</i> {strings.filter}
                      </div>
                    </div>
                    <div className={'m-2'}>
                      {showFilterForOtherUserList &&
                        <UserFilter loadUserFiltred={filterUserInOtherList}
                                    resetFilterData={resetFilterForOtherUserList}
                                    resetIsLoading={resetIsLoadingUserWithoutPosition}
                                    searchIsLoading={searchIsLoadingUserWithoutPosition}/>}
                    </div>

                    <div className='table-responsive'>
                      <table className='table table-responsive table-borderless text-start'>
                        <thead className={'user-table-header text-dark'}>
                        <tr>
                          <th scope='col' width='5%'>
                            {/*<input className="form-check-input" type="checkbox"/>*/}
                          </th>
                          <th scope='col' width='25%'>{strings.hrmsIdNumber}</th>
                          <th scope='col' width='20%'>{strings.name}</th>
                          <th scope='col' width='20%'>{strings.lastName}</th>
                          <th scope='col' width='30%'>{strings.email}</th>
                        </tr>
                        </thead>
                        <tbody>

                        {otherUserList.length > 0 ?
                          (otherUserList.map((user) => {
                              return (
                                <tr key={user.id} scope={'row'}>
                                  <td scope='col'>
                                    <input className='form-check-input selected'
                                           value={user.id}
                                           defaultChecked={isChecked(userForAdd, user.id)}
                                           id={user.id}
                                           onChange={e => handleCheckInOtherUserList(e)}
                                           type='checkbox'/>
                                  </td>
                                  <td>{user.hrmsIdNumber}</td>
                                  <td>{user.firstName}</td>
                                  <td>{user.lastName}</td>
                                  <td>{user.email}</td>
                                </tr>
                              );
                            })

                          ) :
                          (
                            <tr className={'justify-content-center text-center'}>
                              <td colSpan={4}>
                                <h6 className={'text-secondary'}>
                                  <i>{strings.noOtherUser}</i></h6>
                              </td>
                            </tr>
                          )
                        }
                        {/*<tr className={"text-start justify-content-center bg-light mt-3"}>*/}
                        {/*    <td colSpan={4}>*/}
                        {/*        <div className={"d-flex justify-content-center my-2"}>*/}
                        {/*            <div onClick={addUserInPosition}*/}
                        {/*                 className={"btn btn-sm text-white add-button"}>*/}
                        {/*                {strings.addMembers}*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        </tbody>
                      </table>

                      <div className={'d-flex justify-content-between my-3'}>
                        <div className={'mx-auto h-100 align-items-center  d-flex'}>
                          {otherUserList.length > 0 &&
                            <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                           nextLabel={<AiOutlineArrowRight/>}
                                           forcePage={pageOtherUsers}
                                           breakLabel={'...'}
                                           pageCount={pageCountOtherUsers}
                                           marginPagesDisplayed={2}
                                           pageRangeDisplayed={5}
                                           onPageChange={handlePageClickOtherUsers}
                                           breakClassName={'page-item'}
                                           breakLinkClassName={'page-link'}
                                           containerClassName={'pagination h-100 m-0 justify-content-center'}
                                           pageClassName={'page-item'}
                                           pageLinkClassName={'page-link'}
                                           previousClassName={'page-item'}
                                           previousLinkClassName={'page-link'}
                                           nextClassName={'page-item'}
                                           nextLinkClassName={'page-link'}
                                           activeClassName={'active'}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className={'col-4 p-3 h-100'}>
                <ListForAddAndDelete
                  removeFromList={removeUserFormList}
                  userForAdd={userForAdd}
                  userForDelete={userForDelete}
                  setUserForDelete={setUserForDelete}
                  setUserForAdd={setUserForAdd}
                  addUserInPosition={addUserInPosition}
                  deleteUserFromPosition={deleteUserFromPosition}

                />
              </div>
            </div>

          </ModalBody>
          <Modal.Footer>
            <div className={'cancel btn text-white'} onClick={props.handleClose}>
              {strings.close}
            </div>
            {/*<Button type={"submit"} variant="primary">*/}
            {/*    {strings.save}*/}
            {/*</Button>*/}
          </Modal.Footer>
        </Modal>
      }
    </>
  );
};
export default OrganizationalHierarchyUserModal;