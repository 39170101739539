
const MacedonianCalendar ={
    dayLabels : ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб'],
    monthLabels : [
        'Јануари',
        'Февруари',
        'Март',
        'Април',
        'Мај',
        'Јуни',
        'Јули',
        'Август',
        'Септември',
        'Октомври',
        'Ноември',
        'Декември',

    ]
}
export default MacedonianCalendar
