import Button from 'react-bootstrap/Button';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { strings } from '../../localization/Localization';
import { enUS, mk } from 'date-fns/locale';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineCopy } from 'react-icons/ai';
import Select from 'react-select';
import CalendarHistoryRepository from '../../repository/calendarHistoryRepository/CalendarHistoryRepository';
import './CalendarHistoryList.css';
import CalendarHistoryModal from './CalendarHistoryModal';
import HolidayService from '../../repository/holidayRepository/holidayRepository';
import { useHistory } from 'react-router-dom';
import SpinnerComponent from '../../components/Spinner';

const CalendarHistoryList = () => {

  const [calendarYears, setCalendarYears] = useState(null);
  const [selectedCalendarYear, setSelectedCalendarYear] = useState({
    value: null,
    label: null
  });
  const [spinnerShow, setShowSpinner] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [holidayForSelectedYear, setHolidayForSelectedYear] = useState({});

  const history = useHistory();

  useEffect(() => {
    getCalendarYears();
  }, []);

  useEffect(() => {
    getHolidayForSelectedYears(selectedCalendarYear.value);
  }, [selectedCalendarYear]);

  const locales = {
    'mk': mk,
    'en-US': enUS
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    getDay,
    locales,
  });

  const getActiveLanguage = () => {
    const activeLanguage = localStorage.getItem('activeLanguage');
    if (activeLanguage === 'mk') {
      return 'mk';
    } else {
      return 'en-US';
    }
  };

  const arrowLeft = () => {
    let index = calendarYears.map(el => el.year).indexOf(selectedCalendarYear.value);
    if ((index + 1) >= calendarYears.length) {
      return;
    }
    setSelectedCalendarYear({
      value: calendarYears[index + 1]?.year,
      label: calendarYears[index + 1]?.year
    });
  };

  const arrowRight = () => {
    let index = calendarYears.map(el => el.year).indexOf(selectedCalendarYear.value);
    if (index <= 0) {
      return;
    }
    setSelectedCalendarYear({
      value: calendarYears[index - 1]?.year,
      label: calendarYears[index - 1]?.year
    });
  };

  const customviews = ['week', 'month'];

  const components = useMemo(() => ({
    toolbar: () => (<span className='toolbar_border'>{strings.january}</span>)
  }), []);

  const getCalendarYears = () => {
    CalendarHistoryRepository.fetchCalendarYears().then((data) => {
      setCalendarYears(data.data);
      setSelectedCalendarYear({
        value: data.data[0].year,
        label: data.data[0].year
      } ?? {
        value: new Date().getFullYear(),
        label: new Date().getFullYear()
      });
    });
  };

  const getHolidayForSelectedYears = (date) => {
    setShowSpinner(false);
    HolidayService.getHolidayBySelectedYear(date)
      .then((data) => {
        setHolidayForSelectedYear(data.data);
        setShowSpinner(true);
      }).catch(() => setShowSpinner(true));
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: 'black',
      backgroundColor: state.isFocused ? '#b2d2f8' : 'white',
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '20px',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: state.isFocused ? '#b2d2f8' : '#b2d2f8'
      },
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      textAlign: 'center',
    })
  };

  const redirectToHoliday = (month) => {
    if (month != null) {
      let previousMenuItem = document.getElementsByClassName('pro-menu-item active')[0];
      previousMenuItem.classList.remove('active');
      let currentMenuItem = document.getElementById('holidays');
      currentMenuItem.classList.add('active');
      var year = selectedCalendarYear.value;
      if (year != null) {
        history.push('/holidays?year=' + year + '&&month=' + month);
      }
    }
  };

  const dayPropGetter = (monthNum, date) => {
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    if (month == monthNum) {
      var getHolidayForDay = holidayForSelectedYear[month];
      if (getHolidayForDay != null) {
        for (var i = 0; i < getHolidayForDay.length; i++) {
          if (getHolidayForDay[i].day === day && getHolidayForDay[i].month === month && getHolidayForDay[i].year === year) {
            return {
              style: { backgroundColor: 'lightseagreen' }
            };
          }
        }
      }
    }
  };

  return (
    <div className={' pb-4 pt-1'}>
      <div className=' text-center w-100 d-flex justify-content-between '>
        <h1 className='ms-2 h-100 '>{strings.calendarHistory}</h1>
        {/* <div className={'btn my-auto h-50 d-flex align-items-center border-radius text-white save '} */}
        {/*      title={strings.copyCalendar} onClick={handleShow}><AiOutlineCopy/> {strings.copyCalendar} */}
        {/* </div> */}

      </div>
      <SpinnerComponent spinnerShow={spinnerShow}/>
      <div className='row mt-2 mb-4 justify-content-center'>
        <Button className='col-1 btn-close-white'><AiOutlineArrowLeft size={23} onClick={arrowLeft}/></Button>
        <Select name='years'
                className='col-6 pt-2 select'
                styles={styles}
                noOptionsMessage={() => strings.noOptions}
                options={
                  calendarYears?.map((item) => (
                    {
                      value: item.year,
                      label: item.year
                    }
                  ))}
                value={selectedCalendarYear}
                onChange={(val) => setSelectedCalendarYear(val)}
                isSearchable={true}
                maxMenuHeight={250}

        />
        <Button className='col-1 btn-close-white'><AiOutlineArrowRight size={23} onClick={arrowRight}/></Button>

      </div>
      <CalendarHistoryModal showModal={showModal} handleClose={handleClose} setShowSpinner={setShowSpinner}
                            getCalendarYears={getCalendarYears}/>
      <div className='row m-2 gy-4'>
        <div className='col-md-4' onClick={() => redirectToHoliday(0)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={components} views={customviews}
                    disabled={true}
                    dayPropGetter={(val) => dayPropGetter(0, val)}
                    culture={getActiveLanguage()} date={new Date(selectedCalendarYear.value, 0, 1)}
          />
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(1)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.february}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(1, val)}
                    date={new Date(selectedCalendarYear.value, 1, 1)}
          />
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(2)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.march}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(2, val)}
                    date={new Date(selectedCalendarYear.value, 2, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(3)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.april}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(3, val)}
                    date={new Date(selectedCalendarYear.value, 3, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(4)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.may}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(4, val)}
                    date={new Date(selectedCalendarYear.value, 4, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(5)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.june}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(5, val)}
                    date={new Date(selectedCalendarYear.value, 5, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(6)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.july}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(6, val)}
                    date={new Date(selectedCalendarYear.value, 6, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(7)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.august}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(7, val)}
                    date={new Date(selectedCalendarYear.value, 7, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(8)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.september}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(8, val)}
                    date={new Date(selectedCalendarYear.value, 8, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(9)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.october}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(9, val)}
                    date={new Date(selectedCalendarYear.value, 9, 1)}/>
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(10)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.november}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(10, val)}
                    date={new Date(selectedCalendarYear.value, 10, 1)}
          />
        </div>
        <div className='col-md-4' onClick={() => redirectToHoliday(11)}>
          <Calendar localizer={localizer} startAccessor='start'
                    endAccessor='end' components={useMemo(() => ({
            toolbar: () => (<span className='toolbar_border'>{strings.december}</span>)
          }), [])}
                    views={customviews}
                    culture={getActiveLanguage()}
                    dayPropGetter={(val) => dayPropGetter(11, val)}
                    date={new Date(selectedCalendarYear.value, 11, 1)}/>
        </div>
      </div>
    </div>

  );
};

export default CalendarHistoryList;
