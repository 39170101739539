import React, { Fragment } from 'react';

import '../User_modal/UserUpsertModal.css';
import swal from 'sweetalert';
import { strings } from '../../../localization/Localization';

const UserTerm = (props) => {

  const deleteConfirmAlert = () => {
    swal({
      title: strings.areYouSure,
      text: strings.deleteConfirmationNoQuestionMark + '\n' + props.term.email + '?' + '\n ' + strings.thisUserCannotBeRestored,
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],
    }).then((willSuccess) => {
      if (willSuccess) {
        props.deleteUser(props.term.id);
        swal(strings.userIsDeleted, {
          icon: 'success',
        });
      } else {
      }
    });
  };

  return (
    <tr className='userlist' key={props.term.id}>
      <td className={'ps-2 mx-1 pe-2'} title={props.term?.person?.hrmsIdNumber}>{props.term?.person?.hrmsIdNumber}</td>
      <td className={'ps-2 mx-1'} title={props.term.firstName}>{props.term.firstName}</td>
      <td className={'px-1'} title={props.term.person?.parentName}>{props.term.person?.parentName}</td>
      <td className={'ps-1'} title={props.term.lastName}>{props.term.lastName}</td>
      <td className={'mx-2 px-3'} title={props.term.email}>{props.term.email}</td>
      <td title={props.term.roles.map((e) => {
        return (
          e + ' '
        );
      })}>
        {props.term.roles.map((e) => {
          return (

            <Fragment key={e}>

              {e + ' '} <br/>
            </Fragment>
          );
        })}
      </td>
      <td className={'px-2'} title={props.term?.locationName}>{props.term?.locationName}</td>
      <td className={'px-2'} title={props.term?.organisation?.name}>{props.term?.organisation?.name}</td>
      <td className={'px-2'} title={props.term?.position?.name}>{props.term?.position?.name}</td>
      <td className={'h-100'}>
        <div className={'d-inline-flex pe-2'}>
          <div className={'icon-div ms-3'}>
            <a
              onClick={() => props.onClickSetSelectedUserForEdit(props.term)}
              title={'Edit'}
              className={'text-dark icon'}>
              <i className={'edit-icon-circle material-icons '}>
                create
              </i>
            </a>
          </div>
          <div className={'icon-div mx-2'}>
            <a
              onClick={() => props.onClickSetSelectedUserForChangePassword(props.term)}
              title={'Edit'}
              className={'text-dark icon'}>
              <i className={'edit-icon-circle material-icons gray_color'}>
                lock
              </i>
            </a>
          </div>
          {!props.isEnabledSync &&
            <div className={'icon-div'}>
              <a title={'Delete'} type={'Button'}
                 onClick={deleteConfirmAlert}>
                <i className={'material-icons delete-icon-circle'}>delete</i>
              </a>
            </div>
          }
        </div>
      </td>
    </tr>
  );
};
export default UserTerm;