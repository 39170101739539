import axios from "axios";
import {AUTH_TOKEN} from "../../shared/utility";

const UserAddService = {
    addUser: (email, firstName, lastName, parentName, roleIds, phoneNumber, address,ethnicityId,religionId, hrmsIdNumber) => {
        var auth_token = localStorage.getItem(AUTH_TOKEN);
        return axios.post(process.env.REACT_APP_HOST_ENV + '/register', {
            firstName,
            lastName,
            roleIds,
            phoneNumber,
            address,
            parentName,
            ethnicityId,
            religionId,
            hrmsIdNumber
        }, {
            headers: {
                'Content-Type': 'application/json',
                'email': email,
                'Authorization': `Bearer ${auth_token}`
            }
        },)
    },
    changePassword: (newPassword, repeatNewPassword, confirmationToken) => {
        return axios.post(process.env.REACT_APP_HOST_ENV + `/user/changePassword/${confirmationToken}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                "newPassword": newPassword,
                "repeatNewPassword": repeatNewPassword
            }
        })
    },
    changePasswordWithoutToken: (email,oldPassword,newPassword, repeatNewPassword) => {
        return axios.post(process.env.REACT_APP_HOST_ENV + `/user/changePassword`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'email': email,
                "oldPassword":oldPassword,
                "newPassword": newPassword,
                "repeatNewPassword": repeatNewPassword
            }
        })
    },
    changePasswordByAdminAndSuperAdmin: (email,newPassword, repeatNewPassword) => {
        let auth_token = localStorage.getItem(AUTH_TOKEN);
        return axios.post(process.env.REACT_APP_HOST_ENV + `/user/changePasswordByAdminAndSuperAdmin`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'email': email,
                "newPassword": newPassword,
                "repeatNewPassword": repeatNewPassword,
                'Authorization': `Bearer ${auth_token}`
            }
        })
    }
}
export default UserAddService;