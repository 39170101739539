import React from 'react';
import ReactPaginate from 'react-paginate';
import { useEffect, useState } from 'react';
import UserService from '../../../repository/userRepository/UserRepository';
import '../../../components/Table.css';
import RolesRepository from '../../../repository/rolesRepository/RolesRepository';
import SpinnerComponent from '../../../components/Spinner';
import decode from 'jwt-decode';
import { strings } from '../../../localization/Localization';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import EmployeeTerm from '../EmployeeTerm/EmployeeTerm';
import EmployeeUpsertModal from '../Employee_modal/EmployeeUspertModal';
import UserFilter from '../../../components/UserFilter/UserFilter';
import evidentionRepository from '../../../repository/evidentionRepository/evidentionRepository';
import Select from 'react-select';
import ExportService from '../../../repository/exportRepository/exportRepository';
import swal from 'sweetalert';

const EmployeeList = (props) => {

  const [page, setPage] = useState(0);

  const [size, setSize] = useState(10);

  const [allRoles, setAllRoles] = useState([]);

  const [offset, setOffset] = useState(0);

  const [nextPageOffset, setNextPageOffset] = useState(0);

  const [employeeList, setEmployeeList] = useState([]);

  const [pageCount, setPageCount] = useState();

  const [showModal, setShow] = useState(false);

  const [listForDropDown, setListForDropDown] = useState([]);

  const [selectedEmployeeForEdit, setSelectedEmployeeForEdit] = useState({});

  const [detailsForEmployee, setDetailsForEmployee] = useState([]);

  const [listOfDefaultSelectedRolesForDropDown, setListOfDefaultSelectedRolesForDropDown] = useState([]);

  const [spinnerShow, setShowSpinner] = useState(true);

  const [decodeToken, setDecodeToken] = useState({});

  const [showFilter, setShowFilter] = useState(false);

  const FileSaver = require('file-saver');

  const [filterEmployee, setFilterEmployee] = React.useState({
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location: []
  });

  const [resetIsLoading, setResetIsLoading] = useState(false);

  const [searchIsLoading, setSearchIsLoading] = useState(false);

  useEffect(() => {
    loadEmployeeAndPage(page, size, filterEmployee);
    const token = localStorage.getItem('auth_token');
    setDecodeToken(decode(token));
  }, [page, size]);

  const resetFilterData = () => {
    setFilterEmployee({
      firstName: null,
      lastName: null,
      parentName: null,
      email: null,
      hrmsIdNumber: null,
      roles: [],
      religion: [],
      ethnicity: [],
      organisation: [],
      position: [],
      location: []
    });
  };

  const onClickSetSelectedEmployeeForEdit = (e) => {
    loadDetailsForEumployee(e.id);
    setDefaultSelectedEmployee(e);
    handleShow();
  };

  const setDefaultSelectedEmployee = (e) => {
    setSelectedEmployeeForEdit(e);
  };

  const loadDetailsForEumployee = (id) => {
    evidentionRepository.getHolidays(id)
      .then((data) => {
        setDetailsForEmployee(data.data);

      });
  };

  const loadAllRoles = () => {
    RolesRepository.fetchRoles()
      .then((data) => {
        setAllRoles(data.data);
      });
  };

  const handleClose = () => {
    setShow(false);
    // loadEmployeeAndPage();
  };

  const handleShow = () => {
    loadAllRoles();
    setShow(true);
  };

  const handleSpinnerClose = () => {
    setShowSpinner(true);
  };

  const handleSpinnerShow = () => {
    setShowSpinner(false);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const successfulAlert = () => {
    swal(strings.fileDownloaded, {
      icon: "success"
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
      } else {
      }
    });
  };

  const loadEmployeeAndPage = (page, size, data) => {
    setShowSpinner(false);
    UserService.fetchUsers(page, size, data)
      .then((data) => {
        setPageCount(data.data.totalPages);
        setEmployeeList(data.data.content);
        setOffset(data.data.pageable.offset);
        setNextPageOffset(data.data.pageable.offset + size);
        setShowSpinner(true);
      }).catch(() => {
      setShowSpinner(true);
    });
  };

  const loadEmployeeAndPageFiltered = (firstName, lastName, parentName, email, roles, religion, ethnicity, organisationsIds, positionsIds, resetDate = false, searchData = false, hrmsIdNumber,location) => {
    if (resetDate) {
      setResetIsLoading(true);
    } else if (searchData) {
      setSearchIsLoading(true);
    }
    setFilterEmployee({
      firstName: firstName,
      lastName: lastName,
      parentName: parentName,
      email: email,
      hrmsIdNumber: hrmsIdNumber,
      roles: roles,
      religion: religion,
      ethnicity: ethnicity,
      organisation: organisationsIds,
      position: positionsIds,
      location: location
    });
    UserService.fetchUsers(0, size, {
      firstName,
      lastName,
      parentName,
      email,
      hrmsIdNumber,
      roles,
      religion,
      ethnicity,
      organisation: organisationsIds,
      position: positionsIds,
      location:location
    })
      .then((data) => {
        setPageCount(data.data.totalPages);
        setEmployeeList(data.data.content);
        setPage(0);
        setOffset(data.data.pageable.offset);
        setNextPageOffset(data.data.pageable.offset + size);
        if (resetDate) {
          setResetIsLoading(false);
        } else if (searchData) {
          setSearchIsLoading(false);
        }
      }).catch(() => {
      setResetIsLoading(false);
      setSearchIsLoading(false);
    });
  };

  const getEmployeeExportFIle = () => {
    setShowSpinner(false)
    ExportService.getEmployeeFile(filterEmployee)
      .then((data) => {
        fetch(process.env.REACT_APP_HOST_ENV + `/exportUser/download/${data.data}`,
          {
            origin: 'same-origin',
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('auth_token')
            }
          }
        ).then(res => res.blob())
          .then(file => FileSaver.saveAs(file, data.data + '.xlsx'));
      })
      .then(() => {
        successfulAlert();
      })
      .catch(() => {
        warningAlert();
      })
      .finally(() => {
        setShowSpinner(true);
      });
  };

  const getEmployeePage = () => {
    return employeeList.map((term) => {
      return (
        <EmployeeTerm
          key={term.id}
          onClickSetSelectedEmployeeForEdit={onClickSetSelectedEmployeeForEdit}
          term={term}
          setEmployeeList={setEmployeeList}
          setPageCount={setPageCount}
          setOffset={setOffset}
          setNextPageOffset={setNextPageOffset}
        />
      );
    });
  };

  const employeeTermPage = getEmployeePage(offset, nextPageOffset);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected);
  };

  const selectSize = (e) => {
    setPage(0);
    setSize(e.value);
  };

  return (
    <>
      <div className='w-100  justify-content-between d-flex align-items-center '>
        <div className={'d-flex align-items-end mt-auto'}>
          <h1 className='text-end h-100 align-middle'>{strings.EmployeeList}</h1>
        </div>
        <div className={'d-flex'}>
          <div
            className='btn h-50 btn-secondary d-flex justify-content-center text-end  align-items-end users_filter_button'
            onClick={handleFilter}><i
            className={'material-icons users_filter_button_icon me-1'}>filter_list</i> {strings.filter}
          </div>
          <div className='btn border-radius text-white save  ms-2' onClick={getEmployeeExportFIle}>
            <div className={'d-flex   justify-content-center text-start'}>
              <div>
                <i className='material-icons text-white align-items-center d-flex'>sim_card_download</i>
              </div>
              <div className={'mx-1'}>
                {strings.downloadFile}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showFilter && <UserFilter loadUserFiltred={loadEmployeeAndPageFiltered} resetFilterData={resetFilterData}
                                   resetIsLoading={resetIsLoading} searchIsLoading={searchIsLoading}/>}
      </div>
      <SpinnerComponent spinnerShow={spinnerShow}/>
      <div className={'container pb-3'}>
        <div>
          <div className={'row table-responsive'}>
            <table id='keywords' className={'mb-1'}>
              <thead>
              <tr>
                <th scope={'col'}>{strings.hrmsIdNumber}</th>
                <th scope={'col'}>{strings.name}</th>
                <th scope={'col'}>{strings.lastName}</th>
                <th scope={'col'}>{strings.parentName}</th>
                <th scope={'col'}>{strings.email}</th>
                <th scope={'col'}>{strings.roles}</th>
                <th scope={'col'}>{strings.location}</th>
                <th scope={'col'}>{strings.organization}</th>
                <th scope={'col'}>{strings.position}</th>
              </tr>
              </thead>
              <tbody>
              {employeeList.length > 0 &&
                employeeTermPage
              }
              </tbody>
            </table>
            {
              employeeList.length === 0 &&
              <div className={'justify-content-center text-center w-100'}>
                <div>
                  <h5 className={'text-secondary w-100 '}>
                    <i>{strings.emptyList}</i></h5>
                </div>
              </div>
            }
          </div>
          {employeeList.length !== 0 &&
            <div className={'d-flex justify-content-between my-3'}>
              <div className={'mx-auto h-100 align-items-center  d-flex'}>
                <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                               nextLabel={<AiOutlineArrowRight/>}
                               breakLabel={'...'}
                               pageCount={pageCount}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               forcePage={page}
                               onPageChange={handlePageClick}
                               breakClassName={'page-item'}
                               breakLinkClassName={'page-link'}
                               containerClassName={'pagination h-100 m-0 justify-content-center'}
                               pageClassName={'page-item'}
                               pageLinkClassName={'page-link'}
                               previousClassName={'page-item'}
                               previousLinkClassName={'page-link'}
                               nextClassName={'page-item'}
                               nextLinkClassName={'page-link'}
                               activeClassName={'active'}/>
              </div>
              <div className={'d-flex align-items-end'}>
                <Select
                  placeholder={strings.size}
                  onChange={selectSize}
                  noOptionsMessage={() => strings.noOptions}
                  options={[
                    {
                      value: 5,
                      label: '5'
                    },
                    {
                      value: 10,
                      label: '10'
                    },
                    {
                      value: 15,
                      label: '15'
                    },
                    {
                      value: 20,
                      label: '20'
                    }
                  ]}
                  defaultValue={{
                    value: 10,
                    label: '10'
                  }}
                  styles={{
                    control: base => ({
                      ...base,
                      height: 40,
                      border: 'none'
                    })
                  }}
                />
              </div>
            </div>
          }
        </div>
      </div>
      <SpinnerComponent spinnerShow={spinnerShow}/>
      <EmployeeUpsertModal showModal={showModal}
                           handleClose={handleClose}
                           listForDropDown={listForDropDown}
                           defaultListForDropdown={listOfDefaultSelectedRolesForDropDown}
                           selectedEmployeeForEdit={selectedEmployeeForEdit}
                           handleSpinnerShow={handleSpinnerShow}
                           handleSpinnerClose={handleSpinnerClose}
                           loadEmployeeAndPage={loadEmployeeAndPage}
                           detailsForEmployee={detailsForEmployee}
      />
    </>
  );
};
export default EmployeeList;