import {Route, Switch,} from 'react-router-dom';
import Login from "./pages/Login/Login";
import Finalized_registration from "./pages/FinalizedRegistration/Finalized_registration";
import changePassword from "./components/ChangePassword";
import MainLayout from "./components/Layout/MainLayout";
import EthnicityList from "./pages/Ethnicity/Ethnicity_list/EthnicityList";
import ReligionList from "./pages/ReligionsUnit/ReligionsList/ReligionList"
import LeaveRequestsList from "./pages/LeaveRequests/MyLeaveRequest/LeaveRequestList/LeaveRequestsList";
import UserList from "./pages/User/UserList/UserList";
import MyProfile from "./pages/MyProfile/MyProfile";
import Holiday from "./pages/Calendar/Holiday/Holiday";
import CalendarList from "./pages/Calendar/CalendarList/CalendarList";
import Logout from "./pages/Logout/Logout";
import ProtectedRoute from "./ProtectedRoute";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import StatusCalendar from "./pages/StatusCalendar/StatusCalendar";
import EmployeeList from "./pages/EmpolyeeEvidention/EmployeeList/EmployeeList";
import Home from "./pages/Home/Home";
import CalendarHistoryList from "./pages/CalendarHistory/CalendarHistoryList";
import OrganizationalHierarchyList
    from "./pages/OrganizationalHierarchy/OrganizationalHierarchyList/OrganizationalHierarchyList";
import DeliveredRequestList from "./pages/LeaveRequests/DeliveredLeaveRequest/DeliveredRequestList";
import AbsenceStatus from "./pages/AbsenceStatus/AbsenceStatus";
import PositionHierarchyList from "./pages/PositionHierarchy/PositionHierarchyList/PositionHierarchyList";
import Settings from "./pages/Settings/Settings";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import LoginMobilePage from "./pages/LoginMobilePage/LoginMobilePage";
import ChangePasswordMobile from "./pages/ChangePasswordMobile/ChangePasswordMobile";
import FinalizedRegistrationMobile from "./pages/FinalizedRegistrationMobile/FinalizedRegistrationMobile";
import ForgotPasswordMobile from "./pages/ForgotPasswordMobile/ForgotPasswordMobile";

const Routes = () => {

    const windowSize = window.innerWidth;

    return (
        <div className={"h-100"}>
            <Switch>
                <Route exact path="/login" component={windowSize > 567 ? (Login) : (LoginMobilePage)}/>
                <Route exact path="/" component={windowSize > 567 ? (Login) : (LoginMobilePage)}/>
                {/*<Route exact path="/register" component={Register}/>*/}
                <Route exact path="/finalized_registration" component={windowSize > 567 ? (Finalized_registration) : (FinalizedRegistrationMobile)}/>
                <Route exact path="/forgotPassword" component={windowSize > 567 ? (ForgotPassword):(ForgotPasswordMobile)}/>
                <Route exact path="/accessDenied" component={AccessDenied}/>
                <Route exact path="/changePassword" component={windowSize > 567 ? (changePassword) : (ChangePasswordMobile)}/>
                <Route exact path="/mainlayout" component={MainLayout}/>
                <ProtectedRoute exact path="/myProfile" authorizedRoles={[]} component={MyProfile}/>
                <Route path="/logout" component={Logout}/>
                <Route exact
                       path={['/home', '/employeelist', '/religion', '/leaveRequests', '/arrivedRequest', '/organisation',
                           '/user', '/ethnicity', '/holiday', '/holiday/:id', '/holidays', "/status", "/calendarhistory", "/position", "/absence", "/settings"]}>
                    <MainLayout>
                        <ProtectedRoute exact path="/home" authorizedRoles={[]} component={Home}/>
                        <ProtectedRoute exact path="/employeelist"
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        component={EmployeeList}/>
                        <ProtectedRoute exact path="/religion"
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        component={ReligionList}/>
                        <ProtectedRoute exact path="/organisation"
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        component={OrganizationalHierarchyList}/>
                        <ProtectedRoute exact path="/position"
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        component={PositionHierarchyList}/>
                        <ProtectedRoute exact path='/user' authorizedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                        component={UserList}/>
                        <ProtectedRoute exact path='/holidays'
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR","ROLE_HUMAN_RESOURCES"]}
                                        component={CalendarList}/>
                        <ProtectedRoute exact path='/holiday'
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR","ROLE_HUMAN_RESOURCES"]}
                                        component={Holiday}/>
                        <ProtectedRoute exact path='/holiday/:id'
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR","ROLE_HUMAN_RESOURCES"]}
                                        component={Holiday}/>
                        <ProtectedRoute exact path='/status'
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        component={StatusCalendar}/>
                        <ProtectedRoute exact path='/ethnicity'
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        component={EthnicityList}/>
                        <ProtectedRoute exact path='/leaveRequests'
                                        authorizedRoles={[]}
                                        component={LeaveRequestsList}/>
                        <ProtectedRoute exact path='/arrivedRequest'
                                        // authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_HEAD_OF_SECTOR"]}
                                        authorizedRoles={[]}
                                        component={DeliveredRequestList}/>
                        <ProtectedRoute exact path='/calendarhistory'
                                        authorizedRoles={["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]}
                                        component={CalendarHistoryList}/>
                        <ProtectedRoute exact path='/absence'
                                        authorizedRoles={[]}
                                        component={AbsenceStatus}/>
                        <ProtectedRoute exact path='/settings'
                                        authorizedRoles={["ROLE_SUPER_ADMIN"]}
                                        component={Settings}/>
                    </MainLayout>
                </Route>
            </Switch>
        </div>
    );
}

export default Routes;
