import React from "react";
import {Form, Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import {strings} from "../../../localization/Localization";
import OrganisationHierarchyService from "../../../repository/hierarchyRepository/organisationHierarchyRepository";

const OrganizationalHierarchyEditModal = (props) => {

    const [formData, updateFormData] = React.useState({
        hrmsStPositionsHId: "",
        activeFrom: "",
        activeTo: "",
        code: "",
        name: "",
        shortName: "",
        description: "",
        dateCreated: "",
        dateModified: "",
        parentId: ""
    });

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    };

    const editHierarchy = (id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified, childrenOrganisations) => {
        OrganisationHierarchyService.editHierarchy(id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,childrenOrganisations)
            .then((data) => {
                props.loadHierarchy(true);
                props.handleClose();
            });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        const hrmsStPositionsHId = formData.hrmsStPositionsHId !== "" ? formData.emhrmsStPositionsHIdail : props.selectedNode.emhrmsStPositionsHIdail;
        const activeFrom = formData.activeFrom !== "" ? formData.activeFrom : props.selectedNode.activeFrom;
        const activeTo = formData.activeTo !== "" ? formData.activeTo : props.selectedNode.activeTo;
        const code = formData.code !== "" ? formData.code : props.selectedNode.data.code;
        const name = formData.name !== "" ? formData.name : props.selectedNode.text;
        const description = formData.description !== "" ? formData.description : props.selectedNode.data.description;
        const shortName = formData.shortName !== "" ? formData.shortName : props.selectedNode.data.shortName;
        const dateCreated="";
        const dateModified="";

        if (props.selectedNode.id)
            editHierarchy(props.selectedNode.id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,props.selectedNode.data.parentId);
    };

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose} size={"lg"} centered>
                <ModalHeader closeButton>
                    <ModalTitle><b>{strings.editHierarchy}</b></ModalTitle>
                </ModalHeader>
                <Form onSubmit={onFormSubmit}>
                    <ModalBody>
                        <Form.Group className="mb-3" controlId="hierarchyName">
                            <Form.Group>
                                <Form.Label>{strings.edit} {strings.hierarchyName}</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.selectedNode?.text}
                                    onChange={handleChange}
                                    autoFocus
                                    name="name"/>
                            </Form.Group>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{strings.edit} {strings.hierarchyShortName}</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={props.selectedNode?.data?.shortName}
                                onChange={handleChange}
                                name="shortName"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{strings.edit} {strings.hierarchyDescription}</Form.Label>
                            <textarea name="description" className="form-control"
                                      defaultValue={props.selectedNode?.data?.description}
                                      onChange={handleChange}
                                      id="exampleFormControlTextarea1" rows="2"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{strings.edit} {strings.hierarchyCode}</Form.Label>
                            <textarea name="code" className="form-control"
                                      defaultValue={props.selectedNode?.data?.code}
                                      onChange={handleChange}
                                      id="exampleFormControlTextarea1" rows="2"/>
                        </Form.Group>
                    </ModalBody>
                    <Modal.Footer>
                        <div className={"cancel btn text-white"} onClick={props.handleClose}>
                            {strings.cancel}
                        </div>
                        <div type={"submit"} className={"save btn text-white"} onClick={onFormSubmit}>
                            {strings.save}
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default OrganizationalHierarchyEditModal;