import {Menu, MenuItem, ProSidebar, SidebarHeader, SubMenu} from "react-pro-sidebar";
import {
    FaRegCalendarAlt,
    FaList,
    FaFlag,
    FaUserCircle,
    FaClipboardList,
    FaCalendarCheck,
    FaBook,
    FaUserTag,
    FaHistory,
    FaHome, FaSitemap, FaFileExport
} from "react-icons/fa"
import {FiSettings} from "react-icons/fi"
import {BsBookmarkCheckFill} from "react-icons/bs"
import {GiChurch, GiFiles} from "react-icons/gi"
import React, {useEffect, useState} from "react";
import {RiAdminFill} from "react-icons/ri";
import {strings} from "../localization/Localization";
import "./Layout/MainLayout.css";
import {Link} from "react-router-dom";
import ApplicationService from "../ApplicationService";

const Sidebar = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isOpen, setIsOpen] = useState(true);

    const [activePage, setActivePage] = useState(null);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [hovered, setHovered] = useState(false);

    const [openSubMenu, setOpenSubMenu] = useState(false);

    function getWindowSize() {
        const {innerWidth} = window;
        return {innerWidth};
    }

    // useEffect(() => {
    //     function handleWindowResize() {
    //         setWindowSize(getWindowSize());
    //     }
    //
    //     window.addEventListener('resize', handleWindowResize);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //
    //     };
    // }, []);

    function handleActive(event) {
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.toggle("active");
            if (activePage)
                activePage.classList.remove("active");
            setActivePage(event.target);
        }
    }

    useEffect(() => {
        const data = window.localStorage.getItem('isOpen');
        if (data === null) {
            if (windowSize.innerWidth < 700) {
                window.localStorage.setItem('isOpen', false);
                setIsOpen(true);
            } else {
                window.localStorage.setItem('isOpen', true);
                setIsOpen(false);
            }
        } else {
            setIsOpen(data === true ? true : false)
        }
    }, [])

    function OnSideBarOpen() {
        setIsOpen(!isOpen);
        window.localStorage.removeItem('isOpen');
        window.localStorage.setItem('isOpen', JSON.stringify(isOpen));
    }

    const scrollClass = hovered ? '' : 'hide-scroll';

    const handleHover = () => setHovered((prevState) => !prevState)

    const isActive = (url) => {
        const location = window.location.pathname;
        return location == url;
    };

    return (
        <>
            <div className={`d-flex flex-row  ${scrollClass}`}
                 onMouseOver={handleHover}
                 onMouseOut={handleHover}
            >
                <div>
                    <ProSidebar name="sidebar" collapsed={isOpen} collapsedWidth={windowSize.innerWidth>990 ? "10vh" :"8vh" }>
                        <Menu iconShape="circle">
                            <SidebarHeader>
                                <MenuItem icon={<FaList/>} onClick={OnSideBarOpen}
                                          active={false}><i>{strings.minimizeThisMenu}</i></MenuItem>
                            </SidebarHeader>
                            <MenuItem icon={<FaHome/>} active={isActive("/home")}><Link onClick={handleActive}
                                                                            to="/home"> {strings.home}</Link></MenuItem>
                            {ApplicationService.hasRole(["ROLE_SUPER_ADMIN","ROLE_ADMIN","ROLE_HEAD_OF_SECTOR"]) &&
                                <SubMenu active={true} title={strings.administration} icon={<RiAdminFill/>}>
                                    {ApplicationService.hasRole(["ROLE_SUPER_ADMIN","ROLE_ADMIN"]) &&
                                        <MenuItem icon={<FaUserCircle/>} id={"user"} active={isActive("/user")}><Link
                                            onClick={handleActive}
                                            to="/user">{strings.users}</Link></MenuItem>
                                    }
                                    <MenuItem icon={<GiChurch/>} id={"religion"}
                                              active={isActive("/religion")}><Link
                                        onClick={handleActive}
                                        to="/religion">{strings.religion}</Link></MenuItem>
                                    <MenuItem icon={<FaFlag/>} id={"ethnicity"}
                                              active={isActive("/ethnicity")}><Link
                                        onClick={handleActive}
                                        to="/ethnicity">{strings.ethnicity}</Link></MenuItem>
                                    <MenuItem icon={<FaSitemap/>} id={"organisation"}
                                              active={isActive("/organisation")}><Link
                                        onClick={handleActive}
                                        to="/organisation">{strings.organizationalHierarchy}</Link></MenuItem>
                                    <MenuItem icon={<FaSitemap/>} id={"position"}
                                              active={isActive("/position")}><Link
                                        onClick={handleActive}
                                        to="/position">{strings.positions}</Link></MenuItem>
                                    {ApplicationService.hasRole(["ROLE_SUPER_ADMIN"]) &&
                                        < MenuItem icon={<FiSettings/>} id={"settings"}
                                                   active={isActive("/settings")}><Link
                                            onClick={handleActive}
                                            to="/settings">{strings.settings}</Link></MenuItem>
                                    }
                                </SubMenu>
                            }
                            {ApplicationService.hasRole(["ROLE_SUPER_ADMIN","ROLE_ADMIN","ROLE_HEAD_OF_SECTOR","ROLE_HUMAN_RESOURCES"]) &&
                                <MenuItem icon={<FaRegCalendarAlt/>} id={"holidays"}
                                          active={isActive("/holidays")}><Link
                                    onClick={handleActive}
                                    to="/holidays"> {strings.holidays}</Link></MenuItem>
                            }
                            <SubMenu title={strings.absence}
                                     icon={<FaClipboardList/>}>
                                <MenuItem icon={<FaFileExport/>} id={"leaveRequests"}
                                          active={isActive("/leaveRequests")}>
                                    <Link onClick={handleActive} to="/leaveRequests"> {strings.myRequest}</Link>
                                </MenuItem>
                                <MenuItem icon={<BsBookmarkCheckFill/>} id={"absence"}
                                          active={isActive("/absence")}><Link
                                    onClick={handleActive}
                                    to="/absence"> {strings.allowedAbsence}</Link></MenuItem>
                                <MenuItem icon={<GiFiles/>} id={"arrivedRequest"}
                                          active={isActive("/arrivedRequest")}>
                                    <Link onClick={handleActive}
                                          to="/arrivedRequest"> {strings.deliverRequest}</Link>
                                </MenuItem>
                            </SubMenu>
                            {ApplicationService.hasRole(["ROLE_SUPER_ADMIN","ROLE_ADMIN","ROLE_HEAD_OF_SECTOR"]) &&
                                <SubMenu title={strings.evidention} icon={<FaBook/>}>
                                    <MenuItem icon={<FaCalendarCheck/>} id={"status"}
                                              active={isActive("/status")}><Link
                                        onClick={handleActive}
                                        to="/status">{strings.absenceCalendar}</Link></MenuItem>
                                    <MenuItem icon={<FaUserTag/>} id={"employeelist"}
                                              active={isActive("/employeelist")}><Link
                                        onClick={handleActive}
                                        to="/employeelist">{strings.userevidention}</Link></MenuItem>
                                </SubMenu>
                            }
                            {ApplicationService.hasRole(["ROLE_SUPER_ADMIN"]) &&
                                <MenuItem icon={<FaHistory/>} id={"calendarHistory"}
                                          active={isActive("/calendarHistory")}><Link
                                    onClick={handleActive}
                                    to="/calendarHistory">{strings.calendarHistory}</Link></MenuItem>
                            }
                        </Menu>
                    </ProSidebar>
                </div>
            </div>
        </>
    );
}
export default Sidebar;
