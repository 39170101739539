import React, { useState } from 'react';
import HomeNotificationComponent from "../../components/HomeNotificationComponent";
import {strings} from "../../localization/Localization";
import "./Home.css"
import Draggable from 'react-draggable';

const Home = () => {
  const [currentPosition, setCurrentPosition] = useState({
    xRate: 150,
    yRate: 150
  });

  const onDrag = (e, data) => {
    setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  };
    return (
        <>
            <div className={"d-flex align-items-end justify-content-center pb-4 mt-4"}>
                <div className={"header_wrapper justify-content-center d-flex"}>
                    <div className={" d-flex me-2"}></div>
                    <div className={"pt-1 d-flex align-items-center ms-2 me-3"}>
                        <h2 className={"home_header"}>{strings.welcomeToSystemTitle}</h2>
                    </div>
                </div>
            </div>
            <div className={"h-100 mt-3"}>
                <div>
                    <HomeNotificationComponent/>
                </div>
            </div>
        </>
    );
}
export default Home;