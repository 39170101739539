import axios from "../../axios/axios";

const AbsencesService = {

    getAbsenceCountForMonth: (date) => {
        return axios.post("/user/getAbsenceCount", {
            date
        })
    },
    getAllAbsence: (date) => {
        return axios.post("/user/getAllByAbsence", {
            date,
        })
    },
    getAbsenceByVacation: (date) => {
        return axios.post("/user/getAllAbsencesByDate", {
            date
        })
    },
    getAbsenceByHolidayType: (date, holidayType) => {
        return axios.post("/user/getAllAbsencesByHolidayType", {
            date,
            holidayType
        })
    },
    getHistoryAbsenceByVacationForLogUser: () => {
        return axios.get("/userabsence/getAbsencesForLoggedUserWithYear")
    },
    getHistoryAbsenceByReasonsForLogUser: () => {
        return axios.get("/userabsence/getAbsencesForLoggedUserWithoutYear")
    },
    getHistoryAbsenceByHolidayForLogUser: () => {
        return axios.post("/user/getAllAbsencesByHolidayType", {

        })
    },
    getAllAbsenceVacationByUserId:(id)=>{
        return axios.get(`/userabsence/getAbsencesForUserWithYear/${id}`)

    },
    getAllAbsenceReasonsByUserId:(id)=>{
        return axios.get(`/userabsence/getAbsencesForUserWithoutYear/${id}`)
    },
    getDeputies:()=>{
        return axios.get("/user/getAllUserDeputies")
    },
    getRemainingAndAllowedDaysForUserAndReasons: (id, date, userId) => {
        return axios.get(`/userabsence/getAllowedAndUsedDays/${id}`, {
            params: {
                date: date,
                userId: userId
            }
        });
    }
}
export default AbsencesService;