import React, {useState} from "react"
import {Link, useHistory} from "react-router-dom";
import {Buffer} from "buffer";
import AuthenticationRepository from "../../repository/authentication/AuthenticationRepository";
import {AUTH_TOKEN} from "../../shared/utility";
import swal from "sweetalert";
import {strings} from "../../localization/Localization";
import LogoElemMK from "../../assets/images/ESM_MK.png";
import LogoElemEN from "../../assets/images/ESM_EN.png";
import "./LoginMobilePage.css"
import ApplicationService from "../../ApplicationService";

const LoginMobilePage = () => {
    const [email, setEmail] = useState();

    const [password, setPassword] = useState();

    const history = useHistory();

    const handleLoginRequest = (e) => {
        e.preventDefault();
        let data2 = email + ":" + password;
        const request = Buffer.from(data2).toString("base64");
        AuthenticationRepository.loginUser(request).then((data) => {
            localStorage.setItem(AUTH_TOKEN, data.data);
            history.push("/home");
        }).catch((err) => {
            wrongCredentialsAlert();
        });
    };

    const wrongCredentialsAlert = () => {
        swal(strings.wrongCredentials, {
            icon: "error",
        });
    };

    const setLanguageToMacedonian = () => {
        strings.setLanguage('mk');
        localStorage.setItem("activeLanguage", "mk")
        window.location.reload();
    };

    const setLanguageToEnglish = () => {
        strings.setLanguage('en');
        localStorage.setItem("activeLanguage", "en")
        window.location.reload();
    };

    return (
        <div className="login-back-container-mobile d-flex justify-content-center scrollWrapper h-100 w-100">
            <div className="login-div-center-mobile mt-5">
                <div className="content">

                    <div className="d-flex justify-content-center bg-white border-radius px-3">
                        <img src={ApplicationService.getActiveLanguage() == "mk" ? LogoElemMK : LogoElemEN}
                             className={"logo"}/>
                    </div>
                    <hr/>
                    <div className={"text-end"}>
                        <button onClick={setLanguageToMacedonian}
                                className="btn button1 btn-sm language-btn text-white m-sm-1 language-button">MK
                        </button>
                        <button onClick={setLanguageToEnglish}
                                className="btn btn-sm language-btn text-white mx-2 language-button">EN
                        </button>
                    </div>
                    <form onSubmit={handleLoginRequest} className="login-form">
                        <div className="form-group mb-3">
                            <label htmlFor={"exampleInputEmail1"} className={"my-2"}>{strings.email}</label>
                            <input type="email" required={true} className="form-control" id="exampleInputEmail1"
                                   placeholder={strings.email} value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor={"exampleInputPassword1"} className={"my-2"}>{strings.password}</label>
                            <input type="password" required={true} value={password}
                                   onChange={(e) => setPassword(e.target.value)} className="form-control"
                                   id="exampleInputPassword1"
                                   placeholder={strings.password}/>
                        </div>
                        <span id={"error_text"} className={"text-danger d-inline-block"}></span>
                        <button type="submit"
                                className="btn border-radius signIn-button-mobile text-white w-100 my-4">{strings.signIn}</button>
                        <br/>
                        <div className={"forget-password-label d-flex justify-content-between"}>
                            <div>
                                <Link to={"/forgotPassword"}
                                      className="btn btn-link mr-auto">{strings.forgotPassword}</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default LoginMobilePage;