import axios from "../../axios/axios";

const EmployeeService = {
    getHolidays: (id) => {
        return axios.get(`/holiday/getHolidaysForUser/${id}`);
    },
    getLeaveRequest: (id) => {
        return axios.get(`/leaverequest/getAllLeaveRequestsForUser/${id}`);
    },

}
export default EmployeeService;