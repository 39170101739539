import axios from "axios"
import ApplicationService from "../../ApplicationService";

const AuthenticationRepository = {
    loginUser: (request) => {
        return axios.post(process.env.REACT_APP_HOST_ENV + '/login', request, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + request,
                "Accept-Language":ApplicationService.getActiveLanguage()
            }
        })
    },
    forgotPassword: (email) => {
        return axios.post(process.env.REACT_APP_HOST_ENV + '/user/forgotPassword', {}, {
            headers: {
                'Content-Type': 'application/json',
                'email': email,
                "Accept-Language":ApplicationService.getActiveLanguage()
            }
        })
    }
}

export default AuthenticationRepository;