import React from "react";
import "./Spinner.css"

const SpinnerComponent = (props) => {
    return(
        <div hidden={props.spinnerShow}>
            <div id={"spinner-container"}>
            </div>
            <div>
                <div animation="border" role=""
                         style={{position:"absolute", opacity:"100%", left:"50%", top:"43%",width: "4rem", height: "4rem"}}
                        className={"loader"}
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}
export default  SpinnerComponent;