import ReactPaginate from "react-paginate";
import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import BootstrapTable from 'react-bootstrap-table-next';
import DeliveredRequestModal from "./DeliveredRequestModal/DeliveredRequestModal"
import LeaveRequestRepository from "../../../repository/leaveRequestRepository/LeaveRequestRepository";
import '../MyLeaveRequest/LeaveRequestList/LeaveRequest.css';
import { useHistory, useLocation } from 'react-router-dom';
import {
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineExpand,
    AiOutlineExpandAlt,
    AiOutlineShrink
} from "react-icons/ai";
import {strings} from "../../../localization/Localization";
import swal from "sweetalert";
import {FaSortDown, FaSortUp} from "react-icons/fa"
import AddButton from '../../../components/addButton/AddButton';
import LeaveRequestModalHR from '../MyLeaveRequest/LeaveRequestModal/LeaveRequestModalHR';
import ApplicationService from '../../../ApplicationService';

const DeliveredRequestList = (props) => {

    const [showModal, setShowModal] = useState(false)

    const [allLeaves, setAllLeaves] = useState([]);

    const [newLeaves, setNewLeaves] = useState([]);

    const [otherLeaves, setOtherLeaves] = useState([]);

    const [pageCountForNewRequest, setPageCountForNewRequest] = useState();

    const [pageCountForOtherRequest, setPageCountForOtherRequest] = useState();

    const [pageForNewRequest, setPageForNewRequest] = useState(0);

    const [sizeForNewRequest, setSizeForNewRequest] = useState(5);

    const [pageForOtherRequest, setPageForOtherRequest] = useState(0);

    const [sizeForOtherRequest, setSizeForOtherRequest] = useState(5);

    const [showRequestModal, setShowRequestModal] = useState(false);

    const [selectedLeaveRequestForEdit, setSelectedLeaveRequestForEdit] = useState({});

    const [sortByNew,setSortByNew]=useState("");

    const [sortByOther,setSortByOther]=useState("");

    const [sortTypeNew,setSortTypeNew]=useState("");

    const [sortTypeOther,setSortTypeOther]=useState("");

    const history = useHistory();

    const location = useLocation()

    useEffect(() => {
        if (location.state) {
            getLeaveRequestById(location.state.LeaveRequest);
        }
    }, [location.state])

    useEffect(() => {
        if (sortByNew !== "" && sortTypeNew !== ""){
            getAllNewLeaveRequest(sortByNew,sortTypeNew);
        }else{
            getAllNewLeaveRequest();
        }

    }, [pageForNewRequest]);

    useEffect(() => {
        if (sortByOther !== "" && sortTypeOther !== ""){
            getAllOtherLeaveRequest(sortByOther,sortTypeOther);
        }else{
            getAllOtherLeaveRequest();
        }

    }, [pageForOtherRequest]);


    const handleShow = () => {
        getAllOtherLeaveRequest();
        getAllNewLeaveRequest();
        setShowModal(true);
    };

    const handleShowRequestModal = () => {
        setShowRequestModal(true);
    }

    const handleClose = () => {
        setSelectedLeaveRequestForEdit({});
        setShowModal(false);
        setShowRequestModal(false);
    }

    const getAllLeaveRequests = () => {
        getAllNewLeaveRequest();
        getAllOtherLeaveRequest();
    }

    const getLeaveRequestById = (id) => {
        LeaveRequestRepository.getLeaveRequestById(id)
            .then((data) => {
                setSelectedLeaveRequestForEdit(
                    data.data
                )
            })
        handleShow();
    }

    const getAllNewLeaveRequest = (atribute=null,sortType = null) => {
        LeaveRequestRepository.getAllNewLeaveRequestsPaginated(pageForNewRequest, sizeForNewRequest,atribute,sortType).then((data) => {
            if (data.data.content.length === 0 && pageForNewRequest !== 0) {
                setPageForNewRequest(pageForNewRequest - 1);
            } else {
                setPageCountForNewRequest(data.data.totalPages)
                setNewLeaves(data.data.content);
            }
        })
    };

    const getAllOtherLeaveRequest = (atribute=null,sortType = null) => {
        LeaveRequestRepository.getAllOtherLeaveRequestsPaginated(pageForOtherRequest, sizeForOtherRequest,atribute,sortType).then((data) => {
            setPageCountForOtherRequest(data.data.totalPages)
            var otherRequest = data.data.content.filter(r => r.leaveRequestStatus.name !== "NEW");
            setOtherLeaves(otherRequest);
        })
    };

    const handlePageClickForNewRequest = (data) => {
        let selected = data.selected;
        setPageForNewRequest(selected);
    };

    const handlePageClickForOtherRequest = (data) => {
        let selected = data.selected;
        setPageForOtherRequest(selected);
    };

    const formatDate = (date) => {
        if (date == null) {
            return '';
        }
        var d = new Date(date);
        var dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear()
        return dateString;
    };

    const deleteConfirmAlert = (id) => {
        swal({
            title: "Are you sure?",
            text: "Do you really want to delete " + ". \n  This leave request cannot be restored.",
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
        }).then((willSuccess) => {
            if (willSuccess) {
                deleteLeaveRequest(id);
            } else {
            }
        });
    };

    const deleteLeaveRequest = (id) => {
        LeaveRequestRepository.deleteLeaveRequest(id)
            .then((data) => {
                swal("Leave Request is deleted!", {
                    icon: "success",
                });
                getAllNewLeaveRequest();
                getAllOtherLeaveRequest();
            });
    }

    const handleChangeSortArrowNew = (attribute) => {
            setSortByNew(attribute);
        if(attribute===sortByNew && sortTypeNew !== "asc"){
            setSortTypeNew("asc")
            getAllNewLeaveRequest(attribute,"asc")
        }else{
            setSortTypeNew("desc")
            getAllNewLeaveRequest(attribute,"desc")
        }

    };

    const onClickHandle = () => {
        handleShowRequestModal();
    }

    const handleChangeSortArrowOther = (attribute) => {
            setSortByOther(attribute);
        if(attribute===sortByOther && sortTypeOther !=="asc"){
            setSortTypeOther("asc")
            getAllOtherLeaveRequest(attribute,"asc")
        }else{
            setSortTypeOther("desc")
            getAllOtherLeaveRequest(attribute,"desc")
        }

    };

    const columnsNewRequest = [
        {
            dataField: 'user.firstName',
            text: strings.name,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("firstName") }><span className={"me-2"}> {strings.name }</span>{sortByNew ==="firstName" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-2"}/> : <FaSortDown className={"text-muted " }/> } </div>
            }

        },
        {
            dataField: 'user.person.parentName',
            text: strings.parentName,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("parentName") }><span className={"me-2"}> {strings.parentName }</span>{sortByNew ==="parentName" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            }

        },
        {
            dataField: 'user.lastName',
            text: strings.lastName,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("lastName") }><span className={"me-2"}> {strings.lastName }</span>{sortByNew ==="lastName" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            }

        },
        {
            dataField: 'dateFrom',
            text: strings.dateFrom,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("dateFrom") }><span className={"me-2"}> {strings.dateFrom }</span>{sortByNew ==="dateFrom" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
            formatter: (cell, row) => {
                return <span>{formatDate(cell)}</span>
            }
        },
        {
            dataField: 'dateTo',
            text: strings.dateTo ,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("dateTo") }><span className={"me-2"}> {strings.dateTo }</span>{sortByNew ==="dateTo" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
            formatter: (cell, row) => {
                return <span>{formatDate(cell)}</span>
            }
        },
        {
            dataField: 'dateCreated',
            text: strings.dateCreated,
            sort: false,
            formatter: (cell, row) => {
                return <span>{formatDate(cell)} </span>
            },
            headerFormatter: (cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                            onClick={() => handleChangeSortArrowNew("dateCreated")}><span
                    className={"me-2"}> {strings.dateCreated}</span>{sortByNew === "dateCreated" && sortTypeNew==="asc" ?
                    <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                </div>
            }
        },
        {
            dataField: 'correctedWorkDays',
            text: strings.workingDays,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("correctedWorkDays") }><span className={"me-2"}> {strings.workingDays }</span>{sortByNew ==="correctedWorkDays" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted  mt-auto mb-2" }/> } </div>
            },
        },
        {
            dataField: 'totalDays',
            text: strings.totalDays,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("totalDays") }><span className={"me-2"}> {strings.totalDays }</span>{sortByNew ==="totalDays" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
        },
        {
            dataField: 'leaveReason.labelMk',
            text: strings.reasonsForLeaveRequest,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("leaveReason") }><span className={"me-2"}> {strings.reasonsForLeaveRequest }</span>{sortByNew ==="leaveReason" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-auto "}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
        },
        {
            dataField: 'leaveRequestStatus.label_mk',
            text: strings.leaveRequestStatus,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowNew("status") }><span className={"me-2"}> {strings.leaveRequestStatus }</span>{sortByNew ==="status" && sortTypeNew==="asc" ? <FaSortUp className={"text-muted mt-2"}/> : <FaSortDown className={"text-muted " }/> } </div>
            },
            formatter: (cell, row) => {
                if (row.leaveRequestStatus?.name != null) {
                    if (row.leaveRequestStatus.name == "NEW" && row.terminationOrCorrection == 'TERMINATED') {
                        return <span className={"new_notification"}>{strings.termination_request}</span>
                    } else if (row.leaveRequestStatus.name == "NEW" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"new_notification"}>{strings.correction_request}</span>
                    } else if (row.leaveRequestStatus.name == "NEW") {
                        return <span className={"new_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "DECLINED") {
                        return <span className={"declined_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "APPROVED" && row.childStatus == 'DECLINED' && row.terminationOrCorrection == 'TERMINATED') {
                        return <span className={"approved_notification"}>{strings.approved_after_declined_termination}</span>
                    } else if (row.leaveRequestStatus.name == "APPROVED" && row.childStatus == 'DECLINED' && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
                    } else if (row.leaveRequestStatus.name == "APPROVED" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"approved_notification"}>{strings.approved_correction}</span>
                    } else if (row.leaveRequestStatus.name == "BETWEEN APPROVALS" && row.terminationOrCorrection == 'TERMINATED') {
                        return <span className={"new_notification"}>{strings.termination_request}</span>
                    } else if (row.leaveRequestStatus.name == "BETWEEN APPROVALS" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"new_notification"}>{strings.correction_request}</span>
                    }
                    else if (row.leaveRequestStatus.name == "APPROVED") {
                        if (row.parentStatus == "TERMINATED") {
                            return <span className={"text-danger"}>{strings.terminated}</span>
                        } else {
                            return <span className={"approved_notification"}>{cell}</span>
                        }
                    } else if (row.leaveRequestStatus.name == "RETURNED FOR CORRECTION") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "CORRECTED") {
                        return <span className={"corrected_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "BETWEEN APPROVALS") {
                        return <span className={"between_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "REROUTED") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "REROUTED_HR") {
                        return <span className={"rerouted_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "RETURNED_FOR_CORRECTION_HR") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "CORRECTED_HR") {
                        return <span className={"corrected_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "CORRECTED_SECOND_APPROVAL") {
                        return <span className={"corrected_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "RETURNED_FOR_CORRECTION_SECOND_APPROVAL") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "DECLINED_HR") {
                        return <span className={"declined_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "DELETED") {
                        return <span className={"text-danger"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "STOPPED") {
                        return <span className={"text-danger"}>{cell}</span>
                    } else if(row.leaveRequestStatus.name == "EXPIRED"){
                        return <span className={"text-danger"}>{cell}</span>
                    }
                }
            }
        },
        {
            formatter: (cell, row) => {
                // if (row.leaveRequestStatus.name === "RETURNED FOR CORRECTION" || row.leaveRequestStatus.name === "DECLINED") {
                return <div className={'icon-div mx-3'}>
                    <a
                        onClick={() => {
                            setSelectedLeaveRequestForEdit(row);
                            handleShow();
                        }}
                        title={'Edit'}
                        className={'text-dark icon'}>
                        <i className={"edit-icon-circle material-icons "}>
                            create
                        </i>
                    </a>
                </div>
                // }
            }
        }
    ];

    const columnsOtherRequest = [
        {
            dataField: 'user.firstName',
            text: strings.name,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("firstName") }><span className={"me-2"}> {strings.name }</span>{sortByOther ==="firstName" && sortTypeOther==="asc" ? <FaSortUp className={"text-muted mt-2"}/> : <FaSortDown className={"text-muted " }/> } </div>
            }

        },
        {
            dataField: 'user.person.parentName',
            text: strings.parentName,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("parentName") }><span className={"me-2"}> {strings.parentName }</span>{sortByOther ==="parentName" && sortTypeOther==="asc"   ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            }

        },
        {
            dataField: 'user.lastName',
            text: strings.lastName,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("lastName") }><span className={"me-2"}> {strings.lastName }</span>{sortByOther ==="lastName" && sortTypeOther==="asc"  ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            }

        },
        {
            dataField: 'dateFrom',
            text: strings.dateFrom,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("dateFrom") }><span className={"me-2"}> {strings.dateFrom }</span>{sortByOther ==="dateFrom"  && sortTypeOther==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
            formatter: (cell, row) => {
                return <span>{formatDate(cell)}</span>
            }
        },
        {
            dataField: 'dateTo',
            text: strings.dateTo ,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("dateTo") }><span className={"me-2"}> {strings.dateTo }</span>{sortByOther ==="dateTo"  && sortTypeOther==="asc"  ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
            formatter: (cell, row) => {
                return <span>{formatDate(cell)}</span>
            }
        },
        {
            dataField: 'dateCreated',
            text: strings.dateCreated,
            sort: false,
            formatter: (cell, row) => {
                return <span>{formatDate(cell)} </span>
            },
            headerFormatter: (cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                            onClick={() => handleChangeSortArrowOther("dateCreated")}><span
                    className={"me-2"}> {strings.dateCreated}</span>{sortByOther === "dateCreated" && sortTypeOther==="asc" ?
                    <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                </div>
            }
        },
        {
            dataField: 'correctedWorkDays',
            text: strings.workingDays,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("correctedWorkDays") }><span className={"me-2"}> {strings.workingDays }</span>{sortByOther ==="correctedWorkDays" && sortTypeOther==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted  mt-auto mb-2" }/> } </div>
            },
        },
        {
            dataField: 'totalDays',
            text: strings.totalDays,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("totalDays") }><span className={"me-2"}> {strings.totalDays }</span>{sortByOther ==="totalDays"  && sortTypeOther==="asc" ? <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
        },
        {
            dataField: 'leaveReason.labelMk',
            text: strings.reasonsForLeaveRequest,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("leaveReason") }><span className={"me-2"}> {strings.reasonsForLeaveRequest }</span>{sortByOther ==="leaveReason"  && sortTypeOther==="asc" ? <FaSortUp className={"text-muted mt-auto "}/> : <FaSortDown className={"text-muted mt-auto mb-2" }/> } </div>
            },
        },
        {
            dataField: 'leaveRequestStatus.label_mk',
            text: strings.leaveRequestStatus,
            sort: false,
            headerFormatter:(cell, row) => {
                return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"} onClick={()=>handleChangeSortArrowOther("status") }><span className={"me-2"}> {strings.leaveRequestStatus }</span>{sortByOther ==="status" && sortTypeOther==="asc"  ? <FaSortUp className={"text-muted mt-2"}/> : <FaSortDown className={"text-muted " }/> } </div>
            },
            formatter: (cell, row) => {
                if (row.leaveRequestStatus?.name != null) {
                    if (row.leaveRequestStatus.name == "NEW" && row.terminationOrCorrection == 'TERMINATED') {
                        return <span className={"new_notification"}>{strings.termination_request}</span>
                    } else if (row.leaveRequestStatus.name == "NEW" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"new_notification"}>{strings.correction_request}</span>
                    } else if (row.leaveRequestStatus.name == "NEW") {
                        return <span className={"new_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "DECLINED") {
                        return <span className={"declined_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "APPROVED" && row.childStatus == 'DECLINED' && row.terminationOrCorrection == 'TERMINATED') {
                        return <span className={"approved_notification"}>{strings.approved_after_declined_termination}</span>
                    } else if (row.leaveRequestStatus.name == "APPROVED" && row.childStatus == 'DECLINED' && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
                    } else if (row.leaveRequestStatus.name == "APPROVED" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                        return <span className={"approved_notification"}>{strings.approved_correction}</span>
                    } else if (row.leaveRequestStatus.name == "BETWEEN_APPROVALS" && row.terminationOrCorrection == 'TERMINATED') {
                        return <span className={"approved_notification"}>{strings.termination_request}</span>
                    }
                    else if (row.leaveRequestStatus.name == "APPROVED") {
                        if (row.parentStatus == "TERMINATED") {
                            return <span className={"text-danger"}>{strings.terminated}</span>
                        } else {
                            return <span className={"approved_notification"}>{cell}</span>
                        }
                    } else if (row.leaveRequestStatus.name == "RETURNED FOR CORRECTION") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "CORRECTED") {
                        return <span className={"corrected_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "BETWEEN APPROVALS") {
                        return <span className={"between_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "REROUTED") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "REROUTED_HR") {
                        return <span className={"rerouted_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "RETURNED_FOR_CORRECTION_HR") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "CORRECTED_HR") {
                        return <span className={"corrected_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "CORRECTED_SECOND_APPROVAL") {
                        return <span className={"corrected_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "RETURNED_FOR_CORRECTION_SECOND_APPROVAL") {
                        return <span className={"returned_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "DECLINED_HR") {
                        return <span className={"declined_notification"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "DELETED") {
                        return <span className={"text-danger"}>{cell}</span>
                    } else if (row.leaveRequestStatus.name == "STOPPED") {
                        return <span className={"text-danger"}>{cell}</span>
                    }else if(row.leaveRequestStatus.name == "EXPIRED"){
                        return <span className={"text-danger"}>{cell}</span>
                    } else if(row.leaveRequestStatus.name == "TERMINATED"){
                        return <span className={"text-danger"}>{cell}</span>
                    } else if(row.leaveRequestStatus.name == "CANCELLED_FOR_CORRECTION"){
                        return <span className={"text-returned_notification"}>{cell}</span>
                    }

                }
            }
        },
        {
            formatter: (cell, row) => {
                // if (row.leaveRequestStatus.name === "RETURNED FOR CORRECTION" || row.leaveRequestStatus.name === "DECLINED") {
                return <div className={'icon-div mx-3'}>
                    <a
                        onClick={() => {
                            setSelectedLeaveRequestForEdit(row);
                            handleShow();
                        }}
                        title={'Edit'}
                        className={'text-dark icon'}>
                        <i className={"edit-icon-circle material-icons "}>
                            create
                        </i>
                    </a>
                </div>
                // }
            }
        }
    ];

    const expandRow = {
        className: 'expanding-foo',
        onlyOneExpanding: true,
        renderer: row => (
            <div className={"d-flex"}>
                <div className={"block-description w-75"}>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.leaveType}: </b> {row.leaveType.label_mk}</p>
                    </div>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.reasonsForLeaveRequest}: </b> {row.leaveReason.labelMk}</p>
                    </div>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.description}: </b>{row.description}</p>
                    </div>
                </div>
                <div className={"w-25 d-flex mx-5 text-end justify-content-end align-items-center"}>
                </div>

            </div>
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({isAnyExpands}) => {
            if (isAnyExpands) {
                return <b><AiOutlineShrink/></b>;
            }
            return <b><AiOutlineExpand/></b>;
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (
                    <b><AiOutlineShrink/></b>
                );
            }
            return (
                <b><AiOutlineExpandAlt/></b>
            );
        }
    };

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    function rowStyleFormat(row, rowIdx) {
        return {backgroundColor: rowIdx % 2 === 0 ? 'red' : 'blue'};
    }

    return (
        <>
            <div className={"container pb-5"}>
                <div className={"container"}>
                    <h2>{strings.requestsAwaitingResponse}</h2>
                    {
                        allLeaves &&
                        <BootstrapTable
                            keyField='id'
                            data={newLeaves}
                            columns={columnsNewRequest}
                            expandRow={expandRow}
                            bordered={true}
                            defaultSorted={defaultSorted}
                            hover={true}
                            trStyle={rowStyleFormat}
                            headerClasses={"headerStyle"}
                            wrapperClasses={"text-center "}
                            bodyClasses={"bodyStyle"}
                        />
                    }
                    {
                        newLeaves.length === 0 &&
                        <div className={"justify-content-center text-center w-100"}>
                            <div>
                                <h5 className={"text-secondary w-100 "}>
                                    <i>{strings.emptyList}</i></h5>
                            </div>
                        </div>
                    }{
                    newLeaves.length !== 0 &&

                    <ReactPaginate
                        previousLabel={<AiOutlineArrowLeft/>}
                        nextLabel={<AiOutlineArrowRight/>}
                        breakLabel="..."
                        pageCount={pageCountForNewRequest}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={pageForNewRequest}
                        onPageChange={handlePageClickForNewRequest}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination m-0 justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                }
                </div>
            </div>
            <DeliveredRequestModal showModal={showModal}
                                   handleClose={handleClose}
                                   getAllLeaveRequests={getAllLeaveRequests}
                                   getAllOtherLeaveRequest={getAllOtherLeaveRequest}
                                   getAllNewLeaveRequest={getAllNewLeaveRequest}
                                   selectedLeaveRequestForEdit={selectedLeaveRequestForEdit}
            />
            <LeaveRequestModalHR showModal={showRequestModal}
                                handleClose={handleClose}
                                getAllLeaveRequests={getAllLeaveRequests}
                                selectedLeaveRequestForEdit={selectedLeaveRequestForEdit}
            />
            <hr className={"mt-5"}/>
            <div className={"container my-5 pb-3"}>
                <div className={"container"}>
                    <div className={"d-flex ms-2 mb-1 justify-content-between align-items-center"}>
                        <div>
                                <h2>{strings.otherRequests}</h2>
                        </div>
                        <div className={"align-items-center d-flex "}>
                            <div>
                                {ApplicationService.hasRole(["ROLE_SUPER_ADMIN","ROLE_ADMIN","ROLE_HUMAN_RESOURCES"]) &&
                                  <AddButton onClickHandle={onClickHandle}></AddButton>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        otherLeaves &&
                        <BootstrapTable
                            keyField='id'
                            data={otherLeaves}
                            columns={columnsOtherRequest}
                            expandRow={expandRow}
                            bordered={true}
                            defaultSorted={defaultSorted}
                            hover={true}
                            trStyle={rowStyleFormat}
                            headerClasses={"headerStyle"}
                            wrapperClasses={"text-center "}
                            bodyClasses={"bodyStyle"}
                        />
                    }
                    {otherLeaves.length === 0 &&
                        <div className={"justify-content-center text-center w-100"}>
                            <div>
                                <h5 className={"text-secondary w-100 "}>
                                    <i>{strings.emptyList}</i></h5>
                            </div>
                        </div>

                    }
                    {otherLeaves.length !== 0 &&
                        <ReactPaginate
                            previousLabel={<AiOutlineArrowLeft/>}
                            nextLabel={<AiOutlineArrowRight/>}
                            breakLabel="..."
                            pageCount={pageCountForOtherRequest}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={pageForOtherRequest}
                            onPageChange={handlePageClickForOtherRequest}
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination m-0 justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />
                    }
                </div>
            </div>
        </>
    )
}
export default DeliveredRequestList;