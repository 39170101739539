import ReactPaginate from "react-paginate";
import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import BootstrapTable from 'react-bootstrap-table-next';
import LeaveRequestsModal from "../LeaveRequestModal/LeaveRequestsModal";
import LeaveRequestRepository from "../../../../repository/leaveRequestRepository/LeaveRequestRepository";
import "./LeaveRequest.css"
import "../../../../components/HomeDesign.css";
import {Link, useLocation} from "react-router-dom";
import {
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineExpand,
    AiOutlineExpandAlt,
    AiOutlineShrink
} from "react-icons/ai";
import {strings} from "../../../../localization/Localization";
import swal from "sweetalert";
import AddButton from "../../../../components/addButton/AddButton";
import Select from "react-select";
import {FaSortDown, FaSortUp} from "react-icons/fa";

const LeaveRequestsList = (props) => {

    const [showModal, setShowModal] = useState(false)

    const [leaves, setLeaves] = useState([]);

    const [pageCount, setPageCount] = useState();

    const [page, setPage] = useState(0);

    const [size, setSize] = useState(10);

    const [offset, setOffset] = useState(0);

    const [nextPageOffset, setNextPageOffset] = useState(0);

    const [selectedLeaveRequestForEdit, setSelectedLeaveRequestForEdit] = useState({});

    const location = useLocation();

    const [sortBy, setSortBy] = useState("");

    const [sortType,setSortType]=useState("");

    useEffect(() => {
        if (location.state) {
            getLeaveRequestById(location.state.LeaveRequest);
        }
    }, [location.state])

    useEffect(() => {
        if (sortBy !== "" && sortType !== ""){
            getAllLeaveRequests(sortBy,sortType);
        }else{
            getAllLeaveRequests();
        }

    }, [page]);

    useEffect(() => {
        getAllLeaveRequests();
    }, [size]);

    const handleShow = () => {
        getAllLeaveRequests();
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedLeaveRequestForEdit({});
        setShowModal(false);
    }

    const getLeaveRequestById = (id) => {
        LeaveRequestRepository.getLeaveRequestById(id)
            .then((data) => {
                if (data.data.leaveRequestStatus.name !== "APPROVED" && data.data.leaveRequestStatus.name !== "REROUTED" && data.data.leaveRequestStatus.name !== "REROUTED_HR" && data.data.leaveRequestStatus.name !== "DECLINED" && data.data.leaveRequestStatus.name !== "TERMINATED") {
                    setSelectedLeaveRequestForEdit(
                        data.data
                    )
                    handleShow();
                }
            })
    }

    const getAllLeaveRequests = (sortBy = null, sortType = null) => {
        LeaveRequestRepository.getAllLeaveRequestsForLoggedUser(page, size,sortBy,sortType).then((data) => {
            setPageCount(data.data.totalPages)
            setOffset(data.data.pageable.offset);
            setNextPageOffset(data.data.pageable.offset + size)
            setLeaves(data.data.content)
        })
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        setPage(selected);
    };

    const formatDate = (date) => {
        if (date == null) {
            return '';
        }
        var d = new Date(date);
        var dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear()
        return dateString;
    }

    const deleteConfirmAlert = (id) => {
        swal({
            title: "Are you sure?",
            text: "Do you really want to delete " + ". \n  This leave request cannot be restored.",
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
        }).then((willSuccess) => {
            if (willSuccess) {
                deleteLeaveRequest(id);
            } else {
            }
        });
    };

    const deleteLeaveRequest = (id) => {
        LeaveRequestRepository.deleteLeaveRequest(id)
            .then((data) => {
                swal("Leave Request is deleted!", {
                    icon: "success",
                });
                getAllLeaveRequests();
            });
    }

    const handleChangeSortArrow = (attribute) => {
        setSortBy(attribute);
        if(attribute===sortBy && sortType !== "asc"){
            setSortType("asc")
            getAllLeaveRequests(attribute,"asc")
        }else{
            setSortType("desc")
            getAllLeaveRequests(attribute,"desc")
        }
    };

    const columns = [
            {
                dataField: 'dateFrom',
                text: strings.dateFrom,
                sort: false,
                formatter: (cell, row) => {
                    return <span>{formatDate(cell)}</span>
                },
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("dateFrom")}><span
                        className={"me-2"}> {strings.dateFrom}</span>{sortBy === "dateFrom" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                }
            },
            {
                dataField: 'dateTo',
                text: strings.dateTo,
                sort: false,
                formatter: (cell, row) => {
                    return <span>{formatDate(cell)} </span>
                },
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("dateTo")}><span
                        className={"me-2"}> {strings.dateTo}</span>{sortBy === "dateTo" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                }
            },
            {
                dataField: 'dateCreated',
                text: strings.dateCreated,
                sort: false,
                formatter: (cell, row) => {
                    return <span>{formatDate(cell)} </span>
                },
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("dateCreated")}><span
                        className={"me-2"}> {strings.dateCreated}</span>{sortBy === "dateCreated" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                }
            },
            {
                dataField: 'correctedWorkDays',
                text: strings.correctedWorkDays,
                sort: false,
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("correctedWorkDays")}><span
                        className={"me-2"}> {strings.correctedWorkDays}</span>{sortBy === "correctedWorkDays" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                }
            },
            {
                dataField: 'totalDays',
                text: strings.totalDays,
                sort: false,
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("totalDays")}><span
                        className={"me-2"}> {strings.totalDays}</span>{sortBy === "totalDays" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                }

            },
            {
                dataField: 'leaveReason.labelMk',
                text: strings.reasonsForLeaveRequest,
                sort: false,
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-end w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("reasonsForLeaveRequest")}><span
                        className={"me-2"}> {strings.reasonsForLeaveRequest}</span>{sortBy === "reasonsForLeaveRequest" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                }
            },
            {
                dataField: 'leaveRequestStatus.label_mk',
                text: strings.leaveRequestStatus,
                sort: false,
                headerFormatter: (cell, row) => {
                    return <div className={"d-flex justify-content-center w-100 align-items-start cursor-pointer"}
                                onClick={() => handleChangeSortArrow("leaveRequestStatus")}><span
                        className={"ms-3 me-2"}> {strings.leaveRequestStatus}</span>{sortBy === "leaveRequestStatus" ?
                        <FaSortUp className={"text-muted mt-auto"}/> : <FaSortDown className={"text-muted mt-auto mb-2"}/>}
                    </div>
                },
                formatter: (cell, row) => {
                    if (row.leaveRequestStatus?.name != null) {
                        if (row.leaveRequestStatus.name == "NEW" && row.terminationOrCorrection == 'TERMINATED') {
                            return <span className={"new_notification"}>{strings.termination_request}</span>
                        } else if (row.leaveRequestStatus.name == "NEW" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                            return <span className={"new_notification"}>{strings.correction_request}</span>
                        } else if (row.leaveRequestStatus.name == "NEW") {
                            return <span className={"new_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "DECLINED") {
                            return <span className={"declined_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "APPROVED" && row.childStatus == 'DECLINED' && row.terminationOrCorrection == 'TERMINATED') {
                            return <span className={"approved_notification"}>{strings.approved_after_declined_termination}</span>
                        } else if (row.leaveRequestStatus.name == "APPROVED" && row.childStatus == 'DECLINED' && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                            return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
                        } else if (row.leaveRequestStatus.name == "APPROVED" && row.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
                            return <span className={"approved_notification"}>{strings.approved_correction}</span>
                        }
                        else if (row.leaveRequestStatus.name == "APPROVED") {
                            if (row.parentStatus == "TERMINATED") {
                                return <span className={"text-danger"}>{strings.terminated}</span>
                            } else {
                                return <span className={"approved_notification"}>{cell}</span>
                            }
                        } else if (row.leaveRequestStatus.name == "RETURNED FOR CORRECTION") {
                            return <span className={"returned_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "CORRECTED") {
                            return <span className={"corrected_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "BETWEEN APPROVALS") {
                            return <span className={"between_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "REROUTED") {
                            return <span className={"rerouted_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "REROUTED_HR") {
                            return <span className={"rerouted_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "RETURNED_FOR_CORRECTION_HR") {
                            return <span className={"returned_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "CORRECTED_HR") {
                            return <span className={"corrected_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "CORRECTED_SECOND_APPROVAL") {
                            return <span className={"corrected_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "RETURNED_FOR_CORRECTION_SECOND_APPROVAL") {
                            return <span className={"returned_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "DECLINED_HR") {
                            return <span className={"declined_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "DELETED") {
                            return <span className={"text-danger"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "STOPPED") {
                            return <span className={"text-danger"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "EXPIRED") {
                            return <span className={"text-danger"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "CANCELLED_FOR_CORRECTION") {
                            return <span className={"returned_notification"}>{cell}</span>
                        } else if (row.leaveRequestStatus.name == "TERMINATED") {
                            return <span className={"text-danger"}>{cell}</span>
                        }
                    }
                }
            },
            {
                formatter: (cell, row) => {
                    // if ((row.leaveRequestStatus?.name == null) || (row.leaveRequestStatus.name === "RETURNED FOR CORRECTION" || row.leaveRequestStatus.name === "DECLINED" || row.leaveRequestStatus.name === "RETURNED_FOR_CORRECTION_HR" || row.leaveRequestStatus.name === "RETURNED_FOR_CORRECTION_SECOND_APPROVAL" || row.leaveRequestStatus.name === "DECLINED_HR")) {
                        return <div className={'icon-div mx-3'}>
                            <a
                                onClick={() => {
                                    setSelectedLeaveRequestForEdit(row);
                                    handleShow();
                                }}
                                title={'Edit'}
                                className={'text-dark icon'}>
                                <i className={"edit-icon-circle material-icons "}>
                                    create
                                </i>
                            </a>
                        </div>
                    // }
                }


                // headerFormatter: () => {
                //     return <Link onClick={handleShow} className="addButton">
                //         <RiFileAddFill/>
                //     </Link>
                // }
            },
        ]
    ;

    const expandRow = {
        className: 'expanding-foo',
        onlyOneExpanding: true,
        renderer: row => (
            <div className={"d-flex"}>
                <div className={"block-description"}>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.leaveType}: </b> {row.leaveType.label_mk}</p>
                    </div>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.reasonsForLeaveRequest}: </b> {row.leaveReason.labelMk}</p>
                    </div>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.description}: </b>{row.description}</p>
                    </div>
                </div>
                <div className={"button-block d-flex mx-5 text-ecenter justify-content-center align-items-center"}>
                    <div className={"d-inline-flex"}>
                        {/*<div className={'icon-div'}>*/}
                        {/*    <a title={'Delete'} type={'Button'}*/}
                        {/*      onClick={()=>{*/}
                        {/*          deleteConfirmAlert(row.id)*/}
                        {/*      }}*/}
                        {/*        >*/}
                        {/*        <span className={'text-danger icon'}> <RiDeleteBinLine/></span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({isAnyExpands}) => {
            if (isAnyExpands) {
                return <b><AiOutlineShrink/></b>;
            }
            return <b><AiOutlineExpand/></b>;
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (
                    <b><AiOutlineShrink/></b>
                );
            }
            return (
                <b><AiOutlineExpandAlt/></b>
            );
        }
    };

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    function rowStyleFormat(row, rowIdx) {
        return {backgroundColor: rowIdx % 2 === 0 ? 'red' : 'blue'};
    }

    const onClickHandle = () => {
        handleShow();
    }

    const selectSize = (e) => {
        setPage(0);
        setSize(e.value);
    };

    const setActiveMenuItem = () => {
        let previousMenuItem = document.getElementsByClassName("pro-menu-item active")[0]
        previousMenuItem.classList.remove("active")
        let currentMenuItem = document.getElementById("absence")
        currentMenuItem.classList.add("active")
    }

    return (
        <>
            <div className={"container pb-2 my-3"}>
                <div className={"w-100 d-flex justify-content-between"}>
                    <div>
                        <h1 className={"header_page"}>{strings.leaveRequests}</h1>
                    </div>
                </div>
                <div className={"d-flex ms-2 mb-1 justify-content-between align-items-center"}>
                    <div>
                        <Link to={"/absence"} onClick={setActiveMenuItem}>
                            <div className={"d-flex"}>
                                <div className={"align-items-center  allowed__absence__link__text "}>
                                    {strings.viewRemainingAbsence}
                                </div>
                                <i
                                    className={"material-icons align-items-end   allowed__absence__link__icon"}>arrow_right
                                </i>
                            </div>
                        </Link>
                    </div>

                    <div className={"align-items-center d-flex "}>
                        <AddButton onClickHandle={onClickHandle}></AddButton>
                    </div>
                </div>
                {
                    leaves &&
                    <div className={"table-responsive w-100"}>
                        <BootstrapTable
                            keyField='id'
                            classes={"mb-1"}
                            data={leaves}
                            columns={columns}
                            expandRow={expandRow}
                            bordered={true}
                            defaultSorted={defaultSorted}
                            hover={true}
                            trStyle={rowStyleFormat}
                            headerClasses={"headerStyle"}
                            wrapperClasses={"text-center"}
                            bodyClasses={"bodyStyle "}
                        />
                    </div>
                }
                {
                    leaves.length === 0 &&
                    <div className={"justify-content-center text-center w-100"}>
                        <div>
                            <h5 className={"text-secondary w-100 "}>
                                <i>{strings.emptyList}</i></h5>
                        </div>
                    </div>
                }
                {leaves.length !== 0 &&
                    <div className={"d-flex justify-content-between my-3"}>
                        <div className={"mx-auto h-100 align-items-center  d-flex "}>
                            <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                           nextLabel={<AiOutlineArrowRight/>}
                                           breakLabel={"..."}
                                           pageCount={pageCount}
                                           marginPagesDisplayed={2}
                                           pageRangeDisplayed={5}
                                           forcePage={page}
                                           onPageChange={handlePageClick}
                                           breakClassName={"page-item"}
                                           breakLinkClassName={"page-link"}
                                           containerClassName={"pagination h-100 m-0 justify-content-center"}
                                           pageClassName={"page-item"}
                                           pageLinkClassName={"page-link"}
                                           previousClassName={"page-item"}
                                           previousLinkClassName={"page-link"}
                                           nextClassName={"page-item"}
                                           nextLinkClassName={"page-link"}
                                           activeClassName={"active"}/>
                        </div>
                        <div className={"d-flex align-items-end"}>

                            <Select
                                placeholder={strings.size}
                                onChange={selectSize}
                                noOptionsMessage={() => strings.noOptions}
                                options={[
                                    {value: 5, label: "5"},
                                    {value: 10, label: "10"},
                                    {value: 15, label: "15"},
                                    {value: 20, label: "20"}
                                ]}
                                defaultValue={{value: 10, label: "10"}}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 40,
                                        border: "none"
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                <LeaveRequestsModal showModal={showModal}
                                    handleClose={handleClose}
                                    getAllLeaveRequests={getAllLeaveRequests}
                                    selectedLeaveRequestForEdit={selectedLeaveRequestForEdit}
                />
            </div>
        </>
    )
}
export default LeaveRequestsList;