import React, {useEffect, useState} from "react"
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./AbsenceStatus.css"
import AbsencesService from "../../repository/absencesRepository/absencesRepository";
import AbsenceStatusList from "./AbsenseStatusList/AbsenceStatusList";
import {strings} from "../../localization/Localization";

const AbsenceStatus = () => {

    const [key, setKey] = useState('vacation');

    const [vacationAbsenceData, setVacationAbsenceData] = useState([]);

    const [reasonsAbsenceData, setReasonsAbsenceData] = useState([]);

    useEffect(() => {
        getAbsenceData();
    }, [key])

    const getAbsenceData = () => {
        if (key === "vacation") {
            getAbsenceByVacation();
        } else if (key === "absenceByReasons") {
            getAbsenceByReasons();
        } else if (key === "absenceByHoliday") {
            getAbsenceByHoliday();
        }
    }

    const getAbsenceByVacation = () => {
        AbsencesService.getHistoryAbsenceByVacationForLogUser()
            .then((data) => {
                setVacationAbsenceData(data.data);
            });

    };

    const getAbsenceByReasons = () => {
        AbsencesService.getHistoryAbsenceByReasonsForLogUser()
            .then((data) => {
                setReasonsAbsenceData(data.data);
            });
    };

    const getAbsenceByHoliday = () => {
        AbsencesService.getHistoryAbsenceByHolidayForLogUser()
            .then((data) => {

            });
    };

    const getReasonsAbsenceByCurrentYear = () => {
        const currentYear = new Date().getFullYear();
        return reasonsAbsenceData.filter((r) => r.forYear === currentYear)
    }

    const getReasonsAbsenceByOtherYear = () => {
        const currentYear = new Date().getFullYear()
        return reasonsAbsenceData.filter((r) => r.forYear !== currentYear);
    }

    return (
        <>
            <div className={"container w-100  mt-3"}>
                <h1 className={"absence-status-header"}>{strings.allowedAbsenceReview}</h1>
            </div>
            <div className={"container w-100 mt-4 mx-auto"}>

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mt-3 absence-tabs tabs-buttons">

                    <Tab eventKey="vacation" title={strings.vacation}>
                        <div className={"p-4"}>
                            <AbsenceStatusList data={vacationAbsenceData} showReasons={false}/>
                        </div>
                    </Tab>

                    <Tab eventKey="absenceByReasons" title={strings.absenceByReasons}>
                        <div className={"p-4"}>
                            <h3 className={"absence-reasons-table-header"}>{strings.currentYear}</h3>
                            <AbsenceStatusList data={getReasonsAbsenceByCurrentYear()} showReasons={true}/>
                        </div>
                        <div className={"p-4"}>
                            <h3 className={"absence-reasons-table-header"}>{strings.remainingYears}</h3>
                            <AbsenceStatusList data={getReasonsAbsenceByOtherYear()} showReasons={true}/>
                        </div>
                    </Tab>

                    {/*<Tab eventKey="absenceByHoliday" title="Absence by holiday">*/}
                    {/*    <h1>tab3</h1>*/}
                    {/*</Tab>*/}

                </Tabs>

            </div>
        </>

    );
}
export default AbsenceStatus;