import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LogoElemMK from "../../assets/images/ESM_MK.png";
import LogoElemEN from "../../assets/images/ESM_EN.png";
import "./Finalized_registration.css"
import {strings} from "../../localization/Localization";
import ApplicationService from "../../ApplicationService";

const Finalized_registration = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const [success, setSuccess] = useState()

    useEffect(() => {
        let successParam = queryParams.get("status")
        if (successParam === "true") {
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    }, [])

    return (
        <div className="back">
            <div className="form_newpassword ">
                <div className="d-flex justify-content-center">
                    <img src={ApplicationService.getActiveLanguage() == "mk" ? LogoElemMK : LogoElemEN}
                         className={"logo"}/>
                </div>
                <hr/>
                {success &&
                    <div
                        className={'text-success text-center success-info-container'}>{strings.registrationSuccessful}</div>
                }
                {!success &&
                    <div className={"text-danger text-center success-info-container"}>{strings.registrationFailed}</div>
                }
                <br/>

                <div className={'justify-content-center text-center'}>
                    <Link className={'back_link  button-color-blue text-white border-radius btn text-end'} to="/login">
                        {/*<IoMdArrowBack className={'back-icon text-light'} />*/}
                        {strings.backToLogin}
                    </Link>
                </div>

            </div>
        </div>
    );
}
export default Finalized_registration;
