import React, {useEffect, useState} from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {strings} from "../../../localization/Localization";
import "../../User/User_modal/UserUpsertModal.css"
import UserListInModal from "../../../components/UserListInModal";
import AbsencesService from "../../../repository/absencesRepository/absencesRepository";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AbsenceStatusList from "../../AbsenceStatus/AbsenseStatusList/AbsenceStatusList";
import HolidayListForUser from "./HolidayListForUser/HolidayListForUser";
import evidentionRepository from "../../../repository/evidentionRepository/evidentionRepository";

const EmployeeUpsertModal = (props) => {

    const [key, setKey] = useState('vacation');

    const [vacationAbsenceData, setVacationAbsenceData] = useState([]);

    const [reasonsAbsenceData, setReasonsAbsenceData] = useState([]);

    const [holidaysForEmployee, setHolidaysForEmployee] = useState([]);

    useEffect(() => {
        if (props.showModal) {
            setKey("vacation");
            getAbsenceByVacation();
        }
    }, [props.selectedEmployeeForEdit]);

    useEffect(() => {
        if (props.showModal) {
            getAbsenceData();
        }
    }, [key]);

    const getAbsenceData = () => {
        if (key === "vacation") {
            getAbsenceByVacation();
        } else if (key === "absenceByReasons") {
            getAbsenceByReasons();
        } else if (key === "absenceByHoliday") {
            loadHolidaysForEmployee(props.selectedEmployeeForEdit.id)
        }
    };

    const loadHolidaysForEmployee = (id) => {
        evidentionRepository.getHolidays(id)
            .then((data) => {
                setHolidaysForEmployee(data.data);
            });
    };

    const getAbsenceByVacation = () => {
        AbsencesService.getAllAbsenceVacationByUserId(props.selectedEmployeeForEdit.id)
            .then((data) => {
                setVacationAbsenceData(data.data);
            })

    };

    const getAbsenceByReasons = () => {
        AbsencesService.getAllAbsenceReasonsByUserId(props.selectedEmployeeForEdit.id)
            .then((data) => {
                setReasonsAbsenceData(data.data);
            })
    };

    const getReasonsAbsenceByCurrentYear = () => {
        const currentYear = new Date().getFullYear();
        return reasonsAbsenceData.filter((r) => r.forYear === currentYear)
    }

    const getReasonsAbsenceByOtherYear = () => {
        const currentYear = new Date().getFullYear()
        return reasonsAbsenceData.filter((r) => r.forYear !== currentYear);
    }
    return (
        <Modal show={props.showModal} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Body>
                <div>
                    <h3>
                        {props.selectedEmployeeForEdit.email}
                    </h3>
                </div>
                <div className={"my-5"}>
                    <UserListInModal users={[props.selectedEmployeeForEdit]}/>
                </div>
                <div className={"container w-100 mt-4 mx-auto"}>
                    <h2 className="mt-5 mb-5 remaining-days-header"><span>{strings.allowedAbsenceReview}</span></h2>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mt-3 tabs-buttons">

                        <Tab eventKey="vacation" title={strings.vacation}>
                            <div className={"p-4"}>
                                <AbsenceStatusList data={vacationAbsenceData}/>
                            </div>
                        </Tab>

                        <Tab eventKey="absenceByReasons" title={strings.absenceByReasons}>
                            <div className={"p-4"}>
                                <h3>{strings.currentYear}</h3>
                                <AbsenceStatusList data={getReasonsAbsenceByCurrentYear()}/>
                            </div>
                            <div className={"p-4"}>
                                <h3>{strings.remainingYears}</h3>
                                <AbsenceStatusList data={getReasonsAbsenceByOtherYear()}/>
                            </div>
                        </Tab>
                        <Tab eventKey="absenceByHoliday" title={strings.AbsenceByHoliday}>
                            <div className={"p-4"}>
                                <HolidayListForUser detailsForEmployee={holidaysForEmployee}/>
                            </div>
                        </Tab>
                    </Tabs>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary cancel" onClick={props.handleClose}>
                    {strings.close}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default EmployeeUpsertModal;
