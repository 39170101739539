import React, { useEffect, useState } from 'react';
import {
  Tree,
  getBackendOptions,
  MultiBackend,
} from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import './PositionHierarchyTree.css';
import CustomNode from './CustomNode';
import { strings } from '../../../localization/Localization';
import { FaLaptopCode } from 'react-icons/fa';
import PositionHierarchyAddModal from '../PositionHierarchyAddModal/PositionHierarchyAddModal';
import PositionHierarchyEditModal from '../PositionHierarchyEdtiModal/PositionHierarchyEditModal';
import swal from 'sweetalert';
import PositionHierarchyService from '../../../repository/hierarchyRepository/positionHierarchyRepository';
import UserListForHierarchy from '../../../components/UserListForHierarchy/UserListForHierarchy';
import { BiTimeFive } from 'react-icons/bi';
import { MdOutlineAddBox } from 'react-icons/md';
import { BiEdit } from 'react-icons/bi';
import { BiTime } from 'react-icons/bi';
import { MdOutlineDescription } from 'react-icons/md';
import PositionHierarchyUserModal from '../PositionHierarchyUserModal/PositionHierarchyUserModal';
import ApplicationService from '../../../ApplicationService';
import SpinnerComponent from '../../../components/Spinner';

const OrganizationalHierarchyTree = (props) => {

  const [selectedNode, setSelectedNode] = useState(null);

  const handleSelect = (node) => setSelectedNode(node);

  const [showModalAdd, setShowModalAdd] = useState(false);

  const [showModalEdit, setShowModalEdit] = useState(false);

  const [showUserModal, setShowUserModal] = useState(false);

  const [hierarchyData, setHierarchyData] = useState([]);

  const [fullHierarchy, setFullHierarchy] = useState([]);

  const [userInHierarchy, setUserInHierarchy] = useState([]);

  const [activeLanguage, setActiveLanguage] = useState();

  const [isLoadingSpinner, setIsLoadingSpinner] = useState(true);

  useEffect(() => {
    loadHierarchyData();
    setActiveLanguage(ApplicationService.getActiveLanguage());
  }, []);

  const loadHierarchyData = (edit = false) => {
    setIsLoadingSpinner(false);
    PositionHierarchyService.getHierarchy()
      .then((data) => {
        setFullHierarchy(data.data);
        let niza = convertHierarchyData(data.data);
        setIsLoadingSpinner(true);
        setHierarchyData(niza);
        if (edit) {
          if (selectedNode != null) {
            let temp = niza.filter((n) => {
              return n.id == selectedNode.id;
            });
            setSelectedNode(temp[0]);
          }
        }
      }).catch(() => {
      setIsLoadingSpinner(true);
    });
  };

  const convertHierarchyData = (data) => {
    let niza = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentId == null) {
        niza.push({
          'id': data[i].id,
          'parent': 0,
          'droppable': true,
          'text': data[i].name,
          'data': {
            'activeFrom': data[i].activeFrom,
            'activeTo': data[i].activeTo,
            'name': data[i].name,
            'shortName': data[i].shortName,
            'description': data[i].description,
            'code': data[i].code,
            'dateCreated': data[i].dateCreated,
            'dateModified': data[i].dateModified,
            'parentId': null
          }
        });
      } else {
        let flag = haveChildren(data[i].id, data);
        niza.push({
          'id': data[i].id,
          'parent': data[i].parentId,
          'droppable': flag,
          'text': data[i].name,
          'data': {
            'activeFrom': data[i].activeFrom,
            'activeTo': data[i].activeTo,
            'name': data[i].name,
            'shortName': data[i].shortName,
            'description': data[i].description,
            'code': data[i].code,
            'dateCreated': data[i].dateCreated,
            'dateModified': data[i].dateModified,
            'parentId': data[i].parentId
          }
        });
      }
    }
    return niza;
  };

  const haveChildren = (id, data) => {
    let flag = false;
    data.filter((r) => {
      if (r.parentId != null && r.parentId == id) {
        flag = true;
      }
    });
    return flag;
  };

  const handleDrop = (newTreeData) => saveNewTreeData(newTreeData);

  const saveNewTreeData = (newTreeData) => {
    let temp = hierarchyData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id == newTreeData[i].id && temp[i].parent != newTreeData[i].parent) {

        PositionHierarchyService.editHierarchy(temp[i].id, null, null, null, null, null, null, null, null, null, newTreeData[i].parent)
          .then(() => {
            loadHierarchyData();
            // history.push("/organizationalHierarchy")
          });
        return;
      }
    }
  };

  useEffect(() => {
    if (selectedNode) {
      loadUserForHierarchy({});
    }
  }, [selectedNode]);

  const loadUserForHierarchy = ({
    firstName = null,
    lastName = null,
    parentName = null,
    email = null,
    roles = [],
    religion = [],
    ethnicity = [],
    organisation = [],
    position = [],
    location= []
  }) => {
    PositionHierarchyService.getAllUserForPosition(selectedNode.id, {
      firstName,
      lastName,
      parentName,
      email,
      roles,
      religion,
      ethnicity,
      organisation,
      position,
        location
    })
      .then((data) => {
        setUserInHierarchy(data.data);
      });
  };

  const handleShowAdd = () => {
    setShowModalAdd(true);
  };

  const handleCloseAdd = () => {
    setShowModalAdd(false);
  };

  const handleShowEdit = () => {
    setShowModalEdit(true);
  };

  const handleCloseEdit = () => {
    setShowModalEdit(false);
  };

  const handleShowUserModal = () => {
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    loadUserForHierarchy({});
    setShowUserModal(false);
  };

  let deleteHierarchy = (id) => {
    PositionHierarchyService.deleteHierarchy(id)
      .then((data) => {
        loadHierarchyData();
        setSelectedNode(null);
      });
  };

  const formatDate = (string) => {
    let l = string.split('-');
    let pom = l[2].substr(0, 2) + '.' + l[1] + '.' + l[0];
    return pom;
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteHierarchy(id);
      }
    });
  };

  return (
    <>
      <div className='row w-100'>
        <SpinnerComponent spinnerShow={isLoadingSpinner}/>
        <div className='col-sm-4 px-0 '>
          <div className='mx-2 mt-2 hierarchyselection h-100 overflow-auto'>
            <div className='p-2 mx-2'>
              {/*<h2>{strings.hierarchy}</h2>*/}
            </div>

            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <div className={'hierarchypadding hierarchy-wrapper'}>
                <Tree
                  tree={hierarchyData}
                  rootId={0}
                  onDrop={handleDrop}
                  render={(node, {
                    depth,
                    isOpen,
                    onToggle,
                  }) => (
                    <CustomNode
                      node={node}
                      depth={depth}
                      isOpen={isOpen}
                      isSelected={node.id === selectedNode?.id}
                      onToggle={onToggle}
                      onSelect={handleSelect}/>
                  )}
                />
              </div>
            </DndProvider>
          </div>
        </div>
        <div className='col-sm-8'>
          <div className='mx-3 mt-2 hierarchyselection  responsive-y-wrapper w-100'>
            {selectedNode ?
              <div>
                <div className='p-2 mx-2'>
                  <div className='row'>
                    <div className={'col-sm'}>
                      <div>
                        <h2 className={'text-break'}>{selectedNode ? selectedNode.text : null}</h2>
                      </div>
                      <div>
                        {selectedNode.data.shortName &&
                          <h5>{strings.shortName}: {selectedNode.data.shortName}</h5>
                        }

                      </div>
                    </div>
                    {/*{!isEnabledHierarchy &&*/}
                    {/*    <div className={"d-flex justify-content-center my-1 col-sm"}>*/}
                    {/*        <div>*/}
                    {/*            <button onClick={handleShowAdd}*/}
                    {/*                    className={"btn text-white d-flex align-items-center mx-1 button-hierarchy border-radius add-button px-lg-4"}>*/}
                    {/*                <div className={"align-items-center d-flex text-start"}>*/}
                    {/*                    <i className={"material-icons "}>add_box</i>*/}
                    {/*                </div>*/}
                    {/*                <div className={"d-flex align-items-end mx-1"}>*/}
                    {/*                    {strings.add}*/}
                    {/*                </div>*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <div onClick={handleShowEdit}*/}
                    {/*                 className={"btn text-white d-flex align-items-center mx-1 button-hierarchy border-radius edit-button px-lg-4"}>*/}
                    {/*                <div className={"align-items-center d-flex text-start"}>*/}
                    {/*                    <i className={"material-icons "}>app_registration</i>*/}
                    {/*                </div>*/}
                    {/*                <div className={"d-flex align-items-end mx-1"}>*/}
                    {/*                    {strings.edit}*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <button onClick={() => {*/}
                    {/*                ConfirmationDelete(selectedNode ? selectedNode.id : null)*/}
                    {/*            }}*/}
                    {/*                    className={"btn text-white d-flex align-items-center mx-lg-1 button-hierarchy border-radius rmv-button px-lg-4"}>*/}
                    {/*                <div className={"align-items-center d-flex text-start"}>*/}
                    {/*                    <i className={"material-icons "}>delete_forever</i>*/}
                    {/*                </div>*/}
                    {/*                <div className={"d-flex align-items-end"}>*/}
                    {/*                    {strings.delete}*/}
                    {/*                </div>*/}

                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                  </div>
                </div>
                {!props.isEnabledHierarchy &&
                  <div className={'d-flex justify-content-center my-1 col-sm'}>
                    <div>
                      <button onClick={handleShowAdd}
                              className={'btn text-white d-flex align-items-center mx-1 button-hierarchy border-radius add-button px-lg-4'}>
                        <div className={'align-items-center d-flex text-start'}>
                          <i className={'material-icons '}>add_box</i>
                        </div>
                        <div className={'d-flex align-items-end mx-1'}>
                          {strings.add}
                        </div>
                      </button>
                    </div>
                    <div>
                      <div onClick={handleShowEdit}
                           className={'btn text-white d-flex align-items-center mx-1 button-hierarchy border-radius edit-button px-lg-4'}>
                        <div className={'align-items-center d-flex text-start'}>
                          <i className={'material-icons '}>app_registration</i>
                        </div>
                        <div className={'d-flex align-items-end mx-1'}>
                          {strings.edit}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button onClick={() => {
                        ConfirmationDelete(selectedNode ? selectedNode.id : null);
                      }}
                              className={'btn text-white d-flex align-items-center mx-lg-1 button-hierarchy border-radius rmv-button px-lg-4'}>
                        <div className={'align-items-center d-flex text-start'}>
                          <i className={'material-icons '}>delete_forever</i>
                        </div>
                        <div className={'d-flex align-items-end'}>
                          {strings.delete}
                        </div>

                      </button>
                    </div>
                  </div>
                }
                <div>
                  <div className='container'>
                    <div className='row'>
                      <div
                        className={`col text-center ${activeLanguage == 'mk' ? 'ms-4 ps-3' : 'ms-5 ps-2'} mb-3 mt-4`}>
                        <BiTime/> {strings.activeFrom}: {selectedNode.data.activeFrom ? selectedNode.data.activeFrom :
                        <i>{strings.noInfo}</i>}
                      </div>
                      <div
                        className={`col text-start mb-3 mt-4 ${activeLanguage == 'en' ? 'ps-0' : ''}`}>
                        <BiTimeFive/> {strings.activeTo}: {selectedNode.data.activeTo ? selectedNode.data.activeTo :
                        <i>{strings.noInfo}</i>}
                      </div>
                      <div className='w-100'></div>

                      <div
                        className={`col text-center ${activeLanguage == 'mk' ? 'ms-4' : 'ms-4 ps-3 '}  mb-5  me-1`}>
                        <MdOutlineAddBox/> {strings.created}: {selectedNode.data.dateCreated ? formatDate(selectedNode.data?.dateCreated) :
                        <i>{strings.noInfo}</i>}
                      </div>
                      <div className='col text-start mb-5 ms-1'>
                        <BiEdit/> {strings.modified}: {selectedNode.data.dateModified ? formatDate(selectedNode.data?.dateModified) :
                        <i>{strings.noInfo}</i>}
                      </div>
                    </div>
                  </div>
                  <div className='p-2 mb-3 mx-5 hierarchyelements'>
                    <div>
                      <MdOutlineDescription/> <span
                      className='fw-bold ms-0'>{strings.description}</span>
                    </div>

                    <div className='p-1 mx-2 ms-1 mt-2 '>
                      {selectedNode.data.description ? selectedNode.data.description : <i>{strings.noInfo}</i>}
                    </div>
                  </div>

                  <div className='p-2 mt-2 mx-5 hierarchyelements'>
                    <FaLaptopCode/> <span className='fw-bold ms-0'>{strings.code}</span>

                    <div className='p-1 mx-2 mt-2 ms-1'>
                      {selectedNode.data.code ? selectedNode.data.code : <i>{strings.noInfo}</i>}
                    </div>
                  </div>
                </div>


                <div className={'mx-2 my-5'}>
                  <div className={'d-flex justify-content-between align-items-center mb-1'}>
                    <div className={'d-flex'}>
                      <h3 className={'my-2'}>{strings.membersOfThePosition}</h3>
                    </div>
                    {!props.isEnabledHierarchy &&
                      <div onClick={handleShowUserModal}
                           className={'btn text-white d-flex align-items-center mx-1 border-radius edit-button'}>
                        <div className={'align-items-center d-flex text-start'}>
                          <i className={'material-icons '}>app_registration</i>
                        </div>
                        <div className={'d-flex align-items-end mx-1'}>
                          {strings.editMember}
                        </div>
                      </div>
                    }
                  </div>
                  <UserListForHierarchy users={userInHierarchy}/>
                  {showUserModal &&
                    <PositionHierarchyUserModal
                      setUserInHierarchy={setUserInHierarchy}
                      selectedNode={selectedNode}
                      showModal={showUserModal}
                      handleClose={handleCloseUserModal}
                      loadUserForHierarchy={loadUserForHierarchy}/>
                  }
                </div>
              </div>

              : (hierarchyData.length === 0 ?
                  (
                    <>
                      <div className={'mt-4 d-flex justify-content-center text-secondary'}><h4>
                        <i>{strings.emptyHierarchyList}</i></h4></div>
                      <div className={'d-flex w-100 justify-content-center mt-2'}>

                        <button onClick={handleShowAdd}
                                className={'btn text-white d-flex align-items-center mx-3 border-radius add-button'}>
                          <div className={'align-items-center d-flex text-start'}>
                            <i className={'material-icons '}>add_box</i>
                          </div>
                          <div className={'d-flex align-items-end mx-1'}>
                            {strings.addInitialHierarchy}
                          </div>
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className={'d-flex mt-4 my-auto w-100 h-100 text-center'}>
                      <h5 className={'w-100 text-center '}><i className={'text-secondary'}>{strings.selectHierarchy}</i>
                      </h5>
                    </div>
                  )
              )
            }
          </div>
        </div>
      </div>

      <div>
        <PositionHierarchyAddModal showModal={showModalAdd}
                                   handleClose={handleCloseAdd}
                                   selectedNode={selectedNode}
                                   loadHierarchy={loadHierarchyData}
        />
      </div>

      <div>
        <PositionHierarchyEditModal showModal={showModalEdit}
                                    handleClose={handleCloseEdit}
                                    selectedNode={selectedNode}
                                    loadHierarchy={loadHierarchyData}/>
      </div>
    </>
  );
};
export default OrganizationalHierarchyTree;