import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EthnicityRepository from "../../../repository/ethnicityRepository/EthnicityRepository";
import {strings} from "../../../localization/Localization";

const EthnicityModal = (props) => {

    const history = useHistory();

    const [formData, updateFormData] = React.useState({
        id: "",
        name: "",
        label_MK: "",
        label_SQ: "",
        label_EN: "",
    });

    useEffect(() => {
        resetFormData();
    }, [props.showModal]);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    };

    const resetFormData = () => {
        updateFormData({
            id: "",
            name: "",
            label_MK: "",
            label_SQ: "",
            label_EN: "",
        })
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const addEthnicity = (name, label_mk, label_sq, label_en) => {
        EthnicityRepository.addEthnicity(name, label_mk, label_sq, label_en)
            .then((data) => {
                successfulAlert();
                props.handleClose();
            }).catch(() => {
            warningAlert();
        })
    };

    const editEthnicity = (id, name, label_mk, label_sq, label_en) => {
        EthnicityRepository.editEthnicity(id, name, label_mk, label_sq, label_en)
            .then(() => {
                successfulAlert();
                props.handleClose();
            }).catch(() => {
            warningAlert();
        });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id !== "" ? formData.id : props.selectedEthnicityForEdit.id;
        const name = formData.name !== "" ? formData.name : props.selectedEthnicityForEdit.name;
        const label_mk = formData.label_mk !== "" ? formData.label_mk : props.selectedEthnicityForEdit.label_mk;
        const label_sq = formData.label_sq !== "" ? formData.label_sq : props.selectedEthnicityForEdit.label_sq;
        const label_en = formData.label_en !== "" ? formData.label_en : props.selectedEthnicityForEdit.label_en;
        if (!props.isEdit) {
            addEthnicity(name, label_mk, label_sq, label_en);
        } else {
            editEthnicity(id, name, label_mk, label_sq, label_en);
        }
    };

    return (
        <Modal show={props.showModal} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                {!props.isEdit && <Modal.Title>{strings.addNewEthnicity}</Modal.Title>}
                {props.isEdit && <Modal.Title>{strings.editEthnicity}</Modal.Title>}
            </Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{strings.ethnicityName}</Form.Label>
                        <Form.Control
                            type="text"
                            autoFocus
                            defaultValue={props.selectedEthnicityForEdit.name}
                            required
                            onChange={handleChange}
                            disabled={props.isEnabledSync}
                            name="name"
                        />
                    </Form.Group>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3 required" controlId="label_mk">
                                <Form.Label> {strings.labelMk} </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    autoFocus
                                    defaultValue={props.selectedEthnicityForEdit.label_mk}
                                    name='label_mk'
                                    disabled={props.isEnabledSync}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="label_sq">
                                <Form.Label>{strings.labelSq}</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    autoFocus
                                    required
                                    defaultValue={props.selectedEthnicityForEdit.label_sq}
                                    disabled={props.isEnabledSync}
                                    name="label_sq"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="label_en">
                                <Form.Label>{strings.labelEn}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    autoFocus
                                    disabled={props.isEnabledSync}
                                    defaultValue={props.selectedEthnicityForEdit.label_en}
                                    onChange={handleChange}
                                    name="label_en"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    {!props.isEnabledSync &&
                        <button type={"submit"} className={"save btn text-white"}>
                            {strings.save}
                        </button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default EthnicityModal;