import axios from "../../axios/axios";

const CalendarHistoryRepository = {
    fetchCalendarYears: () => {
        return axios.get(`holiday/getCalendarYears`)
    },
    copyCalendar: (fromYear, toYear) => {
        return axios.post(`/holiday/copyHoliday/${fromYear}/${toYear}`)
    }
}

export default CalendarHistoryRepository;