import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import UserService from '../repository/userRepository/UserRepository';
import { useDebounce } from './useDebounce';

const VirtualizedList = ({ onSelectedOptionChange }) => {
  const [filterEmployee, setFilterEmployee] = useState({
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roles: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: [],
    location: []
  });

  const [options, setOptions] = useState([]);
  const [pageNo, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  const [selectedValue, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const debouncedSearch = useDebounce(() => filterEmployee.firstName, 500);

  const loadOptions = async (page) => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const params = {
        page,
        size: 50
      };

      let { data } = await UserService.fetchUsersHR(params.page, params.size, filterEmployee);

      if (filterEmployee.firstName !== '') {
        data = await UserService.fetchUsersHR(params.page, params.size, filterEmployee);
        data = data.data;
      }

      const totalElements = data.totalElements;

      const dataOptions = data?.content.map((user) => ({
        label: user?.firstName + ' ' + user?.lastName,
        value: user?.id
      }));

      const itemsData = options.concat(dataOptions);
      setOptions(itemsData);
      setHasNextPage(itemsData.length < totalElements);
      setPage(page);
    } catch (error) {
      console.error('Error loading options:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadNextPage = async () => {
    await loadOptions(pageNo + 1);
  };

  useEffect(() => {
    loadOptions(pageNo);
  }, [debouncedSearch]);

  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      loadNextPage();
    }
  };

  const handleInputChange = (inputValue) => {
    setFilterEmployee({
      ...filterEmployee,
      firstName: inputValue,
    });
  };

  const handleUserChange = (selected) => {
    setSelectedOption(selected);
    onSelectedOptionChange(selected);
  };

  return (
    <Form.Group className='mb-3 justifyContent' controlId='description'>
      <Form.Label>Корисник</Form.Label>
      <Select
        placeholder='Select'
        isClearable
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        options={options}
        onMenuScrollToBottom={handleScroll}
        onChange={(selected) => {
          handleUserChange(selected);
        }}
        onInputChange={handleInputChange}
        value={selectedValue}
      />
    </Form.Group>
  );
};

export default VirtualizedList;
