import axios from "../../axios/axios";

const UserService = {
    fetchUsers: (page, size, data = {
        "firstName": null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roles: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: [],
        location: null
    }) => {
        return axios.post(`/user/getAllFiltered/${page}/${size}`, data)
    },
    fetchUsersIgnoreIsActive: (page, size, data = {
        "firstName": null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roles: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: [],
        location: null
    }) => {
        return axios.post(`/user/getAllFilteredActive/${page}/${size}`, data)
    },
    getUserDetails: () => {
        return axios.get(`/user/getUserDetails`)
    },
    editUser: (id, email, firstName, lastName, parentName, roleIds, phoneNumber, address, ethnicityId, religionId, hrmsIdNumber) => {
        return axios.put('/user/update', {
            id,
            email,
            "enabled": true,
            firstName,
            lastName,
            phoneNumber,
            address,
            "headUser": null,
            "person": null,
            parentName,
            "employment": null,
            roleIds,
            ethnicityId,
            religionId,
            hrmsIdNumber
        })
    },
    deleteUser(id) {
        return axios.delete(`/user/delete/${id}`)
    },
    getAllUserForHoliday: (firstName, lastName, parentName, email,hrmsIdNumber, roles, religion, ethnicity, id, organisationsIds, positionsIds, page, size ,location) => {
        return axios.post(`/user/getAllByHoliday/${id}/${page}/${size}`, {
            email,
            firstName,
            lastName,
            parentName,
            hrmsIdNumber,
            roles: null,
            ethnicity,
            religion,
            organisation: organisationsIds,
            position: positionsIds,
            location
        })
    },
    getAllUserWithoutHoliday: (firstName, lastName, parentName, email, hrmsIdNumber, roles, religion, ethnicity, id, organisationsIds, positionsIds, page, size, location) => {
        return axios.post(`/user/getAllWithoutHoliday/${id}/${page}/${size}`, {
            email,
            firstName,
            lastName,
            parentName,
            hrmsIdNumber,
            roles: roles,
            ethnicity,
            religion,
            organisation: organisationsIds,
            position: positionsIds,
            location
        })
    },
    getAllUserForHolidayFiltered: (filterDateForHolidayUserList,id) => {
        return axios.post(`/user/getAllByHolidayUsersForHolidayFiltered/${id}`, {
            "email":filterDateForHolidayUserList.email,
            "firstName":filterDateForHolidayUserList.firstName,
            "lastName":filterDateForHolidayUserList.lastName,
            "parentName":filterDateForHolidayUserList.parentName,
            "hrmsIdNumber":filterDateForHolidayUserList.hrmsIdNumber,
            roles: [],
            "ethnicity":filterDateForHolidayUserList.ethnicityId,
            "religion":filterDateForHolidayUserList.religionIds,
            organisation: filterDateForHolidayUserList.organisationsIds,
            position: filterDateForHolidayUserList.positionsIds,
            location:filterDateForHolidayUserList.location
        })
    },
    getAllUserWithoutHolidayFiltered: (filterDateForOtherUserList,id) => {
        return axios.post(`/user/getAllByHolidayUsersWithOutHoliday/${id}`, {
            "email": filterDateForOtherUserList.email,
            "firstName": filterDateForOtherUserList.firstName,
            "lastName": filterDateForOtherUserList.lastName,
            "parentName": filterDateForOtherUserList.parentName,
            "hrmsIdNumber": filterDateForOtherUserList.hrmsIdNumber,
            roles: [],
            "ethnicity": filterDateForOtherUserList.ethnicityId,
            "religion": filterDateForOtherUserList.religionIds,
            organisation: filterDateForOtherUserList.organisationsIds,
            position: filterDateForOtherUserList.positionsIds,
            location:filterDateForOtherUserList.location
        })
    },
    changeUserActive:(id)=>{
        return axios.post(`/user/enableUser/${id}`)
    },

    getAllLocations: () => {
        return axios.get(`/user/getLocations`)
    },
    getAllUsers: () => {
        return axios.get(`/user/getAll`)
    },
    fetchUsersHR: (page, size, data = {
        "firstName": null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roles: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: [],
        location: null
    }) => {
        return axios.post(`/user/getAllFilteredHR/${page}/${size}`, data)
    }
}
export default UserService;