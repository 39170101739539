import React, {useEffect, useState} from "react"
import Modal from "react-bootstrap/Modal";
import {strings} from "../../../localization/Localization";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserListInModal from "../../../components/UserListInModal";
import UserAddService from "../../../repository/userRepository/UserAddRepository";
import swal from "sweetalert";


const UserChangePasswordModal = (props) => {

    const [formData, setFormData] = useState({
        newPassword: "",
        repeatNewPassword: ""
    });

    const bothFieldsAreFilled = formData.newPassword && formData.repeatNewPassword;

    const passwordNotMatchException = formData.newPassword === formData.repeatNewPassword;

    useEffect(()=>{
       resetFormData();
    },[props.showModal])

    const resetFormData = () => {
        setFormData({
            newPassword: "",
            repeatNewPassword: ""
        })
    }

    const successfulAlert = (text) => {
        swal(text, {
            text:text,
            icon: "success",
        });
    };

    const errorAlert = (text) => {
        swal({
            title: strings.oops,
            text: text,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        })
    };


    const onFormSubmit = (e) => {
        e.preventDefault();
        UserAddService.changePasswordByAdminAndSuperAdmin(props.selectedUserForChangePassword.email,formData.newPassword,formData.repeatNewPassword)
            .then((data) => {
                successfulAlert(strings.passwordChangeSuccessful);
                resetFormData();
            }).catch((e) => {
            let error="";
            if(e.response.data =="Incorrect password"){
                error=strings.incorrectPassword;
            }else if( e.response.data=="Password do not match exception"){
                error=strings.passwordsConfirmationDoNotMatch;
            }else{
                error=strings.somethingWrong;
            }
            errorAlert(error);
        })
    }



    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    };


    return (
        <Modal show={props.showModal} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{strings.changePassword}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <div className={"my-3"}>
                        <UserListInModal users={[props.selectedUserForChangePassword]}></UserListInModal>
                    </div>
                    <Row className={"my-4"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.newPassword}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    autoFocus
                                    value={formData.newPassword}
                                    name='newPassword'
                                    onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.confirmNewPassword}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    autoFocus
                                    value={formData.repeatNewPassword}
                                    name='repeatNewPassword'
                                    onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                        {bothFieldsAreFilled ?
                            (passwordNotMatchException ?
                                    (
                                        <div
                                            className={'d-inline-flex ms-2'}>
                                            <i className="material-icons text-success">check_circle</i>
                                            &nbsp; {strings.passwordsMatch}
                                        </div>
                                    ) :
                                    (
                                        <div
                                            className={'d-inline-flex ms-2 '}>
                                            <i className="material-icons text-danger">report</i>
                                            &nbsp;{strings.passwordsDoNotMatch}
                                        </div>
                                    )
                            ) : null
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} className={"save btn text-white"} disabled={!passwordNotMatchException}>
                        {strings.save}
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}
export default UserChangePasswordModal;