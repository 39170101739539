import React from "react";
import {strings} from "../localization/Localization";
import {FaChevronDown} from "react-icons/fa";
import 'material-icons/iconfont/material-icons.css';
import {Link} from "react-router-dom";

const dropdown = () => {
    return (
        <div style={{width: "100%",zIndex:"1010"}}>
            <a className="dropdown d-flex align-items-center btn btn-dark icon-button"  id="dropdownMenu"
               data-bs-toggle="dropdown" aria-expanded="false">
                <FaChevronDown/>
            </a>

            <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                <button className="dropdown-item w-100" type="button">
                    <Link className="item d-inline-flex w-100 text-dark"
                       to="/myprofile">
                        <div className={"icon-wrapper "}>
                            <i className="material-icons mx-1">person_pin</i>
                        </div>
                        <div className={"mx-1"}>
                            {strings.profile}
                        </div>
                    </Link>
                </button>
                <button className="dropdown-item w-100" type="button">
                    <Link
                        className="item  w-100 d-inline-flex text-dark"
                        to="/logout">
                        <div className={"icon-wrapper text"}>
                            <i className="material-icons mx-1">logout</i>
                        </div>
                        <div className={"mx-1"}>
                            {strings.logOut}
                        </div>
                    </Link>
                </button>
            </div>
        </div>
    );
}
export default dropdown;
