import React from "react"
import {strings} from "../../../../localization/Localization";
import {Form} from "react-bootstrap";
import swal from "sweetalert";

const ListForAddAndDelete = (props) => {

    const confirmRemoveAlert = () => {
        swal({
            title: strings.areYouSure,
            text: strings.confirmRemoveUserFromHierarchy+props.userForDelete.length+" "+strings.confirmRemoveUserFromHierarchyMemberNumber,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                props.deleteUserFromPosition();
            } else {

            }
        });
    };

    const confirmAddAlert = () => {
        swal({
            title: strings.areYouSure,
            text: strings.confirmAddUserToHierarchy+ props.userForAdd.length+" "+strings.confirmAddUserToHierarchyMemberNumber,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                props.addUserInPosition();
            } else {

            }
        });
    };


    const removeFromUserFromDelete = (userId) => {
        if (document.getElementById(userId) != null) {
            document.getElementById(userId).checked = false;
        }

        props.setUserForDelete(props.removeFromList(props.userForDelete, userId));
    }

    const removeFromUserFromAdd = (userId) => {
        if (document.getElementById(userId) != null) {
            document.getElementById(userId).checked = false;
        }
        props.setUserForAdd(props.removeFromList(props.userForAdd, userId));
    }


    return (
        <>
            <Form.Group className="h-100" controlId="userDetails">
                <div className={"h-50 min-height-table"}>
                    <h5 className={"my-2"}>{strings.usersToRemoveFromPosition}</h5>
                    <div className={"h-75"}>
                        <div className={"w-100  h-100 bg-light p-3 border-1 border-radius responsive-y-wrapper"}>
                            {props.userForDelete.length > 0 ?
                                (props.userForDelete.map((user) => {
                                        return (
                                            <div key={user.id} scope={"row"} className={"row"}>
                                                <div scope="col" className={"col-1"}>
                                                    <div className={"text-danger material-icons cursor-pointer"}
                                                         title={"remove"}
                                                         onClick={() => removeFromUserFromDelete(user.id)}>clear
                                                    </div>
                                                </div>
                                                <div className={"col-10"}>{user.firstName} {user.lastName}</div>
                                                {/*<div className={"col-4"}> {user.email}</div>*/}
                                            </div>
                                        )
                                    })

                                ) :
                                (
                                    <div className={"justify-content-center w-100 text-center"}>
                                        <div>
                                            <h6 className={"text-secondary"}>
                                                <i>{strings.emptyList}</i></h6>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        {props.userForDelete.length > 0 &&
                            <div className={"d-flex  justify-content-between"}>
                                <div className={"my-2"}>
                                    {strings.selected}: {props.userForDelete.length}
                                </div>
                                <div className={"text-end my-2"}>
                                    <div
                                        onClick={confirmRemoveAlert}
                                        className={"btn btn-sm text-white add-button "}>
                                        {strings.saveChange}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
                <div className={"h-50 min-height-table mt-1"}>
                    <h5 className={"my-2"}>{strings.usersToAddToPosition}</h5>
                    <div className={"h-75"}>
                        <div className={"h-100"}>
                            <div className={"w-100  h-100 bg-light p-3 border-1 border-radius responsive-y-wrapper"}>
                                {props.userForAdd.length > 0 ?
                                    (props.userForAdd.map((user) => {
                                            return (
                                                <div key={user.id} scope={"row"} className={"row"}>
                                                    <div scope="col" className={"col-1"}>
                                                        <div className={"text-danger material-icons cursor-pointer"}
                                                             title={"remove"}
                                                             onClick={() => removeFromUserFromAdd(user.id)}>clear
                                                        </div>
                                                    </div>
                                                    <div className={"col-10"}>{user.firstName} {user.lastName}</div>
                                                    {/*<div className={"col-4"}> {user.email}</div>*/}
                                                </div>
                                            )
                                        })

                                    ) :
                                    (
                                        <div className={"justify-content-center text-center w-100"}>
                                            <div>
                                                <h6 className={"text-secondary "}>
                                                    <i>{strings.emptyList}</i></h6>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                    {props.userForAdd.length > 0 &&
                        <div className={"d-flex  justify-content-between"}>
                            <div className={"my-2"}>
                                {strings.selected}: {props.userForAdd.length}
                            </div>
                            <div className={"text-end   my-2"}>
                                <div onClick={confirmAddAlert}
                                     className={"btn btn-sm text-white add-button "}>
                                    {strings.saveChange}
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </Form.Group>
        </>
    )
}
export default ListForAddAndDelete;