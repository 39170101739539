import React from "react"
import {strings} from "../../localization/Localization";
import "./UserListForHirarchy.css"

const UserListForHierarchy = (props) => {


    return (
        <>
            {props.users.length > 0 ?
                (
                    props.users.map((user) => {
                        return (
                            <>
                                <div className="card b-1 hover-shadow " key={user.id}>
                                    <div className="media card-body ">
                                        <div className="media-left col-1  h-100 mt-2">
                                            <img className="avatar avatar-xl no-radius "
                                                 src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="..."/>
                                        </div>
                                        <div className="media-body col-6 ps-4">
                                            <div className="mb-2">
                                                <span className="fs-20 pr-16">{user.firstName} {user.lastName}</span>
                                            </div>

                                            <div className="mb-2">
                                                <small className="fs-16 fw-300 ls-1">{user.position?.name} </small>
                                            </div>
                                        </div>
                                        <div className="media-right  d-none d-md-block col-4 ">
                                            <div className="text-fade d-flex">
                                                <div className={"align-items-center mx-1"}>
                                                    <i className="material-icons pr-1 ">
                                                        email
                                                    </i>
                                                </div>
                                                <div className={"w-75"}>
                                                    {user.email}
                                                </div>
                                            </div>
                                            <div className="text-fade d-flex">
                                                <div className={"align-items-center mx-1"}>
                                                    <i className="material-icons pr-1 ">
                                                        location_on
                                                    </i>
                                                </div>
                                                <div>
                                                    {user.address !=null ? user.address : <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>
                                            <div className="text-fade d-flex">
                                                <div className={"align-items-center mx-1"}>
                                                    <i className="material-icons pr-1 ">
                                                        phone_in_talk
                                                    </i>
                                                </div>
                                                <div>
                                                    {user.phoneNumber !=null ? user.phoneNumber : <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                )
                :
                (
                    <h5 className={'text-center text-secondary'}>
                        <i>  {strings.noMemberInPosition} </i></h5>
                )
            }
        </>
    )

}
export default UserListForHierarchy;