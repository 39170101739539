import './App.css';
import {strings} from "./localization/Localization";
import {useState} from "react";
import Aux from "./hoc/AuxWrapper";
import {AUTH_TOKEN} from "./shared/utility";
import Routes from "./routes";

const App =() =>{

    const [role, setRole] = useState("");
    const [data, setData] = useState({});

    const activeLanguage= localStorage.getItem('activeLanguage')
    if(activeLanguage === null || activeLanguage === undefined){
        localStorage.setItem('activeLanguage', 'mk')
        strings.setLanguage('mk')
    } else {
        strings.setLanguage(activeLanguage)
    }

    let authToken = localStorage.getItem(AUTH_TOKEN);
    let userRole = localStorage.getItem('userDetails');

    return (
        <Aux>
            {<Routes/>}
        </Aux>
    );
}

export default App;
