import React from "react"
import {Link} from "react-router-dom";
import LogoElemEN from  "../../assets/images/ESM_EN.png";
import LogoElemMK from  "../../assets/images/ESM_MK.png";
import Notifications from "../Notifications/Notifications";
import DropDown from "../Dropdown";
import {FiMenu} from "react-icons/fi";
import "./MobileNavigationBar.css"
import ApplicationService from "../../ApplicationService";

const MobileNavigationBar = (props) => {


    return (
        <div className="mobile-navigation container-fluid p-1 pt-1">
            <div className="row g-0 align-items-center">
                <div className="col-6 justify-content-end text-start p-1"><Link to="/home"><img
                    className="img-fluid ps-2 " src={ApplicationService.getActiveLanguage()=="mk"? LogoElemMK : LogoElemEN}/></Link>
                </div>
                <div className="col-6 justify-content-end align-items-center  d-flex">

                    <div className={"d-flex align-items-center text-end "}>
                        <div className={"d-flex "}>
                            <div className="d-flex justify-content-end">
                                <Notifications/>
                            </div>
                            <div className="d-flex justify-content-end ps-3">
                                <DropDown/>
                            </div>
                        </div>
                        <div className={"text-dark me-2"} onClick={props.handleMobileMenu}>
                            <FiMenu size={50}/>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );

}
export default MobileNavigationBar