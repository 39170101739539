import styles from "./CustomNode.module.css";
import {FaAngleRight} from "react-icons/fa";


const CustomNode = (props) => {

    const { droppable, data } = props.node;

    const handleToggle = (e) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    const handleSelect = () => props.onSelect(props.node);

    return (
        <div className={`btn btn-light mb-2 ${styles.root} ${
            props.isSelected ? styles.isSelected : ""
        }`}
             onClick={(e)=>{
                 handleSelect();
                 handleToggle(e)

             }}
        >

            <div   className={`${styles.expandIconWrapper} ${
                props.isOpen ? styles.isOpen : ""
            }`}>
                {props.node.droppable && (
                    <span style={{minWidth:"200%", minHeight:"200%"}} >
                        <FaAngleRight style={{marginTop:"25%"}}/>
                    </span>
                )}
            </div>
            <div>
                <div droppable={droppable} fileType={data?.fileType} />
            </div>
            <div className="text-center">
                {props.node.text}
            </div>
        </div>
    );
}
export default CustomNode;