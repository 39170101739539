export const en_strings = {
  signIn: 'Sign In',
  email: 'E-mail',
  password: 'Password',
  profile: 'Profile',
  logOut: 'Log out',
  name: 'Name',
  lastName: 'Surname',
  fatherName: 'Father name',
  phoneNumber: 'Phone Number',
  address: 'Address',
  roles: 'Role/s',
  addevent: 'Add Event',
  week: 'Week',
  month: 'Month',
  calendar: 'Calendar',
  dateselected: 'Selected Date',
  UserList: 'User list',
  addNewUser: 'Add new user',
  editUser: 'Edit user',
  close: 'Close',
  cancel: 'Cancel',
  save: 'Save',
  back: 'Go Back',
  register: 'Register',
  forgotPassword: 'Forgot password?',
  resetPassword: 'Reset password',
  saveProfile: 'Save Profile',
  ethnicityList: 'Ethnicity List',
  ethnicityName: 'Ethnicity',
  labelMk: 'Macedonian',
  labelEn: 'English',
  labelSq: 'Albanian',
  addNewEthnicity: 'Add New Ethnicity',
  editEthnicity: 'Edit Ethnicity',
  registrationFailed: 'Registration failed, try again',
  registrationSuccessful: 'Registration successful, click here to be redirected back to the Login page',
  backToLogin: 'Back to login',
  accessRestrictedText: 'The page you\'re trying to access has restricted access.',
  referToSystemAdministrator: 'Please refer to your system administrator',
  wrongCredentials: 'Wrong E-Mail or Password!',
  organizationalUnit: 'Organizational unit',
  religionList: 'Religion list',
  addNewReligion: 'Add new religion',
  editReligion: 'Edit religion',
  minimizeThisMenu: 'Minimize This Menu',
  administration: 'Administration',
  users: 'Users',
  religion: 'Religion',
  ethnicity: 'Ethnicity',
  organisation: 'Organisation',
  leaveRequest: 'Leave request',
  dateFrom: 'Date From',
  dateTo: 'Date To',
  totalDays: 'Calendar days',
  correctedWorkDays: 'Work days',
  leaveType: 'Leave Type',
  leaveRequestStatus: 'Status',
  requestALeave: 'File a Leave Request',
  description: 'Description',
  day: 'Enter number of days...',
  sendRequest: 'Send request',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'october',
  november: 'november',
  december: 'december',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  addHoliday: 'Add Holiday',
  editHoliday: 'Edit Holiday',
  delete: 'Delete',
  holiday: 'Holiday',
  holidays: 'Holidays',
  calendarOfHolidays: 'Calendar of holidays',
  evidention: 'Evidention',
  statuscalendar: 'Status Calendar',
  userevidention: 'Evidention of employees',
  EmployeeList: 'Employee Evidention',
  filter: 'Filter',
  absenceCalendar: 'Absence calendar',
  noHolidaysForSelectedDate: 'There are no holidays for the selected date',
  home: 'Home',
  successfulReligion: 'The religion was succesfully added!',
  somethingWrong: 'A problem occurred while performing the desired action!',
  oops: 'Error...',
  fileIsSafe: 'The file is safe!',
  deleteConfirmation: 'Do you really want to delete it?',
  deleteConfirmationNoQuestionMark: 'Are you sure you want to delete the user:',
  areYouSure: 'Are you sure?',
  deletedForever: 'Will be deleted forever.',
  absence: 'Absence',
  type: 'Type',
  nationalType: 'National type',
  religionType: 'Religious type',
  ethnicityType: 'Ethnic type',
  remainingDays: 'Remaining days',
  forYear: 'Year',
  allowedDays: 'Allowed Days',
  calendarHistory: 'Calendar History',
  weAreSorry: 'We are Sorry...',
  userForHoliday: 'User for this holiday',
  otherUser: 'Other Users',
  noUserForSelectedHoliday: 'No user for selected holiday',
  noOtherUser: 'No other user',
  parentName: 'Parent name',
  recentActivity: 'Recent activity',
  enterDescription: 'Enter Description...',
  tasks: 'Tasks',
  organizationalHierarchy: 'Organizations',
  hierarchy: 'Hierarchy',
  selectedHierarchy: 'Selected Hierarchy',
  selectedItemHierarchy: 'Selected Item Information From Hierarchy',
  yes: 'Yes',
  no: 'No',
  holidayRecurring: 'The holiday recurs every year',
  enterValidateDate: 'Enter Validate date',
  reasonsForLeaveRequest: 'Reasons for leave request',
  add: 'Add',
  edit: 'Edit',
  copyCalendar: 'Copy calendar',
  editLeaveRequest: 'Edit leave request',
  approveRequest: 'Approve request',
  returnRequest: 'Return for correction',
  declineRequest: 'Decline request',
  approveRequestTermination: 'Accept teremination',
  declineRequestTermination: 'Decline termination',
  responseDescription: 'Response description',
  myRequest: 'My request',
  deliverRequest: 'Delivered request',
  downloadFile: 'Download file',
  enterDate: 'Enter date',
  year: 'Year',
  download: 'Download',
  saveChange: 'Save change',
  responseOnRequest: 'Respond to request',
  responseIsSent: 'Response is sent',
  fileDownloaded: 'File downloaded',
  shortName: 'Short Name',
  activeFrom: 'Active From',
  activeTo: 'Active To',
  unknown: 'Unknown',
  created: 'Created',
  modified: 'Modified',
  code: 'Code',
  hierarchyName: 'Hierarchy Name',
  hierarchyDescription: 'Hierarchy Description',
  hierarchyCode: 'Hierarchy Code',
  hierarchyShortName: 'Hierarchy Short Name',
  addNewHierarchy: 'Add New Hierarchy',
  editHierarchy: 'Edit Hierarchy',
  leaveRequests: 'Leave requests',
  newRequests: 'New requests',
  otherRequests: 'Other requests',
  size: 'size',
  search: 'Search',
  remove: 'Remove',
  workingDays: 'Working days',
  positionHierarchy: 'Hierarchy of positions',
  noMemberInPosition: 'There are no members in the position',
  emptyList: 'The list is empty',
  membersOfThePosition: 'Members of the positions',
  addMembers: 'Add members',
  allowedAbsence: 'Allowed absence',
  usedDays: 'Used days',
  allowedAbsenceReview: 'Allowed absence review',
  vacation: 'Vacation',
  absenceByReasons: 'Absence by reasons',
  hrmsIdNumber: 'Identification number',
  organisationHierarchy: 'Hierarchy of organisation',
  fromYear: 'From year',
  toYear: 'To year',
  editMember: 'Edit members',
  requestsAwaitingResponse: 'Requests awaiting response',
  currentYear: 'Current year',
  remainingYears: 'Remaining years',
  addInitialHierarchy: 'Add initial hierarchy',
  selectHierarchy: 'Select hierarchy',
  usersToAddToPosition: 'Members to add to position',
  usersToRemoveFromPosition: 'Members to remove from position',
  editMemberInHierarchy: 'Edit member in hierarchy',
  confirmRemoveUserFromHierarchy: 'Do you really want to remove ',
  confirmRemoveUserFromHierarchyMemberNumber: 'member from the hierarchy?',
  confirmAddUserToHierarchy: 'Do you really want to add ',
  confirmAddUserToHierarchyMemberNumber: 'member to the hierarchy?',
  theMailFurtherUsedForLoggingIntoTheSystem: 'the e-mail is further used for logging into the system!',
  nationalHoliday: 'National holiday',
  ethnicityHoliday: 'Ethnicity holiday',
  religionHoliday: 'Religion holiday',
  religionAndNationalHoliday: 'Religion and national holiday',
  removeMembers: 'Remove members',
  confirmRemoveUserFromHoliday: 'Do you really want to remove  ',
  confirmRemoveUserFromHolidayMemberNumber: 'member from the holiday?',
  confirmAddUserToHoliday: 'Do you really want to add ',
  confirmAddUserToHolidayMemberNumber: 'member to the holiday?',
  absencesByHoliday: 'Absences by holiday',
  notifications: 'Notifications',
  position: 'Position',
  positions: 'Positions',
  organization: 'Organisation',
  absencesByDayOff: 'Absences by day off',
  viewRemainingAbsence: 'View remaining absence',
  emptyHierarchyList: 'The list of hierarchy is empty',
  usersToAddToHoliday: 'Members for add to holiday',
  usersToRemoveFromHoliday: 'Members for delete from holiday',
  settings: 'Settings',
  enableHierarchyEditing: 'Enable hierarchy editing',
  doYouReallyWantToDelete: 'Are you sure you want to delete the holiday:',
  thisHolidayCannotBeRestored: 'This holiday will not be able to be returned to the system.',
  holidayIsDeleted: 'Holiday  is deleted!',
  actionSuccessful: 'Your action is successful',
  doYouWantToSelectADeputy: 'Do you want to select a deputy',
  selectDeputy: 'Select a deputy',
  noAbsenceForSelectedDate: 'No absence for selected date',
  all: 'All',
  vacationn: 'Vacation',
  thisUserCannotBeRestored: 'This user will not be able to return to the system.',
  userIsDeleted: 'User is deleted!',
  welcomeToSystemTitle: 'Welcome to the ESM Absence Management System',
  ethnicityDeleted: 'Ethnicity is deleted.',
  fieldsRequired: 'Please fill in all fields.',
  required: 'Required!',
  userAddSuccessful: 'User added successfully',
  religionDeleted: 'Religion is deleted.',
  enableSync: 'Enable sync',
  accountNotRegistered: 'That account is not registered',
  passwordChangeMessageSuccessfully: 'A password change message has been successfully sent to your email.',
  send: 'Send',
  enterApprovalStatusRequired: 'Please enter an approval status and description.',
  emptyNotificationList: 'The notification list is empty',
  AbsenceByHoliday: 'Absence by holiday',
  workPosition: 'Work place',
  noInfo: 'No information',
  duplicateLeaveRequestException: 'You already have that period leave request!',
  noAvailableDays: 'No available days!',
  noAvailableDaysForThisYear: 'No available days for this year!',
  noEnoughAvailableDays: 'You don\'t have enough days available!',
  holidaysSuccessfullyCreated: 'The holiday has been successfully created. Continue to add users for the created holiday.',
  holidaysSuccessfullyEdited: 'Holiday modified successfully. Continue to add users for the created holiday.',
  reset: 'Reset',
  positionForOrganisation: 'Positions for the selected organization',
  noPositionForSelectedOrganisation: 'There are no positions for the selected organization',
  ok: 'OK',
  selected: 'Selected',
  userEditSuccessful: 'The change is successful. ',
  alreadyHaveSickLeaveDuring: 'You already have sick leave during that period',
  userAlreadyHaveSickLeaveDuring: 'User already have sick leave during that period',
  defaultDescriptionForHavingSickLeaveDuring: 'Your request cannot be accepted because you are on sick leave during that period.',
  changePassword: 'Change password',
  newPassword: 'New Password',
  oldPassword: 'Old Password',
  confirmNewPassword: 'Confirm new password',
  enterNewPassword: 'Enter new password...',
  enterOldPassword: 'Enter old password...',
  savePassword: 'Save password',
  passwordsMatch: 'Passwords match',
  passwordsDoNotMatch: 'Password do not match',
  passwordsConfirmationDoNotMatch: 'Your password confirmation did not match your password!',
  passwordChangeSuccessful: 'Password change successful!',
  incorrectPassword: 'Your password is incorrect!',
  noInfoForSelectedLeaveType: 'There is no data for selected leave type',
  blockUnblockUser: 'Block/Unblock user',
  noOptions: 'No options',
  youHaveNoDaysLeftDueToPendingRequests: 'You have no days left due to pending requests',
  location: 'Location',
  idNumber: 'Id number',
  id: 'Id',
  dateCreated: 'Date created',
  date: 'Date',
  actionHistory: 'Action history for the request',
  actionListEmpty: 'The action list is empty',
  enterEvent: 'Enter Event',
  select: 'Select...',
  filterByName: 'Filter by name...',
  filterByParentName: 'Filter by parent...',
  filterBySurname: 'Filter by surname...',
  filterByIdentificationNumber: 'Filter by identification number...',
  filterByEmail: 'Filter by email...',
  employmentNumber: 'Employment number',
  personNumber: 'Person number',
  filterByLocation: 'Filter by location...',
  isLoading: 'Loading...',
  terminated: 'Terminated',
  termination_request: 'Termination request',
  previous_correction_request: 'Previous request for correction',
  correction_request: 'Correction request',
  approved_after_declined_termination: 'Approved after declined termination',
  approved_after_declined_correction: 'Approved after declined correction',
  approved_correction: 'Approved correction request',
  leaveRequestDatesNotModified: 'Please change the leave request dates before submitting a correction',
  deputy: 'Deputy'
};
