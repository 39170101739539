import React from "react"
import "./AddButton.css"
import {strings} from "../../localization/Localization";

const AddButton = (props) => {

    const onClickEvent = () => {
       props.onClickHandle();
    }

    return (
        <div className={"btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"}
             onClick={onClickEvent}>
            <div className={"align-items-center d-flex text-start"}>
                <i className={"material-icons add_button_icon"}>add_box</i>
            </div>
            <div className={"mx-1"}>
                {strings.add}
            </div>
        </div>
    );

}
export default AddButton;