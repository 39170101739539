import axios from "../../axios/axios";

const EthnicityService = {
    getAllEthnicity: () => {
        return axios.get("/ethnicity/getAll")

    },
    addEthnicity: (name, label_mk, label_sq, label_en) => {
        return axios.post('ethnicity/add', {
            name,
            label_mk,
            label_sq,
            label_en
        })
    },
    fetchEthnicities: (page, size) => {
        return axios.get(`/ethnicity/getAllPaginated/${page}/${size}`)
    },
    editEthnicity: (id, name, label_mk, label_sq, label_en) => {
        return axios.put(`/ethnicity/update`, {
            id,
            name,
            label_mk,
            label_sq,
            label_en
        })
    },
    deleteEthnicity: (id) => {
        return axios.delete(`/ethnicity/delete/${id}`)
    }
}
export default EthnicityService;