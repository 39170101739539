import React from "react";
import {Form, Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import {strings} from "../../../localization/Localization";
import OrganisationHierarchyService from "../../../repository/hierarchyRepository/organisationHierarchyRepository";

const OrganizationalHierarchyAddModal = (props) => {

    const [formData, updateFormData] = React.useState({
        hrmsStPositionsHId: "",
        activeFrom: "",
        activeTo: "",
        code: "",
        name: "",
        shortName: "",
        description: "",
        dateCreated: "",
        dateModified: "",
        parentId: ""
    });

    const onFormSubmit = (e) => {
        e.preventDefault();
        const hrmsStOrganisationsHId = formData.hrmsStOrganisationsHId !== "" ? formData.hrmsStOrganisationsHId : null;
        const activeFrom = formData.activeFrom !== "" ? formData.activeFrom : "2022-01-01";
        const activeTo = formData.activeTo !== "" ? formData.activeTo : "2050-01-01";
        const code = formData.code !== "" ? formData.code : null;
        const name = formData.name !== "" ? formData.name : formData.name;
        const description = formData.description !== "" ? formData.description : null;
        const shortName = formData.shortName !== "" ? formData.shortName : null;
        const dateCreated="";
        const dateModified="";
        addHierarchy(hrmsStOrganisationsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,props.selectedNode?.id)
    };

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    };

    const addHierarchy = (hrmsStOrganisationsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,parentOrganisationId) => {
        OrganisationHierarchyService.addHierarchy(hrmsStOrganisationsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,parentOrganisationId)
            .then((data) => {
                props.loadHierarchy(false);
                props.handleClose();
            });
    };

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose} size={"lg"} centered>
                <ModalHeader closeButton>
                    <ModalTitle><b>{strings.addNewHierarchy}</b></ModalTitle>
                </ModalHeader>
                <Form onSubmit={onFormSubmit}>
                    <ModalBody>
                        <Form.Group className="mb-3" controlId="hierarchyName">
                            <Form.Group>
                                <Form.Label>{strings.hierarchyName}</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    autoFocus
                                    name="name"/>
                            </Form.Group>
                        <Form.Group>
                            <Form.Label>{strings.hierarchyShortName}</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={handleChange}
                                name="shortName"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{strings.hierarchyDescription}</Form.Label>
                            <textarea name="description" className="form-control"
                                      onChange={handleChange}
                                      id="exampleFormControlTextarea1" rows="2"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{strings.hierarchyCode}</Form.Label>
                            <textarea name="code" className="form-control"
                                      onChange={handleChange}
                                      id="exampleFormControlTextarea1" rows="2"/>
                        </Form.Group>
                            </Form.Group>
                    </ModalBody>
                    <Modal.Footer>
                        <div className={"cancel btn text-white"} onClick={props.handleClose}>
                            {strings.cancel}
                        </div>
                        <div type={"submit"} className={"save btn text-white"} onClick={onFormSubmit}>
                            {strings.save}
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default OrganizationalHierarchyAddModal;