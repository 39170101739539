import React from "react"
import ColorLegend from "../../../components/ColorLegend/ColorLegend";
import {strings} from "../../../localization/Localization";

const ColorLegendWrapper = () => {

    const data = [
        {
            id:0,
            colorClass: "national_holiday_color",
            title: strings.nationalHoliday,
        },
        {
            id:1,
            colorClass: "religion_holiday_color",
            title: strings.religionHoliday,
        }, {
            id:2,
            colorClass: "ethnicity_holiday_color",
            title: strings.ethnicityHoliday,
        }, {
            id:3,
            colorClass: "religionAndNationalHoliday_holiday_color",
            title: strings.religionAndNationalHoliday,
        }
    ]

    return (
        <>
            <ColorLegend data={data}/>
        </>
    );
}
export default ColorLegendWrapper;