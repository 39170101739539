import { useEffect, useState, useRef } from 'react';
import swal from 'sweetalert';
import LeaveRequestRepository from '../../../../repository/leaveRequestRepository/LeaveRequestRepository';
import { Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from 'react-bootstrap';
import Select from 'react-select';
import LeaveTypeRepository from '../../../../repository/leaveTypeRepository/LeaveTypeRepository';
import { strings } from '../../../../localization/Localization';
import DatePicker from 'react-datepicker';
import BootstrapTable from 'react-bootstrap-table-next';
import React from 'react';
import './DeliveredRequestModal.css';
import LeaveReasonService from '../../../../repository/leaveReasonRepository/LeaveReasonRepository';
import 'material-icons/iconfont/material-icons.css';
import SpinnerComponent from '../../../../components/Spinner';
import ApplicationService from '../../../../ApplicationService';
import DeliveredRequestStatusManager from '../DeliveredRequestStatusMenager/DeliveredRequestStatusManager';
import UserListInModal from '../../../../components/UserListInModal';
import AuditActionLeaveRequest from '../../AuditActionLeaveRequest/AuditActionLeaveRequest';
import DraggableModalDialog from '../../../../components/DraggableModalDialog/DraggableModalDialog';
import LeaveRequestHistoryModal from '../../MyLeaveRequest/LeaveRequestModal/LeaveRequestHistoryModal';

const DeliveredRequestModal = (props) => {

  const [leaveType, setLeaveType] = useState([]);

  const [selectedLeaveType, setSelectedLeaveType] = useState({});

  const [spinnerShow, setShowSpinner] = useState(true);

  const [leaveReasons, setLeaveReasons] = useState([]);

  const [selectedLeaveReasons, setSelectedLeaveReasons] = useState({});

  const [dateValid, setDateValid] = useState(true);

  const [absenceDetails, setAbsenceDetails] = useState([]);

  const [absenceDetailsByReasons, setAbsenceDetailsByReasons] = useState([]);

  const [absenceDetailsByReasonsForCurrentYear, setAbsenceDetailsByReasonsForCurrentYear] = useState([]);

  const [allLeaveRequestStatus, setAllLeaveRequestStatus] = useState([]);

  const [selectedStatusAndDescriptionForResponse, setSelectedStatusAndDescriptionForResponse] = useState({
    description: '',
    responseStatus: ''
  });

  const [focusSubmitButton, setFocusSubmitButton] = useState(false);

  const ref = useRef(null);

  const [userAlreadyHaveSickLeaveRequest, setUserAlreadyHaveSickLeaveRequest] = useState(false);
  const [formData, setFormData] = useState({
    dateFrom: '',
    dateTo: '',
    totalDays: null,
    workDays: null,
    description: '',
    leaveType: '',
    leaveReasons: '',
  });

  const [parentLeaveRequest, setParentLeaveRequest] = useState({
    dateFrom: '',
    dateTo: '',
    totalDays: null,
    workDays: null,
    description: '',
    leaveType: '',
    leaveReasons: '',
    deputy: '',
    status: ''
  })

  const [isActive, setIsActive] = useState({
    decline: false,
    approve: false,
    returnForCorrection: false
  });

  const [acceptTermination, setAcceptTermination] = useState(false)
  const [declineTermination, setDeclineTermination] = useState(false)

  const setActiveStatus = (statusClicked) => {
    if (statusClicked === 'approve') {
      setIsActive({
        decline: false,
        approve: true,
        returnForCorrection: false
      });
    } else if (statusClicked === 'returnForCorrection') {
      setIsActive({
        decline: false,
        approve: false,
        returnForCorrection: true
      });
    } else if (statusClicked === 'decline') {
      setIsActive({
        decline: true,
        approve: false,
        returnForCorrection: false
      });
    }
  };

  useEffect(() => {
    if (props.selectedLeaveRequestForEdit.parentId !== null) {
      LeaveRequestRepository.getLeaveRequestById(props.selectedLeaveRequestForEdit.parentId).then((data) => {
        setParentLeaveRequest({
          dateFrom: data.data.dateFrom,
          dateTo: data.data.dateTo,
          totalDays: data.data.totalDays,
          workDays: data.data.calculatedWorkDays,
          description: data.data.description,
          leaveType: data.data.leaveType,
          leaveReasons: data.data.leaveReason,
          deputy: data.data.deputy,
          status: data.data.leaveRequestStatus
        })
      });
    }else{
      setParentLeaveRequest({})
    }
  }, [props.selectedLeaveRequestForEdit]);



  useEffect(() => {
    if (props.showModal) {
      setUserAlreadyHaveSickLeaveRequest(false);
      getAllLeaveType();
      getLeaveStatus();
    } else {
      setDeclineTermination(false);
      setAcceptTermination(false);
      setUserAlreadyHaveSickLeaveRequest(false);
      setSelectedLeaveType({});
      setSelectedLeaveReasons({});
      setAbsenceDetailsByReasons([]);
      setSelectedStatusAndDescriptionForResponse({
        description: '',
        responseStatus: ''
      });
      resetFormDate();
    }
  }, [props.showModal]);

  useEffect(() => {
    if (props.selectedLeaveRequestForEdit.id) {
      setSelectedLeaveReasons({
        value: props.selectedLeaveRequestForEdit.leaveReason.id,
        label: props.selectedLeaveRequestForEdit.leaveReason.name
      });
      setSelectedLeaveType({
        value: props.selectedLeaveRequestForEdit.leaveType.id,
        label: props.selectedLeaveRequestForEdit.leaveType.name
      });
    }
  }, [props.selectedLeaveRequestForEdit]);

  useEffect(() => {
    calculateTotalLeaveDays();
  }, [formData.dateFrom, formData.dateTo]);

  useEffect(() => {
    if (props.showModal) {
      getAllAbsencesByLoggedUser();
    }
  }, []);

  useEffect(() => {
    const formattedDateFrom = formData.dateFrom == '' ? new Date().toISOString().split('T')[0] : formData.dateFrom.toISOString().split('T')[0];
    if (selectedLeaveReasons.value) {
      LeaveReasonService.getAllowDayAndRemainingDayForReasonsByUserId(selectedLeaveReasons.value, props.selectedLeaveRequestForEdit.user.id, formattedDateFrom )
        .then((data) => {
          setAbsenceDetailsByReasons(data.data);
        });
    }
  }, [selectedLeaveReasons]);

  useEffect(() => {
    if (selectedLeaveType.value || props.selectedLeaveRequestForEdit.id) {
      if (selectedLeaveType.value) {
        if (formData.leaveType !== '') {
          setSelectedLeaveReasons({});
        }
        getLeaveReasons(selectedLeaveType.value);
      } else if (props.selectedLeaveRequestForEdit.id) {
        getLeaveReasons(props.selectedLeaveRequestForEdit.leaveType.id);
      }
    }
  }, [selectedLeaveType, props.selectedLeaveRequestForEdit]);

  useEffect(() => {
    let year = new Date().getFullYear();
    if (absenceDetailsByReasons.filter((r) => r.forYear === year).length === 0) {
      year = year - 1;
    }

    setAbsenceDetailsByReasonsForCurrentYear(absenceDetailsByReasons.filter((r) => r.forYear === year));
  }, [absenceDetailsByReasons]);

  useEffect(() => {
    if (selectedStatusAndDescriptionForResponse.responseStatus === 'APPROVED') {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      setFocusSubmitButton(true);
    } else {
      setFocusSubmitButton(false);
    }
  }, [selectedStatusAndDescriptionForResponse]);

  const resetFormDate = () => {
    setFormData({
      dateFrom: '',
      dateTo: '',
      totalDays: null,
      workDays: null,
      description: '',
      leaveType: '',
      leaveReasons: '',
    });
    // setResponseDescription(null)
  };

  const calculateTotalLeaveDays = () => {
    if (!formData.dateTo && !props.selectedLeaveRequestForEdit.id) {
      return;
    }
    setShowSpinner(false);
    let date1 = null;
    if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === '') {
      date1 = new Date(props.selectedLeaveRequestForEdit.dateFrom);
    } else if (formData.dateFrom !== '') {
      date1 = new Date(formData.dateFrom);
    } else {
      date1 = new Date();
    }
    let date2 = null;
    if (props.selectedLeaveRequestForEdit.id && formData.dateTo === '') {
      date2 = new Date(props.selectedLeaveRequestForEdit.dateTo);
    } else {
      date2 = new Date(formData.dateTo.getFullYear(), formData.dateTo.getMonth(), formData.dateTo.getDate() + 1);
    }

    LeaveRequestRepository.getCalculateTotalLeaveDays(date1, date2)
      .then((data) => {
        setFormData({
          ...formData,
          totalDays: data.data
        });
      }).catch(() => {
      setFormData({
        ...formData,
        totalDays: ''
      });
    });
    setShowSpinner(true);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()

    });
  };

  const handleDateFromChange = (date) => {
    if (formData.dateTo != '') {
      checkDateValues(date, formData.dateTo);
    }
    setFormData({
      ...formData,
      dateFrom: date
    });
  };

  const handleDateToChange = (date) => {
    if (formData.dateFrom !== '') {
      checkDateValues(formData.dateFrom, date);
    }
    setFormData({
      ...formData,
      dateTo: date
    });
  };

  const checkDateValues = (from, to) => {
    let dateFrom = new Date(from);
    let dateTo = new Date(to);
    if (dateFrom > dateTo) {
      setDateValid(false);
    } else {
      setDateValid(true);
    }
  };

  const selectLeaveType = (e) => {
    setFormData({
      ...formData,
      leaveType: leaveType.find(el => el.id === e.value)
    });
    setSelectedLeaveType({
      value: e.value,
      label: e.label
    });
  };

  const selectLeaveReasons = (e) => {
    setFormData({
      ...formData,
      leaveReasons: leaveReasons.find(el => el.id === e.value)
    });
    setSelectedLeaveReasons({
      value: e.value,
      label: e.label
    });
  };

  const successfulAlert = () => {
    swal(strings.responseIsSent, {
      icon: 'success',
    });
  };

  const errorAlert = (errorText) => {
    swal({
      title: strings.oops,
      text: errorText,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
    });
  };

  const warrningAlert = () => {
    swal({
      title: strings.required,
      text: strings.enterApprovalStatusRequired,
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const getAllAbsencesByLoggedUser = () => {
    LeaveRequestRepository.getAllAbsencesByLoggedUser().then((days) => {
      setAbsenceDetails(days.data);
    });
  };

  const getAllLeaveType = () => {
    LeaveTypeRepository.getAllLeaveType().then((data) => {
      setLeaveType(data.data);
      // setSelectedLeaveType({value: data.data[0].id, label: data.data[0].name});
    });
  };

  const getLeaveReasons = (id) => {
    LeaveReasonService.getAllLeaveReasonsByLeaveTypeId(id)
      .then((data) => {
        setLeaveReasons(data.data);
      });
  };

  const getLeaveStatus = () => {
    LeaveRequestRepository.getAllLeaveRequestStatus()
      .then((data) => {
        setAllLeaveRequestStatus(data.data);
      });
  };

  const onFormSubmit = (e) => {
    setShowSpinner(false);
    let statusId = allLeaveRequestStatus.filter((r) => r.name === selectedStatusAndDescriptionForResponse.responseStatus);
    if (selectedStatusAndDescriptionForResponse.responseStatus === '') {
      setShowSpinner(true);
      warrningAlert();
    } else {
      if ((selectedStatusAndDescriptionForResponse.responseStatus === 'RETURNED FOR CORRECTION' || selectedStatusAndDescriptionForResponse.responseStatus === 'DECLINED') && (parentLeaveRequest.status?.name !== 'TERMINATED') && selectedStatusAndDescriptionForResponse.description === '') {
        setShowSpinner(true);
        warrningAlert();
      } else {
        statusId = statusId[0].id;
        LeaveRequestRepository.sentResponseForRequest(props.selectedLeaveRequestForEdit.user.id, props.selectedLeaveRequestForEdit.id, selectedStatusAndDescriptionForResponse.description, statusId)
          .then((data) => {
            setShowSpinner(true);
            successfulAlert();
            props.getAllOtherLeaveRequest();
            props.getAllNewLeaveRequest();
            props.handleClose();
          })
          .catch((error) => {
            let errorText = '';
            if (error.response.data == 'DuplicateLeaveRequestException') {
              errorText = strings.duplicateLeaveRequestException;
            } else if (error.response.data == 'No available days') {
              errorText = strings.noAvailableDays;
            } else if (error.response.data == 'No available days for this year') {
              errorText = strings.noAvailableDaysForThisYear;
            } else if (error.response.data == 'Already absent') {
              errorText = strings.alreadyHaveSickLeaveDuring;
            } else if (error.response.data == 'User already absent') {
              setUserAlreadyHaveSickLeaveRequest(true);
              errorText = strings.userAlreadyHaveSickLeaveDuring;
            } else {
              errorText = strings.somethingWrong;
            }
            setShowSpinner(true);
            errorAlert(errorText);
          });
      }
    }

  };

  const columns = [
    {
      dataField: 'forYear',
      text: strings.forYear,
    },
    {
      dataField: 'allowedDays',
      text: strings.allowedDays,
    },
    {
      dataField: 'remainingDays',
      text: strings.remainingDays
    }
  ];

  const getClassStyleForStatus = (status) => {
    if (status != null) {
      if (status == 'NEW') {
        return 'new_notification';
      } else if (status == 'DECLINED') {
        return ' declined_notification';
      } else if (status == 'APPROVED') {
        return 'approved_notification';
      } else if (status == 'RETURNED FOR CORRECTION') {
        return 'returned_notification';
      } else if (status == 'CORRECTED') {
        return 'corrected_notification';
      } else if (status == 'BETWEEN APPROVALS') {
        return 'between_notification';
      } else if (status === 'RETURNED_FOR_CORRECTION_SECOND_APPROVAL') {
        return 'returned_notification';
      } else if (status === 'CORRECTED_SECOND_APPROVAL') {
        return 'corrected_notification';
      } else if (status === 'REROUTED_HR') {
        return 'rerouted_notification';
      } else if (status === 'REROUTED') {
        return 'rerouted_notification';
      } else if (status === 'CORRECTED_SECOND_APPROVAL') {
        return 'between_notification';
      } else if (status == 'RETURNED_FOR_CORRECTION_HR') {
        return 'returned_notification';
      } else if (status == 'CORRECTED_HR') {
        return 'corrected_notification';
      } else if (status == 'DECLINED_HR') {
        return ' declined_notification';
      } else if (status == 'DELETED') {
        return 'text-danger';
      } else if (status == 'STOPPED') {
        return 'text-danger';
      } else if (status == 'EXPIRED') {
        return 'text-danger';
      }
    }
  };

  const getDateForFormDateCalendar = () => {
    if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === '') {
      return new Date(props.selectedLeaveRequestForEdit.dateFrom);
    } else if (formData.dateFrom !== '') {
      return formData.dateFrom;
    } else {
      return new Date();
    }
  };

  const getDateForDateToCalendar = () => {
    if (props.selectedLeaveRequestForEdit.id && formData.dateTo === '') {
      return new Date(props.selectedLeaveRequestForEdit.dateTo);
    } else if (formData.dateTo !== '') {
      return formData.dateTo;
    } else {
      return '';
    }
  };

  const handleAcceptTerminationRequest = () => {
    setActiveStatus('approve');
    setAcceptTermination(true);
    setDeclineTermination(false);
    setSelectedStatusAndDescriptionForResponse({
      description: '',
      responseStatus: 'APPROVED'
  });
  };

  const handleDeclineTerminationRequest = () => {
    setActiveStatus('decline');
    setAcceptTermination(false);
    setDeclineTermination(true);
    setSelectedStatusAndDescriptionForResponse({
      description: '',
      responseStatus: 'DECLINED'
    });
  };

  const getLeaveRequestStatusMK = (leaveRequest) => {
    if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.childStatus == 'DECLINED' && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"approved_notification"}>{strings.approved_after_declined_termination}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.childStatus == 'DECLINED' && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
    }else if (leaveRequest.leaveRequestStatus.name == "NEW" && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"new_notification"}>{strings.correction_request}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"approved_notification"}>{strings.approved_correction}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"text-danger"}>Терминирано</span>
    } else if (leaveRequest.leaveRequestStatus.name == "NEW" && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"new_notification"}>{strings.termination_request}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.childStatus == 'DECLINED' && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "BETWEEN APPROVALS" && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"new_notification"}>{strings.termination_request}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "BETWEEN APPROVALS" && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"new_notification"}>{strings.correction_request}</span>
    }else {
      return leaveRequest.leaveRequestStatus.label_mk;
    }
  }

  const getLeaveRequestStatusEN = (leaveRequest) => {
    if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.childStatus == 'DECLINED' && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"approved_notification"}>{strings.approved_after_declined_termination}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.childStatus == 'DECLINED' && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
    }else if (leaveRequest.leaveRequestStatus.name == "NEW" && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"new_notification"}>{strings.correction_request}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"approved_notification"}>{strings.approved_correction}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"text-danger"}>Терминирано</span>
    } else if (leaveRequest.leaveRequestStatus.name == "NEW" && leaveRequest.terminationOrCorrection == 'TERMINATED') {
      return <span className={"new_notification"}>{strings.termination_request}</span>
    } else if (leaveRequest.leaveRequestStatus.name == "APPROVED" && leaveRequest.childStatus == 'DECLINED' && leaveRequest.terminationOrCorrection == 'CANCELLED_FOR_CORRECTION') {
      return <span className={"approved_notification"}>{strings.approved_after_declined_correction}</span>
    } else {
      return leaveRequest.leaveRequestStatus.label_en;
    }
  }

  return (
    <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose}
           dialogClassName={`${props.selectedLeaveRequestForEdit.parentId && (parentLeaveRequest.status?.name !== 'TERMINATED') ? 'size-dialog-extra-large' : ''}`} size={(props.selectedLeaveRequestForEdit.parentId == null || (parentLeaveRequest.status?.name === 'TERMINATED')) && 'xl'} centered>
    <ModalHeader closeButton>
        {props.selectedLeaveRequestForEdit.id ? (
          <ModalTitle>
            <div className={'d-flex '}>
              <div>{strings.editLeaveRequest}</div>
              <div className={'status-position-end d-flex text-center align-items-center ' + getClassStyleForStatus(props.selectedLeaveRequestForEdit.leaveRequestStatus?.name)}>
                {ApplicationService.getActiveLanguage() === 'mk' ? (getLeaveRequestStatusMK(props.selectedLeaveRequestForEdit)) : (getLeaveRequestStatusEN(props.selectedLeaveRequestForEdit))}
              </div>
            </div>
          </ModalTitle>
        ) : (
          <ModalTitle><b>{strings.requestALeave}</b></ModalTitle>
        )
        }
      </ModalHeader>
      <Form>
        <ModalBody className={'row'}>
          {props.selectedLeaveRequestForEdit.id && parentLeaveRequest.dateFrom && (parentLeaveRequest.status?.name !== 'TERMINATED') && <LeaveRequestHistoryModal leaveRequest={props.selectedLeaveRequestForEdit} parentLeaveRequest={parentLeaveRequest}/>}
          <div className={props.selectedLeaveRequestForEdit.parentId && (parentLeaveRequest.status?.name !== 'TERMINATED') && 'col-xl-6 col-md-12 p-4'}>
          <Form.Group className='row mb-3' controlId='date'>
            {parentLeaveRequest.status?.name === 'TERMINATED' ?
              <>
                {props.selectedLeaveRequestForEdit.id && ((props.selectedLeaveRequestForEdit.leaveRequestStatus.name === 'NEW') || (props.selectedLeaveRequestForEdit.leaveRequestStatus.name === 'BETWEEN APPROVALS')) && (props.selectedLeaveRequestForEdit.terminationOrCorrection == 'TERMINATED') && (parentLeaveRequest.status?.name === 'TERMINATED') && (parentLeaveRequest.status?.name === 'TERMINATED') &&
                  <>
                  <h1 className={'text-center mb-4 mt-3'}>Побарана е <span className={'text-danger'} style={{fontWeight: 'bold'}}>терминација</span> за барањето</h1>
                    <Form.Group className='row mb-3' controlId='date'>
                      <div className={'d-flex justify-content-center mx-auto mt-4 mb-2 w-50'}>
                        <div>
                          <a style={{width: '350px', marginRight: 10, marginLeft: 25}}
                             onClick={handleAcceptTerminationRequest}
                             className={'btn button-approve ' + (acceptTermination ? 'active-button active-button-approve' : '')}>
                            {strings.acceptTerminationRequest}
                          </a>
                        </div>
                        <div>
                          <a style={{width: '350px', marginLeft: 10}}
                             onClick={handleDeclineTerminationRequest}
                             className={'btn button-decline ' + (declineTermination ? 'active-button active-button-decline' : '')}
                          >{strings.declineTerminationRequest}
                          </a>
                        </div>
                      </div>
                    </Form.Group>
                  </>
                }
              </>
              :
              <>
                {props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'APPROVED' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'DECLINED' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'DECLINED_HR' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'RETURNED FOR CORRECTION' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'RETURNED_FOR_CORRECTION_SECOND_APPROVAL' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'RETURNED_FOR_CORRECTION_HR' &&
                  <DeliveredRequestStatusManager
                    parentRequest={parentLeaveRequest}
                    leaveRequest={props.selectedLeaveRequestForEdit}
                    setSelectedStatusAndDescriptionForResponse={setSelectedStatusAndDescriptionForResponse}
                    selectedStatusAndDescriptionForResponse={selectedStatusAndDescriptionForResponse}
                    userAlreadyHaveSickLeaveRequest={userAlreadyHaveSickLeaveRequest}/>
                }
              </>
            }

              {props.selectedLeaveRequestForEdit.id &&
                <Form.Group className={'mt-3'}>
                  <UserListInModal users={[props.selectedLeaveRequestForEdit.user]}></UserListInModal>
                </Form.Group>
              }

            <Form.Group className='col-6'>
              <Form.Label>{strings.dateFrom}</Form.Label>
              <DatePicker
                className='react-datepicker'
                disabled={true}
                selected={
                  getDateForFormDateCalendar()
                }
                onChange={(date) => handleDateFromChange(date)}
                dateFormat='dd.MM.yyyy'
              />
            </Form.Group>
            <Form.Group className='col-6'>
              <Form.Label>{strings.dateTo}</Form.Label>
              <DatePicker
                className='react-datepicker'
                disabled={true}
                selected={getDateForDateToCalendar()}
                onChange={(date) => handleDateToChange(date)}
                dateFormat='dd.MM.yyyy'
              />
            </Form.Group>
          </Form.Group>
          {dateValid ? null :
            <div className='alert alert-danger row-cols-6 mt-2'
                 role='alert'>{strings.enterValidateDate}</div>}
          <Form.Group className={'row'}>
            <Form.Group className='col-6 mb-3' controlId='workDays'>
              <Form.Label>{strings.workingDays}</Form.Label>
              <Form.Control
                type='number'
                placeholder={strings.day}
                disabled={true}
                autoFocus
                value={props.selectedLeaveRequestForEdit.correctedWorkDays}
                // defaultValue={props.selectedLeaveRequestForEdit.correctedWorkDays}
                readOnly={true}
                onChange={handleChange}
                name='workDays'
                // value={formData.workDays}
                className='mb-3'/>

            </Form.Group>
            <Form.Group className='col-6 mb-3' controlId='workDays'>
              <Form.Label>{strings.totalDays}</Form.Label>
              <Form.Control
                type='number'
                placeholder={strings.day}
                disabled={true}
                autoFocus
                value={props.selectedLeaveRequestForEdit.totalDays}
                // defaultValue={props.selectedLeaveRequestForEdit.totalDays}
                readOnly={true}
                onChange={handleChange}
                name='totalDays'
                // value={formData.totalDays}
                className='mb-3'/>

            </Form.Group>
          </Form.Group>
          <Form.Group className='mb-3 justifyContent' controlId='description'>
            <Form.Label>{strings.description}</Form.Label>
            <Form.Control
              type='text'
              placeholder={strings.enterDescription}
              disabled={true}
              defaultValue={props.selectedLeaveRequestForEdit.description}
              autoFocus
              required
              onChange={handleChange}
              name='description'
            />
          </Form.Group>
          <Row>
            <Form.Group className='col-5 mb-3'>
              <Form.Label>{strings.doYouWantToSelectADeputy}</Form.Label>
              <div className={'d-flex ms-2'}>
                <div className={'me-2'}>
                  <input type='radio' value='yes' name='deputy'
                         defaultChecked={props.selectedLeaveRequestForEdit.deputy?.id}
                         checked={props.selectedLeaveRequestForEdit.deputy?.id}
                         disabled={true}
                    // onChange={handleDeputySelect}
                  /> {strings.yes}
                </div>
                <div>
                  <input type='radio'
                    // onChange={handleDeputySelect}
                         defaultChecked={props.selectedLeaveRequestForEdit.deputy?.id == null}
                         checked={props.selectedLeaveRequestForEdit.deputy?.id == null}
                         value='no'
                         disabled={true}
                         name='deputy'/> {strings.no}
                </div>
              </div>
            </Form.Group>
            {props.selectedLeaveRequestForEdit.deputy &&
              <Form.Group className='col-7 mb-3'>
                <Form.Label>{strings.selectDeputy}</Form.Label>
                <Select required
                        name='deputies'
                        noOptionsMessage={() => strings.noOptions}
                        options={
                          {
                            value: props.selectedLeaveRequestForEdit.deputy.id,
                            label: props.selectedLeaveRequestForEdit.deputy.firstName + ' ' + props.selectedLeaveRequestForEdit.deputy.lastName
                          }
                        }
                        isDisabled={true}
                  // onChange={selectedDeputyChange}
                        value={{
                          value: props.selectedLeaveRequestForEdit.deputy.id,
                          label: props.selectedLeaveRequestForEdit.deputy.firstName + ' ' + props.selectedLeaveRequestForEdit.deputy.lastName
                        }
                        }
                />
              </Form.Group>
            }
          </Row>
          <Form.Label>{strings.leaveType}</Form.Label>
          {
            selectedLeaveType && leaveType.length > 0 &&
            <Select required
                    name='leaveType'
                    isDisabled={true}
                    noOptionsMessage={() => strings.noOptions}
                    options={
                      leaveType?.map((item) => (
                        {
                          value: item.id,
                          label: item.labelMk
                        }
                      ))}
                    onChange={selectLeaveType}
                    value={selectedLeaveType}
            />
          }
          {(props.selectedLeaveRequestForEdit.id || selectedLeaveType.value && leaveReasons.length > 0) &&
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className='mt-3 justifyContent' controlId='reasons'>
                  <Form.Label>{strings.reasonsForLeaveRequest}</Form.Label>
                  <Select required
                          name='leaveReasons'
                          isDisabled={true}
                          noOptionsMessage={() => strings.noOptions}
                          options={
                            leaveReasons?.map((item) => (
                              {
                                value: item.id,
                                label: item.name
                              }
                            ))}
                          onChange={selectLeaveReasons}
                          value={selectedLeaveReasons}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className='mt-4 d-flex text-align-end justify-content-end'
                            controlId='dayLeft'>
                  <Form.Group className={'d-flex justify-content-end align-items-end'}>
                    <Form.Group className={'text-center'}>
                      <Form.Label
                        className={'justify-content-center d-flex'}>{strings.allowedDays}:</Form.Label>
                      <Form.Control className={'w-50 h-100 mx-auto'}
                                    type='text'
                                    autoFocus
                                    disabled={true}
                                    required
                                    value={absenceDetailsByReasonsForCurrentYear[0]?.allowedDays}
                                    readOnly={true}
                                    onChange={handleChange}
                                    name='description'
                      />
                    </Form.Group>
                    <Form.Group className={'text-center '}>
                      <Form.Label
                        className={'justify-content-center d-flex'}>{strings.remainingDays}:</Form.Label>
                      <Form.Group className={'d-inline-flex justify-content-center'}>
                        <Form.Control className={'w-50 h-100'}
                                      type='text'
                                      autoFocus
                                      disabled={true}
                                      required
                                      value={absenceDetailsByReasonsForCurrentYear[0]?.remainingDays}
                                      readOnly={true}
                                      onChange={handleChange}
                                      name='description'
                        />
                        {/*<div className={'d-inline-flex mx-3 my-2 text-center justify-content-center'}>*/}
                        {absenceDetailsByReasonsForCurrentYear[0]?.remainingDays >= props.selectedLeaveRequestForEdit.correctedWorkDays ?
                          (
                            <i className='material-icons mx-1 align-items-center d-flex text-success'>check_circle</i>
                          ) : (
                            <i className='material-icons mx-1 align-items-center d-flex text-danger '>cancel</i>
                          )
                        }
                        {/*Имате преостанато денови.*/}
                        {/*</div>*/}
                      </Form.Group>
                    </Form.Group>
                    {/*<Form.Group>*/}
                    {/*    <Form.Label className={"justify-content-center d-flex"}>Преостанато денови:</Form.Label>*/}
                    {/*    <Form.Control className={"w-25 h-100"}*/}
                    {/*                  type="text"*/}
                    {/*                  autoFocus*/}
                    {/*                  required*/}
                    {/*                  onChange={handleChange}*/}
                    {/*                  name="description"*/}
                    {/*    />*/}
                    {/*</Form.Group>*/}

                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>
          }
          <h2 className='mt-5 mb-5 remaining-days-header'><span>{strings.remainingDays}</span></h2>
          <BootstrapTable
            keyField='id'
            data={absenceDetailsByReasons}
            columns={columns}
            bordered={true}
            hover={false}
            headerClasses={'headerStyleModal remainingDaysTable'}
            wrapperClasses={'text-center '}
            bodyClasses={'bodyStyleModal'}/>
          <AuditActionLeaveRequest leaveRequestId={props.selectedLeaveRequestForEdit.id} showModal={props.showModal}/>
          </div>
        </ModalBody>
        <ModalFooter>
          {props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'APPROVED' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'DECLINED' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'DECLINED_HR' &&props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'RETURNED FOR CORRECTION' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'RETURNED_FOR_CORRECTION_SECOND_APPROVAL' && props.selectedLeaveRequestForEdit?.leaveRequestStatus?.name !== 'RETURNED_FOR_CORRECTION_HR' &&
            <div type={'button'}
                 className={`save btn text-white`}
                 ref={ref} onClick={onFormSubmit} autofocus={true}>
              {strings.responseOnRequest}
            </div>
          }
        </ModalFooter>
      </Form>
      <SpinnerComponent spinnerShow={spinnerShow}/>
    </Modal>
  );
};
export default DeliveredRequestModal;