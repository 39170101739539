import decode from "jwt-decode";
import SettingsRepository from "./repository/settingsRepository/SettingsRepository";

const ApplicationService = {

    hasRole: (authorizedRoles) => {

        const token = localStorage.getItem('auth_token');
        if (!token) {
            return false;
        }
        let userRoles = decode(token).authority
        const isAuthorized = authorizedRoles.filter((role) => userRoles.includes(role));
        return isAuthorized.length > 0;
    },
    getActiveLanguage: () => {
        const activeLanguage = localStorage.getItem('activeLanguage');
        return activeLanguage;
    },
    hasSyncEnabled: async () => {
        return   await SettingsRepository.getEditableHierarchy().then((data) => {
            return data.data.value === "1"
        })

    }
}
export default ApplicationService;