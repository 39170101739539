export const mk_strings = {
  signIn: 'Најава',
  email: 'Е-пошта',
  password: 'Лозинка',
  profile: 'Профил',
  logOut: 'Одјава',
  name: 'Име',
  lastName: 'Презиме',
  fatherName: 'Татково име',
  phoneNumber: 'Мобилен број',
  address: 'Адреса',
  roles: 'Улога/и',
  calendar: 'Календар',
  week: 'Седмица',
  month: 'Месец',
  addevent: 'Додади настан',
  dateselected: 'Селектиран датум',
  firstName: 'Име',
  UserList: 'Листа на корисници',
  addNewUser: 'Додади нов корисник',
  editUser: 'Промени корисник',
  close: 'Затвори',
  cancel: 'Откажи',
  save: 'Зачувај',
  back: 'Вратете се назад',
  register: 'Регистрација',
  forgotPassword: 'Заборавена лозинка?',
  resetPassword: 'Промени лозинка',
  saveProfile: 'Зачувај Профил',
  ethnicityList: 'Етнички припадности',
  ethnicityName: 'Eтничка припадност',
  labelMk: 'Македонски',
  labelEn: 'Англиски',
  labelSq: 'Албански',
  addNewEthnicity: 'Додади етничка припадност',
  editEthnicity: 'Измени етничка припадност',
  registrationFailed: 'Регистрацијата е неуспешна, обидете се повторно',
  registrationSuccessful: 'Регистрацијата е успешна, кликнете овде за да бидете пренасочени назад на страницата за најавa',
  backToLogin: 'Назад до страната за логирање',
  accessRestrictedText: 'Страницата на која се обидувате да пристапите има ограничен пристап.',
  referToSystemAdministrator: 'Ве молиме обратете се на вашиот системски администратор',
  wrongCredentials: 'Погрешено корисничко име или лозинка!',
  organizationalUnit: 'Сектори',
  religionList: 'Религии',
  addNewReligion: 'Додадете нова религија',
  editReligion: 'Изменете религија',
  minimizeThisMenu: 'Минимизирајте мени',
  administration: 'Администрација',
  users: 'Корисници',
  religion: 'Религија',
  ethnicity: 'Етничка припадност',
  organisation: 'Организации',
  leaveRequest: 'Барања за отсуство',
  dateFrom: 'Датум од',
  dateTo: 'Датум до',
  totalDays: 'Календарски денови',
  correctedWorkDays: 'Работни денови',
  leaveType: 'Тип на отсуство',
  leaveRequestStatus: 'Статус',
  requestALeave: 'Пополни барање за отсуство',
  description: 'Опис',
  day: 'Внеси број на денови',
  sendRequest: 'Испрати барање',
  january: 'Јануари',
  february: 'Февруари',
  march: 'Март',
  april: 'Април',
  may: 'Мај',
  june: 'Јуни',
  july: 'Јули',
  august: 'Август',
  september: 'Септември',
  october: 'Октомври',
  november: 'Ноември',
  december: 'Декември',
  sunday: 'Недела',
  monday: 'Понеделник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четврток',
  friday: 'Петок',
  saturday: 'Сабота',
  addHoliday: 'Додади Празник',
  editHoliday: 'Измени Празник',
  delete: 'Избриши',
  holiday: 'Празник',
  holidays: 'Празници',
  calendarOfHolidays: 'Календар на празници',
  evidention: 'Евиденција',
  userevidention: 'Евиденција на вработени',
  absenceCalendar: 'Календар на отсуства',
  noHolidaysForSelectedDate: 'Нема празници за избраниот датум',
  statuscalendar: 'Календар со статуси',
  EmployeeList: 'Евиденција на вработени',
  remainingDays: 'Преостанати денови',
  forYear: 'Година',
  allowedDays: 'Дозволени денови',
  successfulReligion: 'Религијата успешно е додадена!',
  somethingWrong: 'Настана проблем при извршување на посакуваната акција!',
  oops: 'Грешка...',
  fileIsSafe: 'Нема никаква промена!',
  deleteConfirmation: 'Дали навистина сакате да избришете?',
  deleteConfirmationNoQuestionMark: 'Дали навистина сакате да го избришете корисникот:',
  areYouSure: 'Дали сте сигурни?',
  deletedForever: 'Ќе биде избришана засекогаш.',
  filter: 'Филтер',
  noAbsenceForSelectedDate: 'Нема отсутни за избраниот датум',
  absence: 'Отсуства',
  type: 'Тип',
  nationalType: 'Национален тип',
  religionType: 'Религиски тип',
  ethnicityType: 'Етнички тип',
  home: 'Почетна страна',
  calendarHistory: 'Историја на календари',
  weAreSorry: 'Недозволен пристап...',
  userForHoliday: 'Корисници за избраниот празник',
  otherUser: 'Останати корисници',
  noUserForSelectedHoliday: 'Нема корисници за избраниот празник',
  noOtherUser: 'Нема останати корисници',
  parentName: 'Име на родител',
  yes: 'Да',
  no: 'Не',
  holidayRecurring: 'Празникот се повторува секоја година',
  recentActivity: 'Неодамнешни новости',
  enterDescription: 'Внеси Опис...',
  tasks: 'Задачи',
  organizationalHierarchy: 'Организации',
  hierarchy: 'Хиерархија',
  selectedHierarchy: 'Селектирана хиерархија',
  selectedItemHierarchy: 'Информација за селектираната хиерархија',
  enterValidateDate: 'Внесете валиден датум',
  reasonsForLeaveRequest: 'Причина за отсуство',
  add: 'Додади',
  edit: 'Уреди',
  copyCalendar: 'Копирај календар',
  editLeaveRequest: 'Измени барање за отсуство',
  approveRequest: 'Прифати барање',
  returnRequest: 'Врати на корекција',
  declineRequest: 'Одбиј барање',
  terminateRequest: 'Терминирај барање',
  acceptTerminationRequest: 'Потврди терминација',
  declineTerminationRequest: 'Одбиј терминација',
  cancelForCorrectionRequest: 'Корегирај барање',
  responseDescription: 'Опис на одговорот',
  myRequest: 'Мои барања',
  deliverRequest: 'Доставени барања',
  downloadFile: 'Преземи фајл',
  enterDate: 'Внесете датум',
  year: 'Година',
  download: 'Преземи',
  saveChange: 'Зачувај промени',
  responseOnRequest: 'Одговори на барање',
  responseIsSent: 'Одговорот е пратен',
  fileDownloaded: 'Документот е успешно преземен',
  shortName: 'Скратено име',
  activeFrom: 'Активен од',
  activeTo: 'Активен до',
  unknown: 'Непознато',
  created: 'Креирано',
  modified: 'Последна промена',
  code: 'Код',
  hierarchyName: 'Име на хиерархијата',
  hierarchyDescription: 'Опис на хиерархијата',
  hierarchyCode: 'Код за хиерархијата',
  hierarchyShortName: 'Скратено име за хиерархијата',
  addNewHierarchy: 'Додади нова хиерархија',
  editHierarchy: 'Уреди хиерархија',
  leaveRequests: 'Барања за отсуство',
  newRequests: 'Нови барања',
  otherRequests: 'Останати барања',
  size: 'Големина',
  search: 'Пребарај',
  remove: 'Отстрани',
  workingDays: 'Работни денови',
  positionHierarchy: 'Хиерархија на позиции',
  noMemberInPosition: 'Нема припадници во позицијата',
  emptyList: 'Листата е празна',
  membersOfThePosition: 'Припадници во позицијата',
  addMembers: 'Додади припадници',
  allowedAbsence: 'Дозволени отсуства',
  usedDays: 'Искористени денови',
  allowedAbsenceReview: 'Преглед на дозволени отсуства',
  vacation: 'Годишен одмор',
  absenceByReasons: 'Отсуства според причина',
  hrmsIdNumber: 'Идентификациски број',
  organisationHierarchy: 'Хиерархија на организации',
  fromYear: 'Година од',
  toYear: 'Година до',
  editMember: 'Измени припадници',
  requestsAwaitingResponse: 'Барања кои што чекаат одговор',
  currentYear: 'Тековна година',
  remainingYears: 'Преостанати години',
  addInitialHierarchy: 'Додади почетна хиерархија',
  selectHierarchy: 'Селектирај хиерархија',
  usersToAddToPosition: 'Припадници за додавање во позиција',
  usersToRemoveFromPosition: 'Припадници за отстранување од позиција',
  editMemberInHierarchy: 'Измени припадници во хиерархија',
  confirmRemoveUserFromHierarchy: 'Дали навистина сакате да отстраните ',
  confirmRemoveUserFromHierarchyMemberNumber: 'припадници од хиерархијата?',
  confirmAddUserToHierarchy: 'Дали навистина сакате да додадете ',
  confirmAddUserToHierarchyMemberNumber: 'припадници во хиерархијата?',
  theMailFurtherUsedForLoggingIntoTheSystem: 'Мејлот понатаму ќе се користи за најава во системот!',
  nationalHoliday: 'Национален Празник',
  ethnicityHoliday: 'Етнички празник',
  religionHoliday: 'Религиски празник',
  religionAndNationalHoliday: 'Религиски и национален празник',
  removeMembers: 'Отстрани припадници',
  confirmAddUserToHoliday: 'Дали навистина сакате да додадете ',
  confirmAddUserToHolidayMemberNumber: ' припадници на празникот?',
  confirmRemoveUserFromHoliday: 'Дали навистина сакате да отстраните ',
  confirmRemoveUserFromHolidayMemberNumber: 'припадници од празникот?',
  absencesByHoliday: 'Отсуства според празник',
  notifications: 'Нотификации',
  position: 'Позиција',
  positions: 'Позиции',
  organization: 'Организација',
  absencesByDayOff: 'Отсуства според слободен ден',
  viewRemainingAbsence: 'Преглед на преостанати отсуства',
  emptyHierarchyList: 'Листата на хиерархии е празна',
  usersToAddToHoliday: 'Припадници за додавање во празник',
  usersToRemoveFromHoliday: 'Припадници за отстранување од празник',
  settings: 'Поставки',
  enableHierarchyEditing: 'Овозможете уредување на хиерархија',
  doYouReallyWantToDelete: 'Дали навистина сакате да го избришете празникот:',
  thisHolidayCannotBeRestored: 'Овој празник нема да може да се врати во системот.',
  thisUserCannotBeRestored: 'Овој корисник нема да може да се врати во системот.',
  holidayIsDeleted: 'Празникот е избришан!',
  actionSuccessful: 'Вашата акција е успешна.',
  doYouWantToSelectADeputy: 'Дали сакате да изберете заменик?',
  selectDeputy: 'Изберете заменик',
  all: 'Сите',
  vacationn: 'Одмор',
  userIsDeleted: 'Корисникот е избришан!',
  welcomeToSystemTitle: 'Добредојдовте на системот за управување со отуствата на ЕСМ',
  ethnicityDeleted: 'Етничката припадност е избришана.',
  fieldsRequired: 'Ве молиме пополнете ги сите полиња.',
  required: 'Задолжително!',
  userAddSuccessful: 'Корисникот е успешно додаден',
  religionDeleted: 'Религијата е избришана.',
  enableSync: 'Овозможете синхронизација',
  accountNotRegistered: 'Корисничката сметка не е регистрирана',
  passwordChangeMessageSuccessfully: 'Успешно е испратена порака за промена на лозинката до вашата е-пошта',
  send: 'Испрати',
  enterApprovalStatusRequired: 'Ве молиме внесете статус и опис на одобрување.',
  emptyNotificationList: 'Листата на известувања е празна',
  AbsenceByHoliday: 'Отсуства според празник',
  workPosition: 'Работно место',
  noInfo: 'Нема информации',
  duplicateLeaveRequestException: 'Веќе имате поднесено барање за отсуство во тој период!',
  noAvailableDays: 'Немате преостанати денови!',
  noAvailableDaysForThisYear: 'Нема достапни денови за оваа година!',
  noEnoughAvailableDays: 'Немате доволно денови на располагање!',
  holidaysSuccessfullyCreated: 'Празникот е успешно креиран. Продолжете кон додавање на корисници за креираниот празник.',
  holidaysSuccessfullyEdited: 'Празникот е успешно изменет. Продолжете кон додавање на корисници за креираниот празник.',
  reset: 'Пребриши',
  positionForOrganisation: 'Позиции за дадената организација',
  noPositionForSelectedOrganisation: 'Нема позиции за дадената организација',
  ok: 'Во ред',
  selected: 'Селектирани',
  userEditSuccessful: 'Промената е успешно направена',
  alreadyHaveSickLeaveDuring: 'Веќе имате боледување во тој период',
  userAlreadyHaveSickLeaveDuring: 'Корисникот веќе има поднесено барање во тој период',
  defaultDescriptionForHavingSickLeaveDuring: 'Вашето барање не може да биде прифатено поради тоа што имате боледување во тој период.',
  changePassword: 'Промени лозинка',
  newPassword: 'Нова лозинка',
  oldPassword: 'Стара лозинка',
  confirmNewPassword: 'Потврди нова лозинка',
  enterNewPassword: 'Внесете нова лозинка...',
  enterOldPassword: 'Внесете стара лозинка...',
  enterConfirmNewPassword: 'Потврди нова лозинка',
  savePassword: 'Зачувај',
  passwordsMatch: 'Лозинките се совпаѓаат',
  passwordsDoNotMatch: 'Лозинката не се совпаѓа',
  passwordsConfirmationDoNotMatch: 'Потврдата на вашата лозинка не се совпаѓа со вашата лозинка!',
  passwordChangeSuccessful: 'Лозинката е успешно променета!',
  incorrectPassword: 'Вашата лозинка е невалидна',
  noInfoForSelectedLeaveType: 'Нема податоци за избраниот тип на отсуство',
  blockUnblockUser: 'Блокирај/Одблокирај корисник',
  noOptions: 'Нема понудени опции',
  youHaveNoDaysLeftDueToPendingRequests: 'Немате преостанати денови поради поднесени барања кои што чекаат одговор',
  location: 'Локација',
  idNumber: 'Id број',
  id: 'Ид',
  dateCreated: 'Датум на креирање',
  date: 'Датум',
  actionHistory: 'Историја на акции за барањето',
  actionListEmpty: 'Листата на акции е празна',
  enterEvent: 'Внеси празник',
  select: 'Изберете...',
  filterByName: 'Филтрирај по име...',
  filterByParentName: 'Филтрирај по родител...',
  filterBySurname: 'Филтрирај по презиме...',
  filterByIdentificationNumber: 'Филтрирај по идентификациски број...',
  filterByEmail: 'Филтрирај по е-пошта...',
  employmentNumber: 'Број на вработување',
  personNumber: 'Број на лице',
  filterByLocation: 'Филтрирај по локација...',
  alreadyHaveHoliday: 'Веќе имате празници за оваа година, дали сакате да ги пребришете',
  isLoading: "Се вчитува...",
  terminated: 'Терминирано',
  termination_request: 'Барање за терминација',
  previous_correction_request: 'Предходно барање за корекција',
  correction_request: 'Барање за корекција',
  approved_after_declined_termination: 'Одобрено по одбиена тереминација',
  approved_after_declined_correction: 'Одобрено по одбиена корекција',
  approved_correction: 'Одобрено барање за корекција',
  leaveRequestDatesNotModified: 'Ве молиме направете промена на датуимте со цел да го корегирате барањето',
  deputy: 'Заменик'
};