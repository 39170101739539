import axios from "../../axios/axios";

const ReligionUnitService = {
    getAllReligion: () => {
        return axios.get("/religion/getAll")
    },
    fetchReligionUnit: (page, size) => {
        return axios.get(`/religion/getAllPaginated/${page}/${size}`)
    },
    editReligion: (id, name, labelMk, labelSq, labelEn) => {
        return axios.put('/religion/update', {
            id,
            name,
            labelMk,
            labelSq,
            labelEn
        })
    },
    addReligion: (name, labelMk, labelSq, labelEn) => {
        return axios.post('/religion/add', {
            name,
            labelMk,
            labelSq,
            labelEn
        })
    },
    deleteReligionUnit: (id) => {
        return axios.delete(`/religion/delete/${id}`);
    }
}
export default ReligionUnitService;